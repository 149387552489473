import React, { useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Dates, getErrorMessage } from 'helpers';
import {
  Uom,
  fetchUoms,
  getSearches,
  transformUom,
  fetchSearch,
} from 'services/uoms';
import { Pagination } from 'services/search';
import { useUrlQueryObject } from 'services/url';
import { showProgressAlert } from 'services/alert/redux';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';

import { Routes } from '../../navigation';
import { UomSearchResults, UomAdvancedSearch } from './components';
import { UnitOfMeasurementPageCmp, UnitOfMeasurementPageProps } from './types';
import {
  advancedSearchReduxActions,
  initialUomASFormValues,
  UOM_COLUMNS,
  initialPagination,
  displayNameMap,
} from './consts';
import { UomsPageAction } from './components/UomSearchResults/consts';
import { logErrorCtx } from 'app/logging';
import FBOUomDetailsCard from './components/UomDetailsCard/FBOUomDetailsCard';

const UnitOfMeasurementPage: UnitOfMeasurementPageCmp = (
  props: UnitOfMeasurementPageProps
) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeUomId,
  } = props;

  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [, extendUrlQuery] = useUrlQueryObject();
  const dispatch = useDispatch();

  const hiddenInput = useRef<HTMLInputElement>(null);

  const { startFetching, startCsvFetching } = useBackgroundTasks();

  const exportUoms = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.Uom);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);

  const importUoms = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your UOM import has been initiated.');

      startCsvFetching();

      event.target.value = '';
      try {
        await startBackgroundImport(BackgroundType.Uom, uploadedFile);
        dispatch(
          showProgressAlert(
            'Your Import to UOM has been initiated. This may take a few minutes to complete.',
            BackgroundType.Uom,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);
        const error = e as Error;
        logErrorCtx('Error in startBackgroundImport', {
          stackTrace: error.stack,
          error,
          description: message,
          component: 'UomPage',
        });
        return;
      }
    },
    [dispatch]
  );

  const handleUomsPageAction = useCallback(
    (action: UomsPageAction, date?: Dates) => {
      switch (action) {
        case UomsPageAction.Export:
          exportUoms();
          break;
        case UomsPageAction.Import:
          importUoms();
          break;
        case UomsPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    [exportUoms, importUoms]
  );

  const handleActiveUomClose = () => extendUrlQuery({ activeId: null });

  const handleAddNewPress = () => {
    extendUrlQuery({ activeId: -1 });
  };

  const handleUomClicked = (id: number | null) =>
    extendUrlQuery({ activeId: id });

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={UOM_COLUMNS(activeDate)}
        initialFormValues={initialUomASFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={UomAdvancedSearch}
        displayNameMap={displayNameMap}
        pageName="UOM"
      >
        <PaperSlidingLayout shown={Boolean(activeUomId)}>
          <UomSearchResults
            uoms={searchResult.items}
            activeUomId={activeUomId}
            handleUomClick={handleUomClicked}
            onAddNewPress={handleAddNewPress}
            isLoadingUoms={isLoadingSearchResult}
            activeDate={activeDate}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            onUomsPageAction={handleUomsPageAction}
          />
          <FBOUomDetailsCard
            activeUomId={activeUomId}
            fetchSearchResult={fetchSearchResult}
            onClose={handleActiveUomClose}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
    </>
  );
};

UnitOfMeasurementPage.route = Routes.UnitOfMeasurementPage;

export default withSearchResults<Uom>(UnitOfMeasurementPage, {
  url: '/v1/uoms',
  expand: 'toConversions,fromConversions',
  dataAdapter: transformUom,
  initialPagination,
  columns: UOM_COLUMNS(Dates.DateCreated),
  getSearches,
  fetchSearch,
  rehydrationThunks: [fetchUoms],
});

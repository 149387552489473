import React from 'react';
import { Switch } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';
import { PermissionType } from 'services/permissions';

import { Routes } from './routes';
import { ItemsPage, InventoryPage } from '../pages';
import { MaterialsRouterCmp, MaterialsRouterProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MaterialsRouter: MaterialsRouterCmp = (props: MaterialsRouterProps) => {
  return (
    <Switch>
      <SecureRoute
        permissions={[PermissionType.ItemsView]}
        exact
        component={ItemsPage}
        path={ItemsPage.route}
      />
      <SecureRoute
        permissions={[PermissionType.InventoryView]}
        exact
        component={InventoryPage}
        path={InventoryPage.route}
      />
    </Switch>
  );
};

MaterialsRouter.route = Routes.MaterialsRouter;

export default MaterialsRouter;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  fetchItems as defaultFetchItems,
  Pagination,
  SearchableState,
} from 'services/search';
import { dispatchThunks } from 'services/api';
import { useUrlQueryObject } from 'services/url';

import {
  PageWithSearchCmp,
  WithSearchOptions,
  FetchOptions,
  WrappedComponent as WrappedComponentType,
} from './types';
import {
  getDefaultSearch,
  getSearchState,
  INITIAL_ADVANCED_SEARCH,
} from './helpers';
import { logErrorCtx } from 'app/logging';

export function withSearchResults<D>(
  WrappedComponent: WrappedComponentType<D>,
  options: WithSearchOptions<D>
) {
  const {
    url,
    dataAdapter,
    initialPagination,
    expand,
    columns,
    getSearches,
    fetchSearch,
    rehydrationThunks,
    quickSearchColumns,
  } = options;

  const initialState = {
    advancedSearch: INITIAL_ADVANCED_SEARCH,
    quickSearch: {
      value: '',
      columns,
    },
    pagination: initialPagination,
    expand,
    items: [],
  };

  const PageWithSearch: PageWithSearchCmp = (props) => {
    const joinExpand = (expand?: string | string[]) => {
      return Array.isArray(expand) ? expand.join(',') : expand;
    };
    const state: SearchableState<D> = {
      ...initialState,
      expand: joinExpand(props.expand ?? expand),
    };
    const fetchItems = props.fetchItems ?? defaultFetchItems;

    const dispatch = useDispatch();
    const advancedSearches = useSelector(getSearches);

    const [urlQuery, extendUrlQuery] = useUrlQueryObject();

    const [searchState, setSearchState] = React.useState(state);
    const [activeItemId, setActiveItemId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
      const asyncFnc = async () => {
        try {
          // dispatch rehydration thunks before loading search state
          await dispatchThunks(rehydrationThunks, dispatch);

          // Find default advanced search
          // Lint skip to be removed
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const fetchSearchAction = async () => dispatch(fetchSearch());
          const defaultSearch = await getDefaultSearch(
            urlQuery,
            advancedSearches,
            fetchSearchAction
          );

          // Store advanced search in url as query
          extendUrlQuery({ advancedSearch: _.get(defaultSearch, 'columns') });

          // Load initial search state using advanced search
          await refreshSearchState({ advancedSearch: defaultSearch });

          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          const error = e as Error;
          logErrorCtx('Error while loading search results', {
            error,
            stackTrace: error.stack,
            title: error.message,
            description:
              'Error fetching search results in withSearchResult Global component',
            component: 'withSearchResult',
          });
        }
      };

      setIsLoading(true);
      asyncFnc();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      // Update activeId on url change
      if (urlQuery.activeId) {
        setActiveItemId(urlQuery.activeId);
        return;
      }
      setActiveItemId(null);
    }, [urlQuery]);

    async function refreshSearchState(fetchOptions: FetchOptions = {}) {
      const newSearchState = {
        ...searchState,
        quickSearch: fetchOptions.quickSearch || searchState.quickSearch,
        advancedSearch:
          fetchOptions.advancedSearch || searchState.advancedSearch,
        pagination: fetchOptions.pagination || searchState.pagination,
      };

      setIsLoading(true);

      try {
        const searchResult = await fetchItems(
          url,
          dataAdapter,
          getSearchState(url, newSearchState),
          quickSearchColumns
        );

        const newPagination: Pagination = {
          ...newSearchState.pagination!,
          totalRows: searchResult.totalResults,
        };

        // We need to block setting state until current value of
        // input at this point is the same as state
        const searchInput: any = document.getElementById('search-input');
        if (searchInput.value === newSearchState.quickSearch.value) {
          setSearchState({
            ...newSearchState,
            items: searchResult.results,
            pagination: newPagination,
          });
        }
      } catch (e) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    }

    return (
      <WrappedComponent
        searchState={searchState}
        refreshSearchState={refreshSearchState}
        isLoadingSearchState={isLoading}
        activeItemId={activeItemId}
        setActiveItemId={setActiveItemId}
        {...props}
      />
    );
  };

  PageWithSearch.route = WrappedComponent.route;

  return PageWithSearch;
}

export default withSearchResults;

import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  IconButton,
  Link,
} from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  EEL_PFC,
  ShippoB13FilingOption,
  ShippoContentsType,
  ShippoDefaultIncotermsType,
  ShippoNonDeliveryType,
  ShippoTaxIdType,
  ShipStationContentsType,
} from 'services/integrations/shipping/shippo';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { useHandleTextFieldChange } from 'services/forms';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { ShippingConnectionType } from 'services/integrations/shipping';
import { PhoneInputField } from 'ui/components/TextField/PhoneInputField';

import { CustomsDeclarationStepProps } from './types';
import { useInternationalData } from '../../PurchaseLabelProvider';

const CustomsDeclarationStep: React.FC<CustomsDeclarationStepProps> = () => {
  const { internationalData, setInternationalData } = useInternationalData();

  const [showDisclaimerTextarea, setShowDisclaimerTextArea] = useState(false);

  const connection = useSelector(getShippingConnection);

  const contentsType = useMemo(() => {
    if (!connection) {
      return [];
    }

    switch (connection.typeId) {
      case ShippingConnectionType.Shippo:
        return Object.values(ShippoContentsType);
      case ShippingConnectionType.ShipStation:
        return Object.values(ShipStationContentsType);
      default:
        return [];
    }
  }, [connection]);

  const handleTextFieldChange = useHandleTextFieldChange(
    setInternationalData,
    internationalData
  );

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setShowDisclaimerTextArea(true);
    },
    [setShowDisclaimerTextArea]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setInternationalData);

  const handleAutocompleteChange =
    (name: string) => (e: any, value: string | null) => {
      setInternationalData((old) => ({ ...old, [name]: value }));
    };

  return (
    <Box p={4} overflow="auto" width="100%">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            options={Object.values(ShippoNonDeliveryType)}
            placeholder="Select delivery"
            label="Non Delivery Option"
            value={internationalData.nonDeliveryOption}
            onChange={handleAutocompleteChange('nonDeliveryOption')}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={contentsType}
            label="Contents Type"
            placeholder="Enter type"
            name="number"
            value={internationalData.contentsType}
            onChange={handleAutocompleteChange('contentsType')}
          />
        </Grid>

        {connection && connection.typeId === ShippingConnectionType.Shippo && (
          <>
            <Grid item xs={4}>
              <TextField
                type="text"
                placeholder="Contents type explanation"
                value={internationalData.contentsTypeExplanation}
                name="contentsTypeExplanation"
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                options={Object.values(ShippoDefaultIncotermsType)}
                label="Incoterms"
                placeholder="Select"
                value={internationalData.incoterms}
                onChange={handleAutocompleteChange('incoterms')}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                options={Object.values(EEL_PFC)}
                placeholder="Select"
                label="EEL/PFC"
                value={internationalData.eelPfc}
                onChange={handleAutocompleteChange('eelPfc')}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="AES ITN"
                disabled={internationalData.eelPfc !== EEL_PFC.AES_ITN}
                placeholder="Reference Number"
                value={null}
                onChange={_.noop}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Certificate"
                placeholder="Certificate"
                name="certificate"
                value={internationalData.certificate}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Exporter reference"
                placeholder="Enter exporter reference"
                name="exporterReference"
                value={internationalData.exporterReference}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Importer reference"
                placeholder="Enter importer reference"
                name="importerReference"
                value={internationalData.importerReference}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={Object.values(ShippoB13FilingOption)}
                placeholder="Select"
                label="B13 Filing Options"
                value={internationalData.b13AFilingOption}
                onChange={handleAutocompleteChange('b13AFilingOption')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="B13 number"
                label="Enter B13 number"
                name="b13ANumber"
                value={internationalData.b13ANumber}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={Object.values(ShippoTaxIdType)}
                placeholder="Select tax ID type"
                label="Tax ID Type"
                value={internationalData.taxIdType}
                onChange={handleAutocompleteChange('taxIdType')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Tax number"
                label="Tax number"
                name="taxIdNumber"
                value={internationalData.taxIdNumber}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                placeholder="Invoice"
                label="Enter invoice"
                name="invoice"
                value={internationalData.invoice}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                placeholder="Enter email"
                label="Shipper Email Address"
                name="shipperEmailAddress"
                value={internationalData.shipperEmail}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={4}>
              <PhoneInputField
                placeholder="Enter phone number"
                label="Phone Number"
                name="phoneNumber"
                value={internationalData.phoneNumber}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                placeholder="Notes"
                label="Notes"
                name="notes"
                value={internationalData.notes}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Box py={2}>
                <Divider />
              </Box>
            </Grid>

            <Grid item xs={8}>
              {!showDisclaimerTextarea ? (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={internationalData.consent}
                        name="consent"
                        color="primary"
                        onChange={handleCheckboxChange}
                        disabled={!internationalData.disclaimer}
                      />
                    }
                    label={<b>{internationalData.disclaimer}</b>}
                  />
                  {internationalData.disclaimer ? (
                    <IconButton
                      onClick={() => setShowDisclaimerTextArea(true)}
                      size="large"
                    >
                      <CreateOutlinedIcon />
                    </IconButton>
                  ) : (
                    <Link
                      href="#"
                      underline="always"
                      variant="body2"
                      color="textPrimary"
                      onClick={handleLinkClick}
                    >
                      Add Disclaimer
                    </Link>
                  )}
                </>
              ) : (
                <Box width="45%" display="flex" alignItems="center">
                  <TextField
                    multiline
                    rows={4}
                    placeholder="Add Disclaimer"
                    name="disclaimer"
                    label=" Disclaimer"
                    value={internationalData.disclaimer}
                    onChange={handleTextFieldChange}
                  />
                  <IconButton
                    style={{
                      maxHeight: '50px',
                      maxWidth: '50px',
                      marginLeft: '10px',
                    }}
                    onClick={() => setShowDisclaimerTextArea(false)}
                    size="large"
                  >
                    <SaveOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="text"
                label="Certifier Name"
                placeholder="Select Name"
                name="certifierName"
                value={internationalData.certifierName}
                onChange={handleTextFieldChange}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default memo(CustomsDeclarationStep);

import { AccountType } from 'services/ledgers';
import { PermissionType } from 'services/permissions';

export interface RowDescriptorBase {
  title: string;
  field?: string;
  showTextAsBoolean?: boolean;
  textbox?: boolean;
  meta?: any;
  dataQa?: string;
  disabled?: boolean;
  iconSource?: string;
}

// Types for RowDescriptor
export interface BooleanDescriptor extends RowDescriptorBase {
  type: RowType.Boolean;
  subBooleanRows?: Omit<BooleanDescriptor, 'subBooleanRows'>[];
  subAutocompleteRows?: AutocompleteDescriptor[];
}

export interface EmailToggleDescriptor extends RowDescriptorBase {
  type: RowType.EmailToggle;
}

export interface GoLiveToggleDescriptor extends RowDescriptorBase {
  type: RowType.GoLiveToggle;
}

export interface OrderEmailToogleDescriptor extends RowDescriptorBase {
  type: RowType.OrderEmailToogle;
  subBooleanRows?: Omit<BooleanDescriptor, 'subBooleanRows'>[];
}

export interface TextDescriptor extends RowDescriptorBase {
  type: RowType.Text;
  handleShowTextAsBoolean?(
    value: string,
    field: string,
    setCardData: (field: string, value: string) => void
  ): void;
}

export interface ImageDescriptor extends RowDescriptorBase {
  type: RowType.Image;
}

export interface HyperTextDescriptor extends RowDescriptorBase {
  type: RowType.HyperText;
  handleShowTextAsBoolean?(
    value: string,
    field: string,
    setCardData: (field: string, value: string) => void
  ): void;
}

export interface AutocompleteDescriptor<T = any> extends RowDescriptorBase {
  type: RowType.Autocomplete;
  options: T[];
  isFieldDisabled?(option: T): boolean;
  getOptionsLabel?(option: T): string;
  isOptionEqualToValue?(option: T, value: T): boolean;
  handleAutocompleteChange?(
    e: React.ChangeEvent<{}>,
    value: T,
    field: string,
    setCardData: (field: string, value: unknown) => void
  ): void;
}

export interface CountryAutocompleteDescriptor extends RowDescriptorBase {
  type: RowType.CountryAutocomplete;
}
export interface LocationPickerDescriptor extends RowDescriptorBase {
  type: RowType.LocationPicker;
}
export interface TimezoneAutocompleteDescriptor extends RowDescriptorBase {
  type: RowType.TimezoneAutocomplete;
}

export interface AccountMappingDescriptor<T = any> extends RowDescriptorBase {
  type: RowType.AccountMapping;
  options: T[];
  accountType: AccountType;
}

export type RowDescriptor =
  | BooleanDescriptor
  | TextDescriptor
  | ImageDescriptor
  | HyperTextDescriptor
  | AutocompleteDescriptor
  | CountryAutocompleteDescriptor
  | LocationPickerDescriptor
  | TimezoneAutocompleteDescriptor
  | EmailToggleDescriptor
  | OrderEmailToogleDescriptor
  | AccountMappingDescriptor
  | GoLiveToggleDescriptor;

export enum RowType {
  'Boolean' = 'boolean',
  'Text' = 'text',
  'Image' = 'image',
  'HyperText' = 'hyperText',
  'Autocomplete' = 'autocomplete',
  'CountryAutocomplete' = 'countryAutocomplete',
  'LocationPicker' = 'locationPicker',
  'TimezoneAutocomplete' = 'timezoneAutocomplete',
  'EmailToggle' = 'emailToggle',
  'OrderEmailToogle' = 'orderEmailToogle',
  'AccountMapping' = 'accountMapping',
  'GoLiveToggle' = 'goLiveToggle',
}

export enum OrdersEmailType {
  PurchaseOrder,
  SalesOrder,
}

export interface CardProps {
  title: string;
  data: unknown;
  onApplyClicked(newData: unknown): void;
  noReset?: boolean;
  rows: RowDescriptor[];
  id?: string;
  show: boolean;
  viewPermissions?: PermissionType[];
  editPermissions?: PermissionType[];
  dataQa?: string;
}

import React from 'react';
import { useSelector } from 'react-redux';
import { ListItemButton, ListItemText, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {
  activeUserHasPermission,
  getActiveUserLoading,
} from 'services/user/redux';
import { PermissionType } from 'services/permissions';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme';
import { DrawerSubItemType } from './DrawerSubItem';
import { openInNewTab } from 'helpers';

export interface DrawerItemProps {
  text: string;
  icon?: IconNames;
  to: string | null;
  active: boolean;
  itemExpanded?: boolean;
  secondary?: boolean;
  handleClick?(): void;
  visible?: boolean;
  permissions?: PermissionType[];
  isLoading?: boolean;
  dataQa?: string;
  component?: React.ReactNode;
  className?: string;
  link?: string;
}

export interface DrawerItemType {
  text: string;
  icon: IconNames;
  subitems: DrawerSubItemType[];
  route: string | null;
  exact?: boolean;
  visible?: boolean;
  placement?: 'top' | 'bottom';
  permissions?: PermissionType[];
  onlyExecutor?: boolean;
  link?: string;
}

const DrawerItem: React.FC<DrawerItemProps> = (props) => {
  const {
    text,
    icon,
    to,
    itemExpanded,
    handleClick,
    visible = true,
    permissions,
    dataQa,
    link = null,
  } = props;

  const canAccess = useSelector(activeUserHasPermission(permissions || []));
  const userLoading = useSelector(getActiveUserLoading);

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<RouterLink>((itemProps, ref: any) => (
        <RouterLink to={to as string} {...itemProps} ref={ref} />
      )),
    [to]
  );

  const itemExpandedClass = itemExpanded ? 'active-link' : '';
  const sandboxClass = visible && text === 'Sample Database' ? 'sandbox' : '';

  if (!canAccess) {
    return null;
  }

  if (visible) {
    return (
      <ListItemButton
        className={`${itemExpandedClass} ${sandboxClass}`}
        onClick={link ? () => openInNewTab(link) : handleClick}
        component={to ? (renderLink as any) : link ? 'a' : ''}
        disableRipple
      >
        {icon && (
          <>
            {userLoading ? (
              <Box />
            ) : (
              <Icon name={icon} width={24} height={24} />
            )}
          </>
        )}
        <ListItemText primary={text} data-qa={`drawer-${dataQa}`} />
      </ListItemButton>
    );
  }

  return null;
};

export default React.memo(DrawerItem);

import _ from 'lodash';

import { Address, initialAddress } from 'services/addresses';
import {
  CustomerCarriersBillingType,
  Ship,
  ShipCarton,
} from 'services/shipping';
import { transformShipToAddress } from 'ui/modules/sales/pages/ShippingPage/components/ShippingDetailsCard/components/PurchaseLabelWizard/components/PurchaseLabelStep/transformations';
import { roundToDecimals, transformDateToMomentDate } from 'helpers';

import { initialShippingIntegrationSettings } from './consts';
import { PurchaseLabelExtra } from './purchaseLabel';

import {
  ShippingCarrier,
  ShippingIntegrationFileType,
  ShippingIntegrationPackage,
  ShippingIntegrationRate,
  ShippingIntegrationSettings,
  SalesOrderShippingQuote,
  ShippingIntegrationCarton,
  ShippingEventHistory,
} from './types';
import {
  ShippingConnection,
  ShippingConnectionType,
  ShippingQuoteAddToSalesOrderParams,
  ShippingLabel,
} from '.';

export const transformToShippingSettings = (
  res: any
): ShippingIntegrationSettings => {
  const resMarkup = res.markup;
  let markupValue = null;
  let markupType = 'flat';
  if (resMarkup) {
    markupValue =
      resMarkup.flat || (resMarkup.percent ? resMarkup.percent * 100 : 0);
    markupType = resMarkup.flat ? 'flat' : 'percent';
  }

  // backend is not sending empty fields, so we want to be sure that everything is here
  return {
    ...initialShippingIntegrationSettings,
    ...res,
    markup: {
      type: markupType,
      value: markupValue,
    },
    cartonDimensions: {
      ...initialShippingIntegrationSettings.cartonDimensions,
      ...res.cartonDimensions,
    },
    internationalSettings: {
      ...initialShippingIntegrationSettings.internationalSettings,
      ...res.internationalSettings,
    },
    extraSettings: {
      ...initialShippingIntegrationSettings.extraSettings,
      ...res.extraSettings,
    },
  };
};

export const transformToIntegrationShippingSettingsRequest = (
  settings: ShippingIntegrationSettings
): any => {
  const markup = settings.markup;

  return {
    ...settings,
    markup: {
      flat: markup.type === 'flat' ? markup.value : 0,
      percent:
        markup.type === 'percent' && markup.value ? markup.value / 100 : 0,
    },
  };
};

export const transformToShippingCarriers = (res: any[]) => {
  const newArray: ShippingCarrier[] = [];

  res.forEach((obj) => {
    const index = newArray.findIndex((e) => e.code === obj.carrierCode);
    if (index === -1) {
      newArray.push({
        name: obj.carrierName,
        code: obj.carrierCode,
        services: [{ name: obj.serviceName, code: obj.serviceCode }],
      });
    } else {
      newArray[index].services.push({
        name: obj.serviceName,
        code: obj.serviceCode,
      });
    }
  });

  return newArray;
};

export const transformToShippingIntegrationRates = (
  res: any[],
  connection: ShippingConnection
) => {
  const { typeId } = connection;

  let newArray: ShippingIntegrationRate[] = [];

  switch (typeId) {
    case ShippingConnectionType.Shippo:
      newArray = [
        { carrierCode: 'usps', carrierName: 'USPS', services: [] },
        { carrierCode: 'ups', carrierName: 'UPS', services: [] },
        { carrierCode: 'fedex', carrierName: 'FedEx', services: [] },
      ];
      break;
    case ShippingConnectionType.ShipStation:
      newArray = [
        { carrierCode: 'stamps_com', carrierName: 'USPS', services: [] },
        { carrierCode: 'ups_walleted', carrierName: 'UPS', services: [] },
        { carrierCode: 'fedex', carrierName: 'FedEx', services: [] },
      ];
      break;
  }

  res.forEach((obj) => {
    const index = newArray.findIndex((e) => e.carrierCode === obj.carrierCode);
    if (index === -1) {
      newArray.push({
        carrierCode: obj.carrierCode,
        carrierName: obj.carrierName,
        services: [
          {
            serviceCode: obj.serviceCode,
            serviceName: obj.serviceName,
            totalCost: obj.totalCost,
          },
        ],
      });
    } else {
      newArray[index].services.push({
        serviceCode: obj.serviceCode,
        serviceName: obj.serviceName,
        totalCost: obj.totalCost,
      });
    }
  });

  return newArray;
};

export const transformToFetchRatesPayload = (
  cartons: ShipCarton[],
  shipTo: Address,
  shipFrom: Address,
  purchaseLabelData: PurchaseLabelExtra
): any => {
  const reference1 = purchaseLabelData.referenceField1
    ? purchaseLabelData.referenceField1.toString()
    : '';
  const reference2 = purchaseLabelData.referenceField2
    ? purchaseLabelData.referenceField2.toString()
    : '';

  const billing: any = {
    type: purchaseLabelData.billingType,
  };

  if (purchaseLabelData.billingType !== CustomerCarriersBillingType.Sender) {
    billing.account = purchaseLabelData.billingAccount;
    billing.zip = purchaseLabelData.billingZip;
    billing.country = purchaseLabelData.billingCountry;
  }

  const cod: any = {
    amount: purchaseLabelData.codAmount,
    currency: purchaseLabelData.codCurrency,
    payment_method: purchaseLabelData.codMethod,
  };

  const alcohol: any = {
    contains_alcohol: purchaseLabelData.containsAlcohol,
    recipient_type: purchaseLabelData.recipientType,
  };

  const dryIce: any = {
    weight: {
      value: purchaseLabelData.dryIceWeight,
      unit: purchaseLabelData.dryIceWeightUnit,
    },
  };

  const body: any = {
    cartons: cartons.map((c) => ({
      dimensions: {
        unit: c.dimensionUnit || 'Inches',
        width: c.width || 0,
        height: c.height || 0,
        length: c.length || 0,
      },
      weight: {
        value: c.weight || 0,
        unit: c.weightUnit || 'Pounds',
      },
      packageCode: purchaseLabelData.packagingCode,
      cartonId: c.id,
      insuredAmount: c.insuredValue || 0,
    })),
    shipTo: {
      name: shipTo.name || '',
      company: shipTo.companyName || '',
      street1: shipTo.street || '',
      city: shipTo.city || '',
      state: shipTo.state || '',
      zip: shipTo.postalCode || '',
      country: shipTo.country || '',
      residential: shipTo.residential,
    },
    shipFrom: {
      name: shipFrom.name || '',
      company: shipFrom.companyName || '',
      street1: shipFrom.street || '',
      city: shipFrom.city || '',
      state: shipFrom.state || '',
      zip: shipFrom.postalCode || '',
      country: shipFrom.country || '',
      phone: shipFrom.phone || '',
      email: shipFrom.email || '',
      residential: shipFrom.residential,
    },
    options: {
      saturdayDelivery: purchaseLabelData.saturdayDelivery,
      billing,
      reference1,
      reference2,
    },
  };

  if (purchaseLabelData.cod) {
    body.options.cod = cod;
  }

  if (purchaseLabelData.containsAlcohol) {
    body.options.alcohol = alcohol;
  }

  if (purchaseLabelData.containsDryIce) {
    body.options.dryIce = dryIce;
  }

  return body;
};

export const transformShippingIntegrationPackage = (
  res: any
): ShippingIntegrationPackage => {
  return {
    name: res.name,
    code: res.code,
    carrier: res.carrier,
  };
};

export const transformToShippingIntegrationFileType = (
  res: any
): ShippingIntegrationFileType => {
  return {
    name: res.name,
    code: res.code,
  };
};

export const transformToPurchaseLabelPayload = (
  ship: Ship,
  cartons: ShipCarton[],
  shipTo: Address,
  shipFrom: Address,
  purchaseLabelData: PurchaseLabelExtra
): any => {
  const reference1 = purchaseLabelData.referenceField1
    ? purchaseLabelData.referenceField1.toString()
    : '';
  const reference2 = purchaseLabelData.referenceField2
    ? purchaseLabelData.referenceField2.toString()
    : '';

  const billing: any = {
    type: purchaseLabelData.billingType,
    account: purchaseLabelData.billingAccount,
    zip: purchaseLabelData.billingZip,
    country: purchaseLabelData.billingCountry,
  };

  const cod: any = {
    amount: purchaseLabelData.codAmount,
    currency: purchaseLabelData.codCurrency,
    payment_method: purchaseLabelData.codMethod,
  };

  const alcohol: any = {
    contains_alcohol: purchaseLabelData.containsAlcohol,
    recipient_type: purchaseLabelData.recipientType,
  };

  const dryIce: any = {
    weight: {
      value: purchaseLabelData.dryIceWeight,
      unit: purchaseLabelData.dryIceWeightUnit,
    },
  };
  const body: any = {
    cartons: cartons.map((c) => ({
      dimensions: {
        unit: c.dimensionUnit || 'Inches',
        width: c.width || 0,
        height: c.height || 0,
        length: c.length || 0,
      },
      weight: {
        value: c.weight || 0,
        unit: c.weightUnit || 'Pounds',
      },
      cartonId: c.id,
      packageCode: purchaseLabelData.packagingCode,
      insuredAmount: c.insuredValue || 0,
    })),
    shipTo: {
      name: shipTo.name || '',
      company: shipTo.companyName || '',
      city: shipTo.city || '',
      street1: shipTo.street || '',
      street2: shipTo.street2 || '',
      street3: '',
      state: shipTo.state || '',
      zip: shipTo.postalCode || '',
      phone: purchaseLabelData.recipientPhone || '',
      email: purchaseLabelData.recipientEmail || '',
      country: shipTo.country || '',
      residential: shipTo.residential,
    },
    shipFrom: {
      name: shipFrom.name || '',
      company: shipFrom.companyName || '',
      city: shipFrom.city || '',
      street1: shipFrom.street || '',
      street2: shipFrom.street2 || '',
      street3: '',
      state: shipFrom.state || '',
      zip: shipFrom.postalCode || '',
      phone: shipFrom.phone || '',
      email: shipFrom.email || '',
      country: shipFrom.country || '',
      residential: shipFrom.residential,
    },
    options: {
      signatureConfirmation: purchaseLabelData.signatureType,
      saturdayDelivery: purchaseLabelData.saturdayDelivery,
      billing,
      reference1,
      reference2,
      alcohol,
    },
    carrierCode: purchaseLabelData.carrierCode,
    serviceCode: purchaseLabelData.serviceCode,
    shipmentId: ship.id,
    orderId: ship.salesOrderId,
    orderNumber: ship.number,
    fileType: purchaseLabelData.labelFileType,
    purchaseReturnLabel: purchaseLabelData.purchaseReturnLabel,
  };

  if (purchaseLabelData.cod) {
    body.options.cod = cod;
  }

  if (purchaseLabelData.containsDryIce) {
    body.options.dryIce = dryIce;
  }

  return body;
};

const transformAddressToShippingIntegrationAddress = (
  address: Address
): any => ({
  name: address.name,
  company: address.companyName,
  city: address.city,
  street1: address.street,
  street2: address.street2 || '',
  street3: '',
  state: address.state,
  zip: address.postalCode,
  phone: address.phone,
  email: address.email,
  country: address.country,
  residential: address.residential,
});

const transformToShippingIntegrationCarton = (shipCarton: ShipCarton): any => ({
  dimensions: {
    unit: shipCarton.dimensionUnit,
    width: shipCarton.width,
    height: shipCarton.height,
    length: shipCarton.length,
  },
  weight: {
    value: shipCarton.weight,
    unit: shipCarton.weightUnit,
  },
  cartonId: shipCarton.id,
});

export const transformToQuickShipPayload = (
  ship: Ship,
  settings: ShippingIntegrationSettings
): any => {
  const shipTo = transformShipToAddress(ship);

  const shipFrom = _.get(ship, 'salesOrder.location.address', initialAddress);

  const res: any = {
    shipmentId: ship.id,
    orderId: ship.salesOrderId,
    orderNumber: ship.number,
    quickShipStrategy: settings.quickShipStrategy,
    carrierId: ship.carrierId,
    serviceId: ship.carrierServiceId,
    cartons: ship.shipCartonList.map(transformToShippingIntegrationCarton),
    shipTo: transformAddressToShippingIntegrationAddress(shipTo),
    shipFrom: transformAddressToShippingIntegrationAddress(shipFrom),
  };

  return res;
};

export const transformToQuotesFetchRatesPayload = (
  cartons: ShippingIntegrationCarton[],
  shipTo: Address,
  shipFrom: Address,
  salesOrderShippingQuote: SalesOrderShippingQuote
): any => {
  const cod: any = {
    amount: salesOrderShippingQuote.codAmount,
    currency: salesOrderShippingQuote.codCurrency,
    payment_method: salesOrderShippingQuote.codMethod,
  };

  const alcohol: any = {
    contains_alcohol: salesOrderShippingQuote.containsAlcohol,
    recipient_type: salesOrderShippingQuote.recipientType,
  };

  const dryIce: any = {
    weight: {
      value: salesOrderShippingQuote.dryIceWeight,
      unit: salesOrderShippingQuote.uom,
    },
  };

  const signature: any = {
    signature: salesOrderShippingQuote.signature,
  };

  const body: any = {
    cartons: cartons.map((c) => ({
      dimensions: {
        unit: c.dimensionUnit || 'Inches',
        width: c.width || 0,
        height: c.height || 0,
        length: c.length || 0,
      },
      weight: {
        value: c.weight !== null ? roundToDecimals(c.weight, 4) : 0,
        unit: c.weightUnit || 'Pounds',
      },
      insuredAmount: c.insuredAmount || 0,
    })),
    shipTo: {
      name: shipTo.name || '',
      company: shipTo.companyName || '',
      street1: shipTo.street || '',
      street2: shipTo.street2 || '',
      state: shipTo.state || '',
      zip: shipTo.postalCode || '',
      country: shipTo.country || '',
      city: shipTo.city || '',
      residential: shipTo.residential,
    },
    shipFrom: {
      name: shipFrom.name || '',
      company: shipFrom.companyName || '',
      street1: shipFrom.street || '',
      street2: shipFrom.street2 || '',
      state: shipFrom.state || '',
      zip: shipFrom.postalCode || '',
      country: shipFrom.country || '',
      city: shipFrom.city || '',
      phone: shipFrom.phone || '',
      email: shipFrom.email || '',
      residential: shipFrom.residential,
    },
    options: {
      saturdayDelivery: salesOrderShippingQuote.saturdayDelivery,
    },
  };

  if (salesOrderShippingQuote.cod) {
    body.options.cod = cod;
  }

  if (salesOrderShippingQuote.signatureConfirmation) {
    body.options.signature = signature;
  }

  if (salesOrderShippingQuote.containsAlcohol) {
    body.options.alcohol = alcohol;
  }

  if (salesOrderShippingQuote.containsDryIce) {
    body.options.dryIce = dryIce;
  }

  return body;
};

export const transformShippingEventHistory = (
  res: any
): ShippingEventHistory => {
  return {
    id: res.id,
    object: res.object,
    name: res.name,
    date: transformDateToMomentDate(res.date),
    number: res.number,
    tracking: res.tracking,
    type: res.type,
    user: res.user,
    message: res.message,
    error: res.error,
  };
};

export const transformToShippingQuoteAddToSalesOrderPayload = (
  params: ShippingQuoteAddToSalesOrderParams
): any => {
  const res: any = {
    orderId: params.id,
    orderNumber: params.soNumber,
    carrierName: params.carrierName,
    serviceName: params.serviceName,
    carrierCode: params.carrierCode,
    serviceCode: params.serviceCode,
    totalCost: params.totalCost,
    shipTo: transformAddressToShippingIntegrationAddress(params.shipTo),
  };

  return res;
};

export const transformShippingLabel = (res: any): ShippingLabel => {
  return {
    cartonId: res.cartonId,
    labelUrl: res.labelUrl,
    cost: res.cost,
    tracking: res.tracking,
    trackingUrl: res.trackingUrl,
    returnLabel: res.returnLabel
      ? transformShippingLabel(res.returnLabel)
      : null,
  };
};

import React, { memo, useMemo } from 'react';
import { Box, Grid, Button } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';

import { OrderEmailsModalProps } from './types';
import { MODAL_HEIGHT } from './consts';
import { SaleOrdersEmailTabs } from './SaleOrdersEmailTabs';
import { PurchaseOrdersEmailTabs } from './PurchaseOrdersEmailTabs';
import { OrdersEmailType } from '../../Card/types';

const OrderEmailsModal: React.FC<OrderEmailsModalProps> = (props) => {
  const { visible, onClose, type } = props;

  const resolvedTitle = useMemo(() => {
    switch (type) {
      case OrdersEmailType.PurchaseOrder:
        return 'Purchase Orders';
      case OrdersEmailType.SalesOrder:
        return 'Sales Orders';
    }
  }, [type]);

  const modalActionsComponent = () => {
    return (
      <>
        <Button onClick={onClose} variant="outlined">
          BACK
        </Button>

        <Button variant="contained" color="primary" onClick={onClose}>
          SAVE
        </Button>
      </>
    );
  };

  return (
    <Modal
      open={visible}
      title={resolvedTitle}
      withoutDefaultPadding
      ModalActionsComponent={modalActionsComponent}
      onCancelClicked={onClose}
      maxWidth="md"
      customHeight={MODAL_HEIGHT}
    >
      <Box p={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type="text"
              label="CC"
              placeholder="Separate multiple emails with a comma"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              label="BCC"
              placeholder="Separate multiple emails with a comma"
            />
          </Grid>
        </Grid>
      </Box>
      {type === OrdersEmailType.PurchaseOrder && <PurchaseOrdersEmailTabs />}
      {type === OrdersEmailType.SalesOrder && <SaleOrdersEmailTabs />}
    </Modal>
  );
};

export default memo(OrderEmailsModal);

import React, { memo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { ModuleNavigationItemStatus } from 'services/moduleNavigation';
import { ItemType } from 'services/items';
import { Routes as MaterialsRoutes } from 'ui/modules/materials';
import { ItemTab } from 'ui/modules/materials/pages/ItemsPage/components/ItemDetailsCard/types';

import { MaterialsNavigationProps } from './types';
import { BreadCrumbLocation } from '../BreadCrumb/types';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme';
import FBOBreadCrumb from '../BreadCrumb/FBOBreadCrumb';

const FBOMaterialsNavigation: React.FC<MaterialsNavigationProps> = (props) => {
  const { items } = props;

  const { itemId, itemType = ItemType.Inventory } = items;

  const classes = {
    icon: 'redesign',
    skeleton: 'redesign',
    navigation: 'redesign navigation',
    navigationContainer: 'redesign navigation-container',
    returnNavigation: 'redesign return-navigation',
    returnItem: 'redesign',
    returnIcon: 'redesign',
  };
  const location = useLocation();
  const history = useHistory();

  const onItemsPage = location.pathname.includes(MaterialsRoutes.ItemsPage);
  const onInventoryPage = location.pathname.includes(
    MaterialsRoutes.TrackingPage
  );

  const itemsStatus = !itemId
    ? ModuleNavigationItemStatus.Undefined
    : onItemsPage
    ? ModuleNavigationItemStatus.Active
    : ModuleNavigationItemStatus.InActive;

  const inventoryStatus =
    !itemId || itemType !== ItemType.Inventory
      ? ModuleNavigationItemStatus.Undefined
      : onInventoryPage
      ? ModuleNavigationItemStatus.Active
      : ModuleNavigationItemStatus.InActive;

  const itemsRoute = `${MaterialsRoutes.ItemsPage}?activeId=${itemId}&activeTab=${ItemTab.General}`;

  const inventoryRoute = `${MaterialsRoutes.TrackingPage}?activeId=${itemId}`;

  return (
    <Box className={classes.navigationContainer}>
      <Box className={classes.navigation}>
        <FBOBreadCrumb
          location={BreadCrumbLocation.Left}
          status={itemsStatus}
          zIndex={3}
          onClick={() => history.push(itemsRoute, { triggerRouteGuard: true })}
          data-qa="spacial-navigation-items"
          disabled={itemsStatus === ModuleNavigationItemStatus.Undefined}
          disableRipple
          disableTouchRipple
        >
          <Typography variant="body2">Items</Typography>
        </FBOBreadCrumb>
        <Icon name={IconNames.FBOCaratRightSmall} />
        <FBOBreadCrumb
          location={BreadCrumbLocation.Right}
          status={inventoryStatus}
          zIndex={1}
          onClick={() =>
            history.push(inventoryRoute, { triggerRouteGuard: true })
          }
          data-qa="spacial-navigation-inventory"
          disabled={inventoryStatus === ModuleNavigationItemStatus.Undefined}
          disableRipple
          disableTouchRipple
        >
          <Typography variant="body2">Inventory</Typography>
        </FBOBreadCrumb>
      </Box>
    </Box>
  );
};

export default memo(FBOMaterialsNavigation);

import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  Link,
} from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import Autocomplete from 'ui/components/Autocomplete/Autocomplete/Autocomplete';
import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import {
  EEL_PFC,
  ShippoContentsType,
  ShippoDefaultIncotermsType,
  ShippoNonDeliveryType,
  ShipStationContentsType,
} from 'services/integrations/shipping/shippo';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { ShippingConnectionType } from 'services/integrations/shipping';
import { PhoneInputField } from 'ui/components/TextField/PhoneInputField';

import { ShippingTabProps } from './types';
import { useCardRowStyle } from '../styled';

import { IconNames } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const CustomsInfoTab = (props: ShippingTabProps) => {
  const { shippingQuote, setShippingQuote } = props;

  const classes = useCardRowStyle();

  const connection = useSelector(getShippingConnection);

  const [showDisclaimerTextarea, setShowDisclaimerTextArea] = useState(false);

  const contentsType = useMemo(() => {
    if (!connection) {
      return [];
    }

    switch (connection.typeId) {
      case ShippingConnectionType.Shippo:
        return Object.values(ShippoContentsType);
      case ShippingConnectionType.ShipStation:
        return Object.values(ShipStationContentsType);
      default:
        return [];
    }
  }, [connection]);

  const handleCheckboxChange = useHandleCheckboxChange(setShippingQuote);

  const handleTextFieldChange = useHandleTextFieldChange(
    setShippingQuote,
    shippingQuote
  );

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setShowDisclaimerTextArea(true);
    },
    [setShowDisclaimerTextArea]
  );

  const handleAutocompleteChange =
    (name: string) => (e: any, value: string) => {
      setShippingQuote((old) => ({ ...old, [name]: value }));
    };

  return (
    <Box p={4} className={classes.tabPanelBox}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            options={Object.values(ShippoNonDeliveryType)}
            placeholder="Select delivery"
            label="Non Delivery Option"
            value={shippingQuote.nonDeliveryOption}
            onChange={handleAutocompleteChange('nonDeliveryOption')}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={contentsType}
            label="Contents Type"
            placeholder="Enter type"
            name="number"
            value={shippingQuote.contentsType}
            onChange={handleAutocompleteChange('contentsType')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            placeholder="Contents type explanation"
            value={shippingQuote.contentsTypeExplanation}
            name="contentsTypeExplanation"
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={Object.values(ShippoDefaultIncotermsType)}
            label="Incoterms"
            placeholder="Select"
            value={shippingQuote.incoterms}
            onChange={handleAutocompleteChange('incoterms')}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={Object.values(EEL_PFC)}
            placeholder="Select"
            label="EEL/PFC"
            value={shippingQuote.eel}
            onChange={handleAutocompleteChange('eel')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            label="AES ITN"
            disabled={shippingQuote.eel !== EEL_PFC.AES_ITN}
            placeholder="Reference Number"
            value={null}
            onChange={_.noop}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            label="Certificate"
            placeholder="Certificate"
            name="certificate"
            value={shippingQuote.certificate}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            label="Exporter reference"
            placeholder="Enter exporter reference"
            name="exporterReference"
            value={shippingQuote.exporterReference}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            label="Importer reference"
            placeholder="Enter importer reference"
            name="importerReference"
            value={shippingQuote.importerReference}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            placeholder="Invoice"
            label="Enter invoice"
            name="invoice"
            value={shippingQuote.invoice}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            placeholder="Enter email"
            label="Shipper Email Address"
            name="shipperEmailAddress"
            value={shippingQuote.shipperEmailAddress}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneInputField
            placeholder="Enter phone number"
            label="Phone Number"
            name="phoneNumber"
            value={shippingQuote.phoneNumber}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            placeholder="Notes"
            label="Notes"
            name="notes"
            value={shippingQuote.notes}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box py={2}>
            <Divider />
          </Box>
        </Grid>

        <Grid item xs={8}>
          {!showDisclaimerTextarea ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={shippingQuote.consent}
                    name="consent"
                    color="primary"
                    onChange={handleCheckboxChange}
                    disabled={!shippingQuote.disclaimer}
                  />
                }
                label={<b>{shippingQuote.disclaimer}</b>}
              />
              {shippingQuote.disclaimer ? (
                <>
                  <FBOButton
                    variant="tertiary"
                    color="neutral"
                    size="medium"
                    icon={IconNames.IconEdit}
                    onClick={() => setShowDisclaimerTextArea(true)}
                    data-qa="customs-info-tab-edit-icon"
                  />
                </>
              ) : (
                <Link
                  href="#"
                  underline="always"
                  variant="body2"
                  color="textPrimary"
                  onClick={handleLinkClick}
                >
                  Add Disclaimer
                </Link>
              )}
            </>
          ) : (
            <Box width="45%" display="flex" alignItems="center">
              <TextField
                className="redesign"
                variant="standard"
                multiline
                rows={3}
                placeholder="Add Disclaimer"
                name="disclaimer"
                label=" Disclaimer"
                value={shippingQuote.disclaimer}
                onChange={handleTextFieldChange}
              />
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="medium"
                icon={IconNames.IconSave}
                onClick={() => setShowDisclaimerTextArea(false)}
                data-qa="customs-info-tab-save-button"
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Certifier Name"
            placeholder="Select Name"
            name="certifierName"
            value={shippingQuote.certifierName}
            onChange={handleTextFieldChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(CustomsInfoTab);

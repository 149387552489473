import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  getSalesOrderId,
  getSearches,
  fetchSearch,
  deleteSalesOrders,
  unIssueSalesOrders,
  multipleIssueSalesOrders,
  transformDisplaySalesOrder,
  DisplaySalesOrder,
} from 'services/salesOrders';
import {
  fetchRepresentatives,
  getRepresentatives,
} from 'services/representatives';
import { fetchCustomers, getCustomers } from 'services/customers';
import {
  fetchOrderPriorities,
  getOrderPriorities,
} from 'services/settings/orderPriorities';
import { fetchUsers } from 'services/user';
import { fetchUsers as fetchUsersV2 } from 'services/userV2';
import { fetchLocations, getLocations } from 'services/locations';
import { fetchCarriers, getCarriers } from 'services/carriers';
import { fetchPaymentTerms, getPaymentTerms } from 'services/paymentTerms';
import { fetchSettingsShipping } from 'services/settings/shipping/redux';
import { fetchTaxRates } from 'services/taxRates';
import { fetchUoms } from 'services/uoms';
import { fetchSettingsSalesOrders } from 'services/settings/salesOrders';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { Pagination } from 'services/search';
import { fetchSettingsCompanies } from 'services/settings/company/redux';
import { SHIPPING_TERMS } from 'services/items';
import { fetchVendors } from 'services/vendors';
import { CustomFieldsModal } from 'ui/components/CustomFields/CustomFieldsModal';
import { fetchTags, fetchTagsAPI, Tag } from 'services/tags';
import { ReportId } from 'services/reports';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { ObjectType } from 'services/customFields';
import { useUrlQueryObject } from 'services/url';
import { fetchClasses } from 'services/classes';
import { Routes } from '../../navigation';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  removeModuleNavigation,
} from 'services/moduleNavigation';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications/';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { showProgressAlert } from 'services/alert/redux';
import { Dates, getErrorMessage } from 'helpers';
import { useGetIntlDateFormatString, usePageSettings } from 'helpers/hooks';

import {
  SalesOrderSearchResults,
  SalesOrderDetailsCard,
  SalesOrderAdvancedSearch,
  SALES_ORDER_COLUMNS,
} from './components';
import { SalesOrderPageProps } from './types';
import {
  initialSalesOrderFormValues,
  advancedSearchReduxActions,
  createDisplayValueMap,
  initialPagination,
  displayNameMap,
  salesOrderAdvSearchFields,
} from './consts';
import {
  SalesOrderPageAction,
  SalesOrderTableRowActions,
} from './components/SalesOrderSearchResults/types';
import { logErrorCtx } from 'app/logging';
import { ErrorBoundary } from '@datadog/rum-react-integration';
import { FallbackUI } from 'ui/components/Modal/ErrorMessage/common';
import { ModuleNavigation } from 'app/components/AppBar/components';

const SalesOrderPage = (props: SalesOrderPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeSalesOrderId,
  } = props;

  const dispatch = useDispatch();

  const { items: customers } = useSelector(getCustomers);
  const { items: representatives } = useSelector(getRepresentatives);
  const { items: carriers } = useSelector(getCarriers);
  const { items: orderPriorities } = useSelector(getOrderPriorities);
  const { items: paymentTerms } = useSelector(getPaymentTerms);
  const { items: locations } = useSelector(getLocations);

  const hiddenInput = useRef<HTMLInputElement>(null);

  const { storedValue, setPageSettings } = usePageSettings();

  const [nextSalesOrdersNumber, setNextSalesOrdersNumber] =
    useState<number>(-1);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [clone, setClone] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [unissueModalVisible, setUnissueModalVisible] = useState(false);
  const [issueModalVisible, setIssueModalVisible] = useState(false);
  const [issueLoadingVisible, setIssueLoadingVisible] = useState(false);
  const [customFieldsModalVisible, setCustomFieldsModalVisible] =
    useState(false);
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [showReportModal, setShowReportModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTags, setSearchTags] = useState<Tag[]>([]);

  const [, extendUrlQuery] = useUrlQueryObject();

  const { startFetching, startCsvFetching } = useBackgroundTasks();
  const intlFormatDate = useGetIntlDateFormatString();

  // watch advanced search columns and fetch selected tags
  useEffect(() => {
    const tagIds = _.get(
      searchResult.advancedSearch.columns,
      [salesOrderAdvSearchFields.Tags],
      null
    ) as number[];

    if (tagIds) {
      (async () => {
        try {
          const resTags = await fetchTagsAPI({}, tagIds);

          setSearchTags(resTags.data);
        } catch {
          //ignore Error
        }
      })();
    }
  }, [searchResult.advancedSearch.columns]);

  useEffect(() => {
    dispatch(clearModuleNavigation(ModuleNavigationType.Sales));
    setActiveDate(storedValue.salesOrder.dateColumn);

    return () => {
      dispatch(removeModuleNavigation());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNextSalesOrdersNumber = useCallback(async () => {
    const nextNumber = await getSalesOrderId();
    setNextSalesOrdersNumber(nextNumber || -1);
  }, []);

  const handleActiveSalesOrderClose = useCallback(
    () => extendUrlQuery({ activeId: null }),
    [extendUrlQuery]
  );

  const handleAddNewPress = useCallback(async () => {
    await getNextSalesOrdersNumber();
    setClone(false);
    extendUrlQuery({ activeId: -1 });
  }, [getNextSalesOrdersNumber, extendUrlQuery]);

  const handleSalesOrderClick = useCallback(
    async (salesOrderId: number) => {
      extendUrlQuery({ activeId: salesOrderId });
      setClone(false);
    },
    [extendUrlQuery]
  );

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  const hideDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const onDeleteClicked = useCallback(() => setDeleteModalVisible(true), []);

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await deleteSalesOrders(selectedItems);
      await fetchSearchResult();
      hideDeleteModal();
    } catch {
      // Ignore error
    }
  }, [selectedItems, hideDeleteModal, fetchSearchResult]);

  const hideUnissueModal = useCallback(() => {
    setUnissueModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const onUnissueClicked = useCallback(() => setUnissueModalVisible(true), []);

  const handleUnissueConfirm = useCallback(async () => {
    setIsLoading(true);
    try {
      await unIssueSalesOrders(selectedItems);
      await fetchSearchResult();
      hideUnissueModal();
    } catch {
      // Ignore error
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [selectedItems, hideUnissueModal, fetchSearchResult]);

  const closeIssueModal = useCallback(() => {
    setIssueModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const handleIssueConfirm = useCallback(async () => {
    setIssueLoadingVisible(true);

    try {
      await multipleIssueSalesOrders(selectedItems);
      await fetchSearchResult();
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error while issuing multiple sales order', {
        error,
        stackTrace: error.stack,
        title: 'Unalble to issue sales orders',
        description: 'Error when issuing multiple orders in Sales Order',
        component: 'SalesOrderPage',
      });
    }

    closeIssueModal();
    setIssueLoadingVisible(false);
  }, [selectedItems, fetchSearchResult, closeIssueModal]);

  const onIssueClicked = useCallback(() => {
    setIssueModalVisible(true);
  }, [setIssueModalVisible]);

  const handleAction = useCallback(
    (type: SalesOrderTableRowActions, value: any) => {
      if (type === SalesOrderTableRowActions.Duplicate) {
        setClone(true);
        extendUrlQuery({ activeId: value });
      }
      if (type === SalesOrderTableRowActions.Delete) {
        setSelectedItems([value as number]);
        onDeleteClicked();
      }
    },
    [extendUrlQuery, onDeleteClicked]
  );

  const exportSalesOrders = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.SalesOrder);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);

  const importSalesOrders = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Sales Order import has been initiated.');

      startCsvFetching();
      event.target.value = '';
      try {
        await startBackgroundImport(BackgroundType.SalesOrder, uploadedFile);
        dispatch(
          showProgressAlert(
            'Your Import to Sales Order has been initiated. This may take a few minutes to complete.',
            BackgroundType.SalesOrder,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);
        const error = e as Error;
        logErrorCtx('Error in startBackgroundImport', {
          stackTrace: error.stack,
          error,
          description: message,
          component: 'SalesOrderPage',
        });
        return;
      }
    },
    [dispatch]
  );

  const handlePageAction = useCallback(
    (action: SalesOrderPageAction, date?: Dates) => {
      switch (action) {
        case SalesOrderPageAction.Export:
          exportSalesOrders();
          break;
        case SalesOrderPageAction.Import:
          importSalesOrders();
          break;
        case SalesOrderPageAction.CustomFields:
          setCustomFieldsModalVisible(true);
          break;
        case SalesOrderPageAction.ShowReport:
          setShowReportModal(true);
          break;
        case SalesOrderPageAction.ChangeDate:
          setActiveDate(date!);
          setPageSettings('salesOrder', 'dateColumn', date);
          break;
      }
    },
    [exportSalesOrders, importSalesOrders, setPageSettings]
  );

  return (
    <ErrorBoundary fallback={FallbackUI}>
      <ModuleNavigation />
      <PageWithAdvancedSearch
        detailCardColumns={SALES_ORDER_COLUMNS(activeDate)}
        initialFormValues={initialSalesOrderFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={SalesOrderAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(
          customers,
          representatives,
          carriers,
          orderPriorities,
          paymentTerms,
          SHIPPING_TERMS,
          locations,
          searchTags,
          intlFormatDate
        )}
        showAllLabel="Show All Orders"
        pageName="Sales Orders"
      >
        <PaperSlidingLayout shown={Boolean(activeSalesOrderId)}>
          <SalesOrderSearchResults
            salesOrders={searchResult.items}
            activeSalesOrderId={activeSalesOrderId}
            handleSalesOrderClick={handleSalesOrderClick}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            onAddNewPress={handleAddNewPress}
            isLoadingSalesOrders={isLoadingSearchResult}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onAction={handleAction}
            onDeleteClicked={onDeleteClicked}
            onPageAction={handlePageAction}
            activeSalesOrderDate={activeDate}
            onUnissueClicked={onUnissueClicked}
            onIssueClicked={onIssueClicked}
          />
          <SalesOrderDetailsCard
            activeSalesOrderId={activeSalesOrderId}
            nextSalesOrdersNumber={nextSalesOrdersNumber}
            onClose={handleActiveSalesOrderClose}
            setNextSalesOrderNumber={setNextSalesOrdersNumber}
            fetchSearchResult={fetchSearchResult}
            clone={clone}
            setClone={setClone}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Sales Order"
        body={`This will delete all selected sales orders and all related sales order items, are you sure?`}
        onCancelClicked={hideDeleteModal}
        onConfirmClicked={handleDeleteConfirm}
        confirmLabel={'Delete'}
        showCloseIcon
        confirmButtonRed
      />
      <ConfirmationModal
        open={unissueModalVisible}
        title="Unissue Sales Order"
        body={`This will unissue all selected sales orders and all related sales order items, are you sure?`}
        onCancelClicked={hideUnissueModal}
        onConfirmClicked={handleUnissueConfirm}
        confirmLabel={'Unissue'}
        confirmButtonRed
        isLoading={isLoading}
      />
      <ConfirmationModal
        open={issueModalVisible}
        title="Issue Sale Orders"
        body="This will issue all selected sale orders, are you sure?"
        onCancelClicked={closeIssueModal}
        onConfirmClicked={handleIssueConfirm}
        confirmLabel={'Issue'}
        isLoading={issueLoadingVisible}
      />
      <CustomFieldsModal
        open={customFieldsModalVisible}
        setVisible={setCustomFieldsModalVisible}
        module={ObjectType.SalesOrder}
      />
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
      <ReportsModal
        isOpen={showReportModal}
        reportId={ReportId.SalesOrderSummary}
        onClose={() => setShowReportModal(false)}
        params={{}}
        autoGenerate
      />
    </ErrorBoundary>
  );
};

SalesOrderPage.route = Routes.SalesOrderPage;

export default withSearchResults<DisplaySalesOrder>(SalesOrderPage, {
  url: '/sales-mgmt/v1/orders?showStockStatus=true',
  expand: '',
  dataAdapter: transformDisplaySalesOrder,
  columns: SALES_ORDER_COLUMNS(Dates.DateCreated),
  getSearches,
  fetchSearch,
  initialPagination,
  quickSearchColumns: [
    'number',
    'salesOrderStatus',
    'customer.name',
    'representative.firstName',
    'location.name',
    'salesOrderItems.name',
    'salesOrderItems.saleItem.name',
    'salesOrderItems.saleItem.sku',
    'salesOrderBundleItems.item.name',
  ],
  rehydrationThunks: [
    fetchCustomers,
    fetchUsers,
    fetchUsersV2,
    fetchLocations,
    fetchPaymentTerms,
    fetchCarriers,
    fetchOrderPriorities,
    fetchTaxRates,
    fetchTags,
    fetchUoms,
    fetchRepresentatives,
    fetchSettingsCompanies,
    fetchSettingsSalesOrders,
    fetchSettingsShipping,
    fetchVendors,
    fetchClasses,
  ],
});

import React from 'react';
import {
  Menu,
  Box,
  Avatar,
  Grid,
  Typography,
  Divider,
  Tooltip,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import {
  useThemeFontSize,
  useThemeTableDensity,
} from 'app/components/AppThemeProvider';
import { ThemeTableDensity } from 'app/components/types';
import { Routes as DownloadsRoutes } from 'ui/modules/notifications/navigation/routes';
import { getActiveUser } from 'services/user';
import { getSettingsCompany } from 'services/settings/company/redux';
import { signOut } from 'services/auth';
import { disableTestEnv, enableTestEnv, isTestEnv } from 'services/api/testEnv';

import { useProfileMenuStyle } from './styled';
import { colorPalette, IconNames, themeRestyle } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOSwitch from '../FBOSwitch/FBOSwitch';
import { useFlags } from 'helpers/useFlags';

interface ProfileMenuProps {
  isMenuOpen: boolean;
  onClose(event: object, reason: string): void;
  anchorEl: null | HTMLElement;
}

const FBOProfileMenu: React.FC<ProfileMenuProps> = (
  props: ProfileMenuProps
) => {
  const { isMenuOpen, onClose, anchorEl } = props;

  const history = useHistory();
  const { themeSettings, setTableDensity } = useThemeTableDensity();
  const { setFontSizeMultiplier } = useThemeFontSize();

  const classes = useProfileMenuStyle();

  const dispatch = useDispatch();
  const activeUser = useSelector(getActiveUser);
  const { name: companyName } = useSelector(getSettingsCompany);

  const flags = useFlags();

  const handleLogOut = (e: React.MouseEvent<HTMLElement | MouseEvent>) => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(signOut());
    onClose({ currentTarget: e.currentTarget }, 'logging out');
  };

  const nameFirst = _.get(activeUser, 'user.firstName', '');
  const nameLast = _.get(activeUser, 'user.lastName', '');
  const email = _.get(activeUser, 'user.email', '');

  const initials = `${(nameFirst || ' ')[0]}${
    (nameLast || ' ')[0]
  }`.toUpperCase();

  const handleTestEnvChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      enableTestEnv();

      history.replace(history.location.pathname);
      history.go(0);
    } else {
      disableTestEnv();

      history.replace(history.location.pathname);
      history.go(0);
    }
  };

  const toggleTableDensity = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setTableDensity(
      checked ? ThemeTableDensity.Small : ThemeTableDensity.Medium
    );
  };

  const handleFontSizeMultiplier = (value: number) => {
    document.documentElement.style.setProperty(
      '--font-size-multiplier',
      `${value}`
    );
    setFontSizeMultiplier(value);
  };

  const closeDownloads = (e: React.MouseEvent<HTMLElement | MouseEvent>) => {
    onClose({ currentTarget: e.currentTarget }, 'backdropClick');
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted={true}
      open={isMenuOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box p={2} width={350} className="redesign profile">
        <Grid container spacing={2}>
          <Grid item xs={3} className="avatar">
            <Avatar>{initials}</Avatar>
          </Grid>
          <Grid item xs={9}>
            <Typography className="names">{`${nameFirst} ${nameLast}`}</Typography>
            <Tooltip title={email}>
              <Typography className="email">{email}</Typography>
            </Tooltip>
            <Typography className="company-name">{companyName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Link
              to={DownloadsRoutes.DownloadsPage}
              className={classes.navigationLink}
              onClick={closeDownloads}
            >
              <GetAppIcon fontSize="small" color="primary" />
              <Typography variant="body1" color="textPrimary">
                Downloads
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
            <FBOSwitch
              name="Sample Database Switch"
              size="small"
              label={isTestEnv() ? 'Viewing Sample Data' : 'View Sample Data'}
              labelPlacement="end"
              checked={isTestEnv()}
              dataQa="sample-data-switch"
              onChange={handleTestEnvChange}
              sx={{
                margin: `${themeRestyle.spacing(2)} ${themeRestyle.spacing(1)}`,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: '0px !important',
            }}
          >
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
            <FBOSwitch
              name="Dense tables"
              size="small"
              label={'Dense tables'}
              labelPlacement="end"
              checked={true}
              // ABOVE PROP: themeSettings.tableDensity === ThemeTableDensity.Small
              dataQa="table-density-switch"
              onChange={toggleTableDensity}
              sx={{
                margin: `${themeRestyle.spacing(2)} ${themeRestyle.spacing(1)}`,
              }}
              disabled={true} // this needs to be true for now since toggling it breaks tables
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box px={1}>
              <Typography variant="body1" color="textSecondary">
                Font Size
              </Typography>
            </Box>
            <Box px={3} pt={2} pb={5}>
              <Slider
                min={1}
                max={1.3}
                defaultValue={
                  themeSettings.fontSizeMultiplier
                    ? themeSettings.fontSizeMultiplier
                    : 1
                }
                marks={{
                  1: {
                    style: {
                      height: 22,
                      lineHeight: '22px',
                    },
                    label: 'A',
                  },
                  1.1: {},
                  1.2: {},
                  1.3: {
                    style: {
                      fontSize: 16,
                      height: 22,
                      lineHeight: '22px',
                    },
                    label: 'A',
                  },
                }}
                step={null}
                onChange={handleFontSizeMultiplier}
                handleStyle={{
                  borderColor: colorPalette.redesign.lightPrimaryBlue,
                }}
                trackStyle={{
                  backgroundColor: colorPalette.redesign.lightPrimaryBlue,
                }}
                activeDotStyle={{
                  borderColor: colorPalette.redesign.lightPrimaryBlue,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: '18px' }}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              gap: '8px',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            {flags.commerceIntegrationUiRefactor && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={'https://help.fishbowlonline.com/'}
              >
                <FBOButton
                  size="medium"
                  variant="secondary"
                  icon={IconNames.MenuHelp}
                  color="positive"
                  data-qa="log-out-button"
                >
                  Help
                </FBOButton>
              </a>
            )}
            <FBOButton
              size="medium"
              variant="primary"
              color="positive"
              sx={{
                flex: flags.commerceIntegrationUiRefactor ? '1 1 0%' : 'unset',
              }}
              onClick={handleLogOut}
              data-qa="log-out-button"
            >
              Log Out
            </FBOButton>
          </Grid>
        </Grid>
      </Box>
    </Menu>
  );
};

export default React.memo(FBOProfileMenu);

import { RowDescriptor, RowType } from '../Card';

export const emailCardId = 'email-card-navigation-id';

export const title = 'Emailing';

export const emailRowDescriptor: RowDescriptor[] = [
  {
    type: RowType.EmailToggle,
    title: 'Email Integration',
    field: 'emailIntegration',
  },

  {
    type: RowType.OrderEmailToogle,
    title: 'Send Order Emails',
    field: 'sendOrderEmails',
  },
];

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { colorStatusPaletteMap } from 'ui/theme/colors/statusColors';

import { TitleBarStyleProps } from './types';
import { SupportColors } from 'ui/theme';

// Lint skip to be removed
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useTitleBarStyle = makeStyles<Theme, TitleBarStyleProps>(
  (theme: Theme) => ({
    titleBar: {
      backgroundColor: theme.palette.titleBar.primary,
      display: 'flex',
      alignItems: 'center',
      height: 42,
      maxHeight: 42,
      borderTopLeftRadius: ({ noBorderRadius }) => (noBorderRadius ? 0 : 4),
      borderTopRightRadius: ({ noBorderRadius }) => (noBorderRadius ? 0 : 4),
      flexShrink: 0,
      '& .MuiButton-text, & .MuiIconButton-root': {
        height: 42,
        borderRadius: 0,
        borderLeft: `1px solid ${SupportColors.dividerOnPrimaryMain}`,
        '&.Mui-disabled': {
          color: '#8A8A8A',
        },
      },
      '& .MuiAutocomplete-endAdornment > .MuiIconButton-root': {
        height: 'initial',
        borderRadius: 0,
        borderLeft: 'none',
      },
    },
    whiteText: {
      color: theme.palette.common.white,
      alignSelf: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    status: {
      marginLeft: theme.spacing(2),
      borderRadius: 20,
      background: ({ status }) => {
        const colors = status ? colorStatusPaletteMap[status] : 'transparent';
        return `linear-gradient(-45deg, ${colors[0]} 50%, ${colors[1]} 50%)`;
      },
    },
    statusLabel: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1,
    },
    image: {
      marginRight: '10px',
    },
  })
);

import React, { memo } from 'react';
import { Box, Paper } from '@mui/material';

import Wizard from './Wizard';
import { CardWizardProps } from './types';
import { useWizardStyle } from './styled';

const CardWizard: React.FC<CardWizardProps> = (props) => {
  const classes = useWizardStyle();

  return (
    <Paper className={classes.paper}>
      <Box className={classes.container}>
        <Wizard {...props} />
      </Box>
    </Paper>
  );
};

export default memo(CardWizard);

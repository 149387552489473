import {
  DIMENSIONS_UNITS,
  Fob,
  FOBS,
  ShippingTerm,
  SHIPPING_TERMS,
} from 'services/items';
import { PaymentTerm } from 'services/paymentTerms';
import { CompanySettings } from 'services/settings/company';
import { OrderPriority } from 'services/settings/orderPriorities';
import { DefaultWeightUom } from 'services/settings/shipping';

import { RowDescriptor, RowType } from '../Card';
import { CostingMethod } from './types';

export const companyCardId = 'company-card-navigation-id';

export const title = 'Company';
const shippingTerms = SHIPPING_TERMS;

export const companyRowDescriptor = (
  paymentTerms: PaymentTerm[] = [],
  orderPriorities: OrderPriority[] = []
): RowDescriptor[] => [
  {
    type: RowType.Image,
    title: 'Logo',
    field: 'imageUrl',
    iconSource: 'DefaultCompanyLogo',
  },
  {
    type: RowType.Text,
    title: 'Name',
    field: 'name',
    dataQa: 'company-name',
  },
  {
    type: RowType.Text,
    title: 'Tax Number',
    field: 'taxExemptNumber',
    dataQa: 'company-tax-exempt-number',
  },
  {
    type: RowType.HyperText,
    title: 'Website',
    field: 'url',
    dataQa: 'company-website',
  },
  {
    type: RowType.Text,
    title: 'Email',
    field: 'email',
  },
  {
    type: RowType.Text,
    title: 'Main contact phone',
    field: 'mainPhone',
    dataQa: 'company-main-contact-phone',
  },
  {
    type: RowType.Text,
    title: 'Business number',
    field: 'businessNumber',
    dataQa: 'company-business-number',
  },
  {
    type: RowType.CountryAutocomplete,
    title: 'Country',
    field: 'country',
    disabled: true,
  },
  {
    type: RowType.Boolean,
    title: 'Use Multicurrency',
    field: 'useMultiCurrency',
    subBooleanRows: [
      {
        type: RowType.Boolean,
        title: 'Display Currency Code for Reports',
        field: 'displayCurrencyCodeForReports',
      },
    ],
  },
  {
    type: RowType.TimezoneAutocomplete,
    title: 'Time zone',
    field: 'timezone',
    dataQa: 'company-timezone',
  },
  {
    type: RowType.Text,
    title: 'Default home currency',
    field: 'homeCurrency.name',
    dataQa: 'company-home-currency',
    disabled: true,
  },
  {
    type: RowType.LocationPicker,
    title: 'Default location',
    field: 'defaultLocation',
    dataQa: 'company-default-location',
  },
  {
    type: RowType.Autocomplete,
    title: 'Default weight unit',
    field: 'defaultWeightUom',
    options: Object.values(DefaultWeightUom),
    dataQa: 'default-weight-unit',
  },
  {
    type: RowType.Autocomplete,
    title: 'Default dimension unit',
    field: 'defaultDimensionUom',
    options: DIMENSIONS_UNITS.map((unit) => unit.name),
    dataQa: 'default-dimension-unit',
  },
  // {
  //   type: RowType.Text,
  //   title: 'Tax exempt',
  //   field: 'taxExemptNumber',
  //   showTextAsBoolean: true,
  //   handleShowTextAsBoolean: (value, field, setCardData) => {
  //     setCardData('taxExempt', value ? 'true' : 'false');
  //     setCardData(field, value);
  //   },
  // },
  {
    type: RowType.Autocomplete,
    title: 'Default payment term',
    field: 'defaultPaymentTerm',
    options: paymentTerms,
    getOptionsLabel: (option: PaymentTerm) =>
      option.name || 'Unknown default payment term',
    isOptionEqualToValue: (option: PaymentTerm, value: PaymentTerm) =>
      option.id === value.id,

    handleAutocompleteChange: (e, value, field, setCardData) => {
      setCardData('defaultPaymentTerm', value);
      setCardData('defaultPaymentTermId', value ? value.id : null);
    },
    dataQa: 'company-default-payment-term',
  },
  {
    type: RowType.Autocomplete,
    title: 'Default shipping term',
    field: 'defaultShippingTerm',
    options: shippingTerms,
    getOptionsLabel: (option: ShippingTerm) => option.name,
    isOptionEqualToValue: (option: ShippingTerm, value: ShippingTerm) =>
      option.id === value.id,
    handleAutocompleteChange: (e, value, field, setCardData) => {
      setCardData('defaultShippingTerm', value);
      setCardData('defaultShippingTermId', value ? value.id : null);
    },
    dataQa: 'company-default-shipping-term',
  },
  {
    type: RowType.Autocomplete,
    title: 'Default FOB point',
    field: 'defaultFobPoint',
    options: FOBS,
    getOptionsLabel: (option: Fob) => option.name || '',
    isOptionEqualToValue: (option: Fob, value: Fob) => option.id === value.id,
    handleAutocompleteChange: (e, value, field, setCardData) => {
      setCardData('defaultFobPoint', value);
      setCardData('defaultFobPointId', value ? value.id : null);
    },
    dataQa: 'company-default-fob-point',
  },
  {
    type: RowType.Autocomplete,
    title: 'Default priority',
    field: 'defaultPriority',
    options: orderPriorities,
    getOptionsLabel: (option: OrderPriority) => option.name || '',
    isOptionEqualToValue: (option: OrderPriority, value: OrderPriority) =>
      option.id === value.id,
    handleAutocompleteChange: (e, value, field, setCardData) => {
      setCardData('defaultPriority', value);
      setCardData('defaultPriorityId', value ? value.id : null);
    },
    dataQa: 'company-default-priority',
  },
  {
    type: RowType.Autocomplete,
    title: 'Costing method',
    field: 'accountingMethod',
    isFieldDisabled: (option: CompanySettings) => !!option.goLiveDate,
    options: Object.values(CostingMethod),
    dataQa: 'company-costing-method',
  },
  {
    type: RowType.GoLiveToggle,
    title: 'Go live',
    field: 'goLiveDate',
    dataQa: 'company-go-live',
  },
];

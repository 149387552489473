import React from 'react';
import { Switch } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes } from './routes';
import { ReportsPage } from '../pages';
import { ReportsRouterCmp, ReportsRouterProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReportsRouter: ReportsRouterCmp = (props: ReportsRouterProps) => {
  return (
    <Switch>
      <SecureRoute path={ReportsPage.route} exact component={ReportsPage} />
    </Switch>
  );
};

ReportsRouter.route = Routes.ReportsRouter;

export default ReportsRouter;

export enum Routes {
  SetupRouter = '/setup',
  UnitOfMeasurementPage = '/setup/unit-of-measurement',
  LocationPage = '/setup/location',
  UsersPage = '/setup/users',
  PermissionsPage = '/setup/permissions',
  PaymentTermsPage = '/setup/payment-terms',
  RepresentativesPage = '/setup/representatives',
  TaxRatesPage = '/setup/tax-rates',
  CarriersPage = '/setup/carriers',
  CurrencyPage = '/setup/currency',
  SettingsPage = '/setup/settings',
}

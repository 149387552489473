import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { fetchSearch, getSearches } from 'services/vendors/redux';
import {
  deleteVendor,
  deleteVendors,
  transformVendor,
  Vendor,
} from 'services/vendors';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { getPaymentTerms, fetchPaymentTerms } from 'services/paymentTerms';
import { Pagination } from 'services/search';
import { fetchCarriers } from 'services/carriers';
import { fetchTags, fetchTagsAPI, Tag } from 'services/tags';
import { fetchOrderPriorities } from 'services/settings/orderPriorities';
import { fetchSettingsCompanies } from 'services/settings/company/redux';
import { useUrlQueryObject } from 'services/url';
import { CustomFieldsModal } from 'ui/components/CustomFields/CustomFieldsModal';
import { ObjectType } from 'services/customFields';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import { fetchUoms } from 'services/uoms';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { Dates, getErrorMessage, useGetIntlDateFormatString } from 'helpers';
import { showProgressAlert } from 'services/alert/redux';
import { fetchClasses } from 'services/classes';

import { VendorPageCmp, VendorsPageProps } from './types';
import {
  advancedSearchReduxActions,
  initialVendorsFormValues,
  displayNameMap,
  createDisplayValueMap,
  initialPagination,
  VENDOR_COLUMNS,
} from './consts';
import {
  VendorPageAction,
  VendorPageRowAction,
} from './components/VendorsSearchResults/consts';
import { Routes } from '../../navigation';
import {
  VendorsSearchResults,
  VendorDetailsCard,
  VendorsAdvancedSearch,
} from './components';
import { logErrorCtx } from 'app/logging';

const VendorsPage: VendorPageCmp = (props: VendorsPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeVendorId,
  } = props;

  const { items: paymentTerms } = useSelector(getPaymentTerms);

  const [selectedVendors, setSelectedVendors] = useState<number[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState<Vendor | null>();
  const [customFieldsModalVisible, setCustomFieldsModalVisible] =
    useState(false);
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [searchTags, setSearchTags] = useState<Tag[]>([]);

  const [, extendUrlQuery] = useUrlQueryObject();
  const dispatch = useDispatch();

  const intlFormatDate = useGetIntlDateFormatString();

  const hiddenInput = useRef<HTMLInputElement>(null);

  const { startFetching, startCsvFetching } = useBackgroundTasks();

  // watch advanced search columns and fetch selected tags
  useEffect(() => {
    const tagIds = _.get(
      searchResult.advancedSearch.columns,
      ['tags.id'],
      null
    ) as number[];

    if (tagIds) {
      (async () => {
        try {
          const resTags = await fetchTagsAPI({}, tagIds);

          setSearchTags(resTags.data);
        } catch {
          //ignore Error
        }
      })();
    }
  }, [searchResult.advancedSearch.columns]);

  const exportVendors = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.Vendor);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);

  const importVendors = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Vendors import has been initiated.');

      startCsvFetching();
      event.target.value = '';
      try {
        await startBackgroundImport(BackgroundType.Vendor, uploadedFile);
        dispatch(
          showProgressAlert(
            'Your Import to Vendors has been initiated. This may take a few minutes to complete.',
            BackgroundType.Vendor,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);
        const error = e as Error;
        logErrorCtx('Error in startBackgroundImport', {
          stackTrace: error.stack,
          error,
          description: message,
          component: 'VendorsPage',
        });
        return;
      }
    },
    [dispatch]
  );

  const handleActiveVendorClose = () => extendUrlQuery({ activeId: null });

  const onDeleteClicked = useCallback(() => setDeleteModalVisible(true), []);

  const handleAddNewPress = useCallback(() => {
    extendUrlQuery({ activeId: -1 });
  }, [extendUrlQuery]);

  const handleVendorPageAction = useCallback(
    (action: VendorPageAction, date?: Dates) => {
      switch (action) {
        case VendorPageAction.Export:
          exportVendors();
          break;
        case VendorPageAction.Import:
          importVendors();
          break;
        case VendorPageAction.CustomFields:
          setCustomFieldsModalVisible(true);
          break;
        case VendorPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    [exportVendors, importVendors]
  );

  const handleAction = useCallback(
    async (type: VendorPageRowAction, value: any) => {
      switch (type) {
        case VendorPageRowAction.Delete: {
          setVendorToDelete(value);
          break;
        }
      }
    },
    []
  );

  const handleDeleteSingle = useCallback(async () => {
    setDeleteModalLoading(true);
    try {
      await deleteVendor(vendorToDelete!.id!);
      await fetchSearchResult();
      setVendorToDelete(null);
    } catch {
      // Ignore error
    }
    setDeleteModalLoading(false);
  }, [vendorToDelete, fetchSearchResult]);

  const hideDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
    setSelectedVendors([]);
  }, [setSelectedVendors]);

  const handleDeleteConfirm = useCallback(async () => {
    setDeleteModalLoading(true);
    try {
      await deleteVendors(selectedVendors);
    } catch {
      setDeleteModalLoading(false);
      return;
    }

    fetchSearchResult();
    setDeleteModalLoading(false);
    hideDeleteModal();
  }, [selectedVendors, hideDeleteModal, fetchSearchResult]);

  const vendorClicked = useCallback(
    async (vendorId: number) => {
      extendUrlQuery({ activeId: vendorId });
    },
    [extendUrlQuery]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    fetchSearchResult({ pagination: newPagination });
  };

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={VENDOR_COLUMNS(activeDate)}
        initialFormValues={initialVendorsFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={VendorsAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(
          paymentTerms,
          searchTags,
          intlFormatDate
        )}
        pageName="Vendors"
      >
        <PaperSlidingLayout shown={Boolean(activeVendorId)}>
          <VendorsSearchResults
            activeVendorId={activeVendorId}
            handleVendorClick={vendorClicked}
            isLoadingVendors={isLoadingSearchResult}
            selectedVendors={selectedVendors}
            setSelectedVendors={setSelectedVendors}
            onAddNewPress={handleAddNewPress}
            onPaginationChange={handlePaginationChange}
            pagination={searchResult.pagination || initialPagination}
            vendors={searchResult.items}
            onDeleteClicked={onDeleteClicked}
            onAction={handleAction}
            activeDate={activeDate}
            onVendorPageAction={handleVendorPageAction}
          />
          <VendorDetailsCard
            activeVendorId={activeVendorId}
            onClose={handleActiveVendorClose}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Vendor"
        body={`This will delete all selected Vendors, are you sure?`}
        onCancelClicked={hideDeleteModal}
        onConfirmClicked={handleDeleteConfirm}
        isLoading={deleteModalLoading}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
      {vendorToDelete && (
        <ConfirmationModal
          open
          title="Delete Vendor"
          body={`Are you sure you want to delete ${vendorToDelete.name}`}
          onCancelClicked={() => setVendorToDelete(null)}
          onConfirmClicked={handleDeleteSingle}
          isLoading={deleteModalLoading}
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmButtonRed
        />
      )}
      <CustomFieldsModal
        open={customFieldsModalVisible}
        setVisible={setCustomFieldsModalVisible}
        module={ObjectType.Vendor}
      />
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
    </>
  );
};

VendorsPage.route = Routes.VendorsPage;

export default withSearchResults<Vendor>(VendorsPage, {
  url: '/v1/vendors?expand=paymentTerm,vendorAddresses',
  dataAdapter: transformVendor,
  columns: VENDOR_COLUMNS(Dates.DateCreated),
  fetchSearch,
  getSearches,
  initialPagination,
  rehydrationThunks: [
    fetchPaymentTerms,
    fetchCarriers,
    fetchOrderPriorities,
    fetchTags,
    fetchSettingsCompanies,
    fetchUoms,
    fetchClasses,
  ],
});

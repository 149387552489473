import React, { memo, useCallback, useMemo, useState } from 'react';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { fetchItem, Item, ItemType } from 'services/items';
import { Pagination } from 'services/search';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { PaginationDirection } from 'services/url';

import { PoAsyncItemsAutocompleteProps } from './types';
import NewItemModal from './NewItemModal';
import {
  itemsAutocompleteFilterOptions,
  itemsAutocompleteGetOptionLabel,
  poHasDropShipItem,
  resolvedFetchItemsFunction,
} from './helpers';
import { useAsyncAutocomplete } from '../Autocomplete/hooks';
import { useSalesOrderItemsStyle } from './styled';
import { PurchaseItemOption } from './components';

const initialItemPagination: Pagination = {
  ...initialPagination,
  pageSize: 50,
  sort: { sortBy: 'name', direction: PaginationDirection.Ascending },
};

const PurchaseOrderItemsAsyncAutocomplete: React.FC<
  PoAsyncItemsAutocompleteProps
> = (props) => {
  const {
    label,
    value,
    placeholder,
    purchaseOrder,
    removedIds = [],
    required,
    onChange,
    disableAdd = false,
    additionalInputProps,
    itemTypes = [
      'Inventory',
      'Shipping',
      'Service',
      'Non Inventory',
      'Labor',
      'Overhead',
    ] as ItemType[],
    dataQa,
    error,
    expands = [
      'images',
      'tags',
      'vendorItems',
      'itemTrackingTypes',
      'itemUomConversions',
      'itemUomConversions.uomConversion',
      'defaultUom.fromConversions',
      'defaultUom.toConversions',
    ],
    onEnterClick,
    customQuickSearchColumns = [
      'name',
      'description',
      'sku',
      'vendorItems.name',
      'tags.name',
    ],
    onlySaleItems,
    sx = {},
    onlyShowVendorPartsInThePartDropDownFilter = false,
  } = props;
  const hasDropShipItems = useMemo(() => {
    return poHasDropShipItem(purchaseOrder.purchaseOrderItemList);
  }, [purchaseOrder]);
  const poVendorId = purchaseOrder.vendorId;
  const isPurchaseOrder = true;
  const {
    data: items,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
    setData,
  } = useAsyncAutocomplete(
    value,
    resolvedFetchItemsFunction(
      itemTypes,
      expands,
      customQuickSearchColumns,
      onlySaleItems,
      hasDropShipItems,
      poVendorId,
      isPurchaseOrder,
      onlyShowVendorPartsInThePartDropDownFilter
    ),
    fetchItem,
    initialItemPagination
  );
  const classes = useSalesOrderItemsStyle();

  const [newItemName, setNewItemName] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [highlightValue, setHighlightValue] = useState<Item | null>(null);
  const filteredItems = useMemo(
    () => items.filter((item) => !removedIds.includes(item.id!)),
    [items, removedIds]
  );

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, item: Item | null) => {
    if (item && (item.id === null || item.id < 0)) {
      setNewItemName(item.name);
      setShowModal(true);
      return;
    }

    setInnerValue(item);
    onChange(item);
  };
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // handle enter click only if autocomplete is closed and value is selected
      if (e.keyCode === 13 && !open && value && onEnterClick) {
        onEnterClick();
      }
      if (e.keyCode === 9 && open && highlightValue) {
        // on tab click set value to highlighted item or if add on fly show new item modal
        if (highlightValue && highlightValue.id === -1) {
          setShowModal(true);
          setNewItemName(highlightValue.name);
          setHighlightValue(null);
          return;
        }

        onChange(highlightValue);
      }
    },
    [open, value, highlightValue, onEnterClick, onChange]
  );

  const handleHighlightChange = (ev: any, option: Item | null, reason: any) => {
    if (option && reason === 'keyboard') {
      if (option.id === null || option.id < 0) {
        setNewItemName(option.name);
        setShowModal(true);
        return;
      }

      setInnerValue(option);
      onChange(option);
    } else if (option) {
      setHighlightValue(option);
    }
  };
  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleSaveModal = useCallback(
    async (item: Item) => {
      setShowModal(false);
      setData([item]);
      setInnerValue(item);
      onChange(item);
    },
    [onChange, setData, setInnerValue]
  );

  return (
    <>
      <AutoComplete
        sx={sx}
        className="redesign"
        options={filteredItems}
        open={open}
        onOpen={onOpenAutocomplete}
        onClose={onCloseAutocomplete}
        onChange={handleChange}
        onHighlightChange={handleHighlightChange}
        filterOptions={itemsAutocompleteFilterOptions(disableAdd)}
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={itemsAutocompleteGetOptionLabel}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        renderOption={(PoItemsAutocompleteProps, option: Item) => (
          <li {...PoItemsAutocompleteProps} key={option.id}>
            <PurchaseItemOption vendorId={option.vendorId} option={option} />
          </li>
        )}
        value={innerValue}
        autoSelect={false}
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const node = event.currentTarget;
            if (
              Math.ceil(node.scrollTop + node.clientHeight) ===
              node.scrollHeight
            ) {
              onNextPage();
            }
          },
        }}
        renderInput={(params) => {
          // params.InputProps.endAdornment is component with 2 children
          // first child is clear button, so we want to set it to null
          const endAdornment = params.InputProps.endAdornment as any;

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornment}
                  </React.Fragment>
                ),
                classes: {
                  root: classes.searchInputInner,
                  notchedOutline: classes.noBorder,
                },
              }}
              inputProps={{
                'data-qa': dataQa,
                onKeyDown: handleKeyDown,
                ...params.inputProps,
              }}
            />
          );
        }}
        classes={{
          root: classes.searchInputOuter,
          option: classes.searchOption,
        }}
        clearIcon={
          <CloseIcon
            fontSize="small"
            data-qa={`${dataQa}-autocomplete-clear-icon`}
          />
        }
        popupIcon={
          <ArrowDropDownIcon
            fontSize="small"
            data-qa={`${dataQa}-autocomplete-dropdown-icon`}
          />
        }
      />
      <NewItemModal
        show={showModal}
        newItemName={newItemName}
        itemTypes={itemTypes}
        onSave={handleSaveModal}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default memo(PurchaseOrderItemsAsyncAutocomplete);

import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { clearEnvironment } from 'services/sandbox';
import { sideDrawerStatus } from '../FBODrawer';
import { Icon } from 'ui/components/Icon';

const ClearSandboxData = React.forwardRef((_props, ref) => {
  const { drawerExpanded } = useContext(sideDrawerStatus);

  const [clearModalVisible, setClearModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const clearDataClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await clearEnvironment();
      history.go(0);
    } catch {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setClearModalVisible(false);
  }, [history]);
  useEffect(() => {}, [drawerExpanded]);
  return (
    <>
      {drawerExpanded && (
        <Button
          ref={ref as React.RefObject<HTMLButtonElement>}
          onClick={() => setClearModalVisible(true)}
          className="clear"
        >
          <Icon name="TrashCan" height={13.33} width={13.33} />
          Clear Data
        </Button>
      )}

      <ConfirmationModal
        open={clearModalVisible}
        title="Clear sandbox data"
        onConfirmClicked={clearDataClicked}
        onCancelClicked={() => setClearModalVisible(false)}
        body="You are about to clear your sandbox data. Do you want to continue?"
        confirmLabel="Yes"
        isLoading={isLoading}
      />
    </>
  );
});

export default memo(ClearSandboxData);

import React, { memo, useCallback, useContext, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { validateYup } from 'services/forms/validation';
import { generalSettingsValidation } from 'services/integrations/shipping/shippo/validations';
import { TitleBar } from 'ui/components/TitleBar';

import { ShipStationSettingsModalType, ShipStationCardProps } from './types';
import { ShipStationContext } from '../../../../ShipStationProvider';
import { useShipStationCardStyle } from './styled';
import { ShipStationCardModal } from './components';
import { ShipStationLogoSmall } from 'ui/theme';

const ShipStationCard: React.FC<ShipStationCardProps> = () => {
  const [activeModal, setActiveModal] =
    useState<ShipStationSettingsModalType | null>(null);

  const { settings, saveSettings, setErrors, isLoading } =
    useContext(ShipStationContext);

  const classes = useShipStationCardStyle();

  const [isLoadingApply, setIsLoadingApply] = useState(false);

  const openModal = useCallback(
    (type: ShipStationSettingsModalType) => () => {
      setActiveModal(type);
    },
    []
  );

  const handleCloseModal = () => setActiveModal(null);

  const handleApplyClicked =
    (type: ShipStationSettingsModalType) => async () => {
      //validation only for general setting
      if (
        type === ShipStationSettingsModalType.GeneralSettings &&
        !validateYup(settings, generalSettingsValidation, setErrors)
      ) {
        return;
      }

      setIsLoadingApply(true);
      await saveSettings();

      setActiveModal(null);
      setIsLoadingApply(false);
    };

  return (
    <>
      <Box className={classes.container}>
        <Paper id="shipstation" className={classes.paper}>
          <TitleBar
            title="ShipStation"
            statusCustom="Beta"
            imageUrl={ShipStationLogoSmall}
          />

          <Box className={classes.cardRow}>
            <Typography variant="body1" color="textPrimary">
              Status
            </Typography>

            <Typography variant="body1" color="primary">
              Connected
            </Typography>
          </Box>
          <>
            <Box
              className={`${classes.cardRow} ${classes.cardRowClickable}`}
              onClick={openModal(ShipStationSettingsModalType.GeneralSettings)}
            >
              <Typography variant="body1" color="textPrimary">
                General Settings
              </Typography>

              <ArrowRightIcon />
            </Box>

            <Box
              className={`${classes.cardRow} ${classes.cardRowClickable}`}
              onClick={openModal(
                ShipStationSettingsModalType.InternationalSettings
              )}
            >
              <Typography variant="body1" color="textPrimary">
                International Settings
              </Typography>

              <ArrowRightIcon />
            </Box>

            <Box
              className={`${classes.cardRow} ${classes.cardRowClickable}`}
              onClick={openModal(
                ShipStationSettingsModalType.ShippingExtrasSettings
              )}
            >
              <Typography variant="body1" color="textPrimary">
                Shipping Extras
              </Typography>

              <ArrowRightIcon />
            </Box>
          </>
        </Paper>
      </Box>

      <ShipStationCardModal
        open={activeModal === ShipStationSettingsModalType.GeneralSettings}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked(
          ShipStationSettingsModalType.GeneralSettings
        )}
        isLoadingContent={isLoading}
        isLoadingApply={isLoadingApply}
        modalType={ShipStationSettingsModalType.GeneralSettings}
      />

      <ShipStationCardModal
        open={
          activeModal === ShipStationSettingsModalType.InternationalSettings
        }
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked(
          ShipStationSettingsModalType.InternationalSettings
        )}
        isLoadingContent={isLoading}
        isLoadingApply={isLoadingApply}
        modalType={ShipStationSettingsModalType.InternationalSettings}
      />

      <ShipStationCardModal
        open={
          activeModal === ShipStationSettingsModalType.ShippingExtrasSettings
        }
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked(
          ShipStationSettingsModalType.ShippingExtrasSettings
        )}
        isLoadingContent={isLoading}
        isLoadingApply={isLoadingApply}
        modalType={ShipStationSettingsModalType.ShippingExtrasSettings}
      />
    </>
  );
};

export default memo(ShipStationCard);

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { RumRoute as Route } from '@datadog/rum-react-integration';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import axios, { AxiosResponse } from 'axios';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import { snackbarOptions } from 'ui/components/Snackbar';
import {
  getAccessToken,
  clearAccessToken,
  AuthActionTypes,
} from 'services/auth';
import { NotificationManager } from 'services/api/notifications';
import { isTestEnv } from 'services/api/testEnv';
import { AnalyticsProvider } from 'services/analytics/AnalyticsProvider';
import { BackgroundTaskProvider } from 'services/backgroundTasks';
import {
  AuthWrapper,
  LoginPage,
  CreateAccountPage,
  ForgotPasswordPage,
  ChangePasswordPage,
  TermsOfServicePage,
  QboDisconnectedPage,
} from 'ui/modules/public';
import { AlertModal } from 'ui/components/Modal/AlertModal';
import { AlertProgressModal } from 'ui/components/Modal/AlertProgressModal';
import { reportingApi, tenantIdHeaderInterceptor } from 'services/api/config';
import MaintenancePage from 'app/MaintenacePage/MaintenancePage';
import { useFlags } from 'helpers/useFlags';

import { store } from '../redux';
import AppHome from './AppHome/AppHome';
import { AppThemeProvider } from './AppThemeProvider';
import { ThumbnailModal } from 'ui/components/Modal/ThumbnailModal';

// Datadog
import DatadogMonitor from 'app/monitoring';
import DatadogLogger from 'app/logging';
import FBOSnackbar from 'ui/theme/components/FBOSnackbar/FBOSnackbar';
import { SnackbarProps } from './types';
import GlobalStylesRedesign from 'ui/theme/GlobalStylesRedesign';
import { Auth0Wrapper } from 'ui/modules/public/components/Auth0Wrapper/Auth0Wrapper';

axios.interceptors.request.use(tenantIdHeaderInterceptor);
// when we receive 401 error, we sign out the logged in user
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    if (
      error.response !== undefined &&
      error.response.status === 401 &&
      !error.response.config.url.includes('fboshipping.initdevelopment.com') &&
      !error.response.config.url.includes('ship-dev.fishbowlonline.com') &&
      !error.response.config.url.includes('ship.fishbowlonline.com') &&
      !error.response.config.url.includes(
        'fishbowlonline.com/v1/admin/login_as'
      )
    ) {
      clearAccessToken();
      store.dispatch({
        type: AuthActionTypes.SIGN_OUT_ERROR,
        payload: error,
      });
    }
    return Promise.reject(error);
  }
);

// check if test env
if (isTestEnv()) {
  axios.defaults.headers.common.environment = 'test';
  reportingApi.defaults.headers.common.environment = 'test';
}
// if there's an access token in storage, set it in redux and
// fetch current user
const accessToken = getAccessToken();
if (accessToken) {
  store.dispatch({
    type: AuthActionTypes.SIGN_IN_SUCCESS,
    payload: { token: accessToken },
  });
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const appWrapper = (reduxStore: any, flags: any, children: any) => {
  const snackbarComponents = {
    info: FBOSnackbar,
    error: FBOSnackbar,
    warning: FBOSnackbar,
    success: FBOSnackbar,
  };
  const isDriveAuth0Enabled = flags.driveAuth0Enabled;
  const SelectedAuthProvider = isDriveAuth0Enabled ? Auth0Wrapper : AuthWrapper;
  return (
    <Provider store={reduxStore}>
      <DatadogMonitor>
        <DatadogLogger>
          <AppThemeProvider>
            <AnalyticsProvider>
              <QueryClientProvider client={queryClient}>
                <GlobalStylesRedesign />
                <CssBaseline />
                <BrowserRouter>
                  <SnackbarProvider
                    {...snackbarOptions}
                    Components={snackbarComponents}
                    SnackbarProps={
                      {
                        'data-qa': 'client-snackbar',
                      } as SnackbarProps
                    }
                  >
                    <BackgroundTaskProvider>
                      <AlertModal />
                      <ThumbnailModal />
                      <AlertProgressModal />
                      <NotificationManager />
                      <SelectedAuthProvider>{children}</SelectedAuthProvider>
                    </BackgroundTaskProvider>
                  </SnackbarProvider>
                </BrowserRouter>
              </QueryClientProvider>
            </AnalyticsProvider>
          </AppThemeProvider>
        </DatadogLogger>
      </DatadogMonitor>
    </Provider>
  );
};

function App() {
  const flags = useFlags();
  const maintenance = flags.maintenancePage;

  useEffect(() => {
    const scriptOne = document.createElement('script');
    scriptOne.type = 'text/javascript';
    scriptOne.innerHTML = `window.AppcuesSettings = {
        enableURLDetection: true,
      }`;
    document.body.appendChild(scriptOne);

    const scriptTwo = document.createElement('script');
    scriptTwo.src = '//fast.appcues.com/212977.js';
    scriptTwo.async = true;
    document.body.appendChild(scriptTwo);

    return () => {
      document.body.removeChild(scriptOne);
      document.body.removeChild(scriptTwo);
    };
  }, []);

  if (maintenance) {
    return <MaintenancePage />;
  }

  return appWrapper(
    store,
    flags,
    <Switch>
      <Route path={LoginPage.route} component={LoginPage} />
      <Route path={CreateAccountPage.route} component={CreateAccountPage} />
      <Route path={ForgotPasswordPage.route} component={ForgotPasswordPage} />
      <Route path={ChangePasswordPage.route} component={ChangePasswordPage} />
      <Route path={TermsOfServicePage.route} component={TermsOfServicePage} />
      <Route path={QboDisconnectedPage.route} component={QboDisconnectedPage} />
      <Route path={AppHome.route} component={AppHome} />
    </Switch>
  );
}

export default App;

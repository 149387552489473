import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RumRoute as Route } from '@datadog/rum-react-integration';
import { useFlags } from 'helpers/useFlags';

import { activeUserHasPermission } from 'services/user/redux';
import { Routes as AppRoutes } from 'app/navigation';

import { SecureRouteProps } from './types';

const SecureRoute: React.FC<SecureRouteProps> = (props) => {
  const {
    permissions = [],
    redirectToHome = false,
    featureFlag,
    ...otherProps
  } = props;

  const canAccess = useSelector(activeUserHasPermission(permissions));
  const flags = useFlags();

  const enabledFeatureFlag = featureFlag ? flags[featureFlag] : true;

  if (!enabledFeatureFlag) {
    return <Redirect to={AppRoutes.AppHome} />;
  }

  if (!canAccess) {
    return <Redirect to={AppRoutes.AppHome} />;
  }

  if (redirectToHome) {
    return <Redirect to={AppRoutes.AppHome} />;
  }

  return <Route {...otherProps} />;
};

export default SecureRoute;

import React from 'react';
import { Switch } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { RemoteSupportPage } from '../pages/RemoteSupportPage';
import { Routes } from './routes';
import { RemoteSupportRouterCmp, RemoteSupportRouterProps } from './types';

const RemoteSupportRouter: RemoteSupportRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: RemoteSupportRouterProps
) => {
  return (
    <Switch>
      <SecureRoute
        path={RemoteSupportPage.route}
        exact
        component={RemoteSupportPage}
      />
    </Switch>
  );
};

RemoteSupportRouter.route = Routes.RemoteSupportRouter;

export default RemoteSupportRouter;

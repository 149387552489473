import React from 'react';
import _ from 'lodash';

import {
  ShippingConnection,
  ShippingConnectionType,
  ShippingIntegrationSettings,
} from 'services/integrations/shipping';
import {
  PurchaseLabel,
  PurchaseLabelResponse,
} from 'services/integrations/shipping/purchaseLabel';
import { Ship } from 'services/shipping';
import {
  fetchDefaultReportPreset,
  mergeReports,
  ReportId,
  reportList,
} from 'services/reports';

export const setPurchaseLabelFromSettings = (
  settings: ShippingIntegrationSettings,
  ship: Ship,
  setPurchaseLabel: React.Dispatch<React.SetStateAction<PurchaseLabel>>,
  connection: ShippingConnection
) => {
  const {
    customField1,
    customField2,
    labelType,
    alternateEmail,
    alternatePhone,
    extraSettings,
    internationalSettings,
  } = settings;

  let billingCarrierCode: string | null = null;
  let billingAccount: string | null = null;
  let billingCountry: string | null = null;
  let billingZip: string | null = null;

  let hasDefaultCarrierAccount = false;
  const customer = ship.salesOrder!.customer;

  if (customer) {
    const carrierAccounts = customer.carrierAccountSettings;

    if (carrierAccounts) {
      const defaultCarrier = carrierAccounts.find(
        (c) => c.defaultFlag === true
      );
      if (defaultCarrier) {
        hasDefaultCarrierAccount = true;
        billingCarrierCode = defaultCarrier.carrier;
        billingAccount = defaultCarrier.accountNumber;
        billingCountry = defaultCarrier.billingCountry;
        billingZip = defaultCarrier.billingZip;
      }
    }
  }

  const exporterReference = internationalSettings.exporterReference;
  const importerReference = internationalSettings.importerReference;

  setPurchaseLabel((old) => ({
    ...old,
    purchaseLabelExtra: {
      ...old.purchaseLabelExtra,
      billingType: getDefaultBillingType(connection, hasDefaultCarrierAccount),
      billingCarrierCode: billingCarrierCode,
      billingAccount: billingAccount,
      billingCountry: billingCountry,
      billingZip: billingZip,
      useRetailerRates: extraSettings.useRetailRates,
      saturdayDelivery: extraSettings.saturdayDelivery,
      useInsurance: extraSettings.useInsurance,
      containsDryIce: extraSettings.containsDryIce,
      dryIceWeight: extraSettings.dryIceWeight,
      uom: extraSettings.unitOfMeasure,
      containsAlcohol: extraSettings.containsAlcohol,
      recipientType: extraSettings.recipientType,
      referenceField1: customField1 ? _.get(ship, customField1, null) : null,
      referenceField2: customField2 ? _.get(ship, customField2, null) : null,
      labelFileType: labelType,
      recipientEmail: _.get(ship, 'salesOrder.emails', null)
        ? _.get(ship, 'salesOrder.emails', null)
        : alternateEmail,
      recipientPhone: _.get(ship, 'salesOrder.phone', null)
        ? _.get(ship, 'salesOrder.phone', null)
        : alternatePhone,
    },
    internationalData: {
      ...old.internationalData,
      nonDeliveryOption: internationalSettings.nonDeliveryOption,
      contentsType: internationalSettings.contentsType,
      eelPfc: internationalSettings.eelPfc,
      incoterms: internationalSettings.incoterms,
      shipperEmail: internationalSettings.shipperEmail,
      disclaimer: internationalSettings.disclaimer,
      exporterReference: exporterReference
        ? _.get(ship, exporterReference, null)
        : null,
      importerReference: importerReference
        ? _.get(ship, importerReference, null)
        : null,
      taxIdNumber: internationalSettings.taxIdNumber,
      taxIdType: internationalSettings.taxIdType,
    },
  }));
};

const getDefaultBillingType = (
  connection: ShippingConnection,
  hasDefaultCarrierAccount: boolean
) => {
  switch (connection.typeId) {
    case ShippingConnectionType.Shippo:
      return hasDefaultCarrierAccount ? 'RECIPIENT' : 'SENDER';
    case ShippingConnectionType.ShipStation:
      return hasDefaultCarrierAccount ? 'recipient' : 'my_account';
    default:
      return null;
  }
};

export const mergeLabelsAndReport = async (
  labels: PurchaseLabelResponse[],
  shipId: number,
  activeUserId: number
) => {
  const reportParameters = reportList.find(
    (r) => r.reportId === ReportId.PackingList
  )!.parameters;

  const defaultPreset = await fetchDefaultReportPreset(
    activeUserId,
    ReportId.PackingList
  );

  const parameters = defaultPreset
    ? { ...reportParameters, ...defaultPreset.parameters, shipId }
    : { ...reportParameters, shipId };

  const resolvedLabels = labels
    .filter((l) => l.fileType !== 'ZPLII')
    .map((l) => {
      if (l.returnLabel && l.returnLabel.labellUrl) {
        return [l.labellUrl, l.returnLabel.labellUrl];
      } else {
        return [l.labellUrl];
      }
    })
    .flat();

  // escape ZPLII files
  const resBlob = await mergeReports(resolvedLabels, [
    {
      reportName: ReportId.PackingList,
      parameters: JSON.stringify(parameters),
    },
  ]);

  const url = window.URL.createObjectURL(resBlob);

  const newWindow = window.open(url, '_blank');

  if (newWindow) {
    newWindow.print();
  }
};

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { CustomFieldsModal } from 'ui/components/CustomFields/CustomFieldsModal';
import {
  Ship,
  fetchSearch,
  getSearches,
  transformShip,
  voidMultipleShips,
} from 'services/shipping';
import { fetchOrderPriorities } from 'services/settings/orderPriorities';
import { fetchCustomers, getCustomers } from 'services/customers';
import { fetchCarriers, getCarriers } from 'services/carriers';
import { fetchUoms } from 'services/uoms';
import { fetchSettingsShipping } from 'services/settings/shipping/redux';
import { Pagination } from 'services/search';
import { ObjectType } from 'services/customFields';
import { useUrlQueryObject } from 'services/url';
import { fetchLocations, getLocations } from 'services/locations';
import { fetchVendors, getVendors } from 'services/vendors';
import { fetchSaleItem } from 'services/items';
import { SaleItem } from 'services/items/saleItems';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  removeModuleNavigation,
} from 'services/moduleNavigation';
import { Dates, useGetIntlDateFormatString } from 'helpers';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import {
  SHIPPING_COLUMNS,
  ShippingSearchResults,
  ShippingAdvancedSearch,
  ShippingDetailsCard,
} from './components';
import { ShippingPageCmp, ShippingPageProps } from './types';
import {
  initialShippingFormValues,
  advancedSearchReduxActions,
  displayNameMap,
  initialPagination,
  createDisplayValueMap,
} from './consts';
import { Routes } from '../../navigation';
import { ShippingPageAction } from './components/ShippingSearchResults/types';
import { logErrorCtx } from 'app/logging';
import { ModuleNavigation } from 'app/components/AppBar/components';

const ShippingPage: ShippingPageCmp = (props: ShippingPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeShipId,
  } = props;

  const dispatch = useDispatch();

  const { items: locations } = useSelector(getLocations);
  const { items: customers } = useSelector(getCustomers);
  const { items: vendors } = useSelector(getVendors);
  const { items: carriers } = useSelector(getCarriers);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [searchSaleItem, setSearchSaleItem] = useState<SaleItem | null>(null);
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateShipped);
  const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false);
  const [voidModalVisible, setVoidModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [, extendUrlQuery] = useUrlQueryObject();
  const intlFormatDate = useGetIntlDateFormatString();

  // watch advanced search columns and fetch selected sale item
  useEffect(() => {
    const saleItemId = _.get(
      searchResult.advancedSearch.columns,
      'shipItems.salesOrderItem.saleItemId',
      null
    );

    if (saleItemId) {
      (async () => {
        try {
          const resSaleItem = await fetchSaleItem(saleItemId as number);
          setSearchSaleItem(resSaleItem);
        } catch {
          // nothing
        }
      })();
    }
  }, [searchResult.advancedSearch.columns]);

  // spatial navigation
  useEffect(() => {
    dispatch(clearModuleNavigation(ModuleNavigationType.Sales));

    return () => {
      dispatch(removeModuleNavigation());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVoidClicked = () => setVoidModalVisible(true);

  const hideVoidModal = useCallback(() => {
    setVoidModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const handleVoidConfirm = useCallback(async () => {
    setIsLoading(true);

    try {
      await voidMultipleShips(selectedItems);
      await fetchSearchResult();
      hideVoidModal();
    } catch (e) {
      logErrorCtx('Error in voidMultipleShips', {
        error: e as Error,
        stackTrace: (e as Error).stack,
        component: 'ShippingPage',
        title: 'Error in voidMultipleShips',
        description: 'Error in voidMultipleShips',
      });
    }

    setIsLoading(false);
  }, [selectedItems, hideVoidModal, fetchSearchResult]);

  const handlePickClicked = useCallback(
    (shipId: number) => {
      extendUrlQuery({ activeId: shipId });
    },
    [extendUrlQuery]
  );

  const handleDetailsCardClosed = useCallback(() => {
    extendUrlQuery({ activeId: null });
  }, [extendUrlQuery]);

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  const handlePageAction = useCallback(
    (action: ShippingPageAction, date?: Dates) => {
      switch (action) {
        case ShippingPageAction.CustomFields:
          setShowCustomFieldsModal(true);
          break;
        case ShippingPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    []
  );

  return (
    <>
      <ModuleNavigation />
      <PageWithAdvancedSearch
        detailCardColumns={SHIPPING_COLUMNS(activeDate)}
        initialFormValues={initialShippingFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={ShippingAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(
          locations,
          customers,
          vendors,
          carriers,
          searchSaleItem,
          intlFormatDate
        )}
        showAllLabel="Show All Shipments"
        pageName="Shipping"
      >
        <PaperSlidingLayout shown={Boolean(activeShipId)}>
          <ShippingSearchResults
            ships={searchResult.items}
            activeShipId={activeShipId}
            handleShipClick={handlePickClicked}
            isLoadingShips={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            selectedItems={selectedItems}
            onVoidClicked={onVoidClicked}
            activeDate={activeDate}
            setSelectedItems={setSelectedItems}
            onPageAction={handlePageAction}
          />
          <ShippingDetailsCard
            activeShipId={activeShipId}
            onClose={handleDetailsCardClosed}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <CustomFieldsModal
        open={showCustomFieldsModal}
        setVisible={setShowCustomFieldsModal}
        module={ObjectType.Ship}
      />
      <ConfirmationModal
        open={voidModalVisible}
        title="Void multiple ships"
        body="This will void all selected ships, are you sure?"
        onCancelClicked={hideVoidModal}
        onConfirmClicked={handleVoidConfirm}
        confirmLabel="Void"
        isLoading={isLoading}
        confirmButtonRed
      />
    </>
  );
};

ShippingPage.route = Routes.ShippingPage;

const expands = [
  'carrier',
  'shipCartons',
  'shipItems.item.images',
  'shipItems.item.itemTrackingTypes.trackingType',
  'shipItems.uom',
  'salesOrder.customer.customerAddresses',
  'orderPriority',
];

export default withSearchResults<Ship>(ShippingPage, {
  url: '/v1/ships',
  expand: expands.join(','),
  dataAdapter: transformShip,
  columns: SHIPPING_COLUMNS(Dates.DateShipped),
  getSearches,
  fetchSearch,
  initialPagination,
  rehydrationThunks: [
    fetchCustomers,
    fetchVendors,
    fetchCarriers,
    fetchLocations,
    fetchOrderPriorities,
    fetchUoms,
    fetchSettingsShipping,
  ],
});

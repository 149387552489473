import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useShipStationCardStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '600px',
    padding: 0,
  },
  container: {
    paddingBottom: '64px',
  },
  cardRow: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 56,
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    '&:last-of-type:': {
      borderBottom: 'none',
    },
  },
  cardRowClickable: {
    cursor: 'pointer',
    transitionDuration: '0.2s',
    transitionProperty: 'background-color',
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  modalRow: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 56,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  modalRowExpandable: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
  },
  modalRowInner: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

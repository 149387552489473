import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TextField } from 'ui/components/TextField/TextField';

import {
  fetchXeroTaxRates,
  getXeroTaxRates,
  InternationalTaxRate,
} from 'services/integrations/xero';
import {
  fetchQuickbookTaxRates,
  getQuickbooksTaxRates,
} from 'services/integrations/quickbooks';
import { InternationalTaxRatesProps } from './types';

export const InternationalTaxRates = (props: InternationalTaxRatesProps) => {
  const {
    accountingType,
    label,
    optionsFilter,
    selectedOptionCallback,
    index,
    selectedValue,
    dataQa,
    additionalInputProps,
    disabled = false,
    setSelectedValue,
  } = props;

  const [open, setOpen] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [selectedTaxRate, setSelectedTaxRate] =
    useState<InternationalTaxRate | null>(null);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const dispatch = useDispatch();

  const taxRates = useSelector(
    accountingType === 'XERO' ? getXeroTaxRates : getQuickbooksTaxRates
  );
  const loadInternationalTaxRates = async () => {
    if (taxRates.length === 0) {
      setFetchLoading(true);
      accountingType === 'XERO'
        ? // @ts-ignore
          await dispatch(fetchXeroTaxRates())
        : // @ts-ignore
          await dispatch(fetchQuickbookTaxRates());
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    loadInternationalTaxRates();
  }, [accountingType]);

  const selectedInternationalTaxRate = useCallback(() => {
    return selectedTaxRate || selectedValue || null;
  }, [selectedTaxRate, selectedValue]);

  const availableInternationalTaxRates = useCallback(() => {
    return optionsFilter ? taxRates.filter(optionsFilter) : taxRates;
  }, [taxRates, optionsFilter, searchValue]);

  const handleInternationalTaxRateChange = (
    _e: any,
    value: InternationalTaxRate | null
  ) => {
    setSelectedTaxRate(value);
    if (value && selectedOptionCallback) {
      selectedOptionCallback(value, index);
    } else if (!value) {
      setSearchValue(null);
      setSelectedValue(null);
      setSelectedTaxRate(null);
      selectedOptionCallback && selectedOptionCallback(null as any, index);
    }
  };

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    if (reason === 'input') setSearchValue(val || null);
    else if (reason === 'clear') setSelectedValue(null);
    else {
      setSearchValue(null);
    }
  };

  const getOptionsLabel = (xtr: InternationalTaxRate) =>
    `${xtr.name} (${xtr.taxType})`;

  return (
    <AutoComplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={availableInternationalTaxRates()}
      value={selectedInternationalTaxRate()}
      getOptionLabel={getOptionsLabel}
      onChange={handleInternationalTaxRateChange}
      onInputChange={handleAutocompleteInputChange}
      filterOptions={(options, params) =>
        createFilterOptions<InternationalTaxRate>()(options, params)
      }
      isOptionEqualToValue={(option, val) =>
        option.code === val.code &&
        option.taxType === val.taxType &&
        option.name === val.name
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={`Select ${accountingType} Tax Rate`}
          InputProps={{
            ...params.InputProps,
            ...additionalInputProps,
            endAdornment: (
              <>
                {fetchLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputProps={{
            'data-qa': dataQa,
            'data-lpignore': 'true',
            ...params.inputProps,
          }}
        />
      )}
      popupIcon={
        <ArrowDropDownIcon
          fontSize="small"
          data-qa={`${dataQa}-autocomplete-dropdown-icon`}
        />
      }
      clearIcon={
        <CloseIcon
          fontSize="small"
          data-qa={`${dataQa}-autocomplete-clear-icon`}
        />
      }
    />
  );
};

import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import _ from 'lodash';
import clsx from 'clsx';

import { TitleBarProps } from './types';
import { useTitleBarStyle } from './styled';

const TitleBar: React.FC<TitleBarProps> = (props) => {
  const {
    title,
    children,
    status,
    statusCustom,
    statusCustomStyle,
    pr,
    beforeElements,
    afterElements,
    dangerouslySetInnerHTML,
    maxTitleWidth = 400,
    imageUrl,
    noBorderRadius,
  } = props;

  const classes = useTitleBarStyle(props);

  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.titleBar, { title: !noBorderRadius })}
      pl={2}
      pr={pr}
    >
      {(title || status || statusCustom) && (
        <Box pr={4} display="flex" maxWidth={maxTitleWidth}>
          {imageUrl && (
            <img src={imageUrl} alt="Logo" className={classes.image} />
          )}
          {_.isString(title) && dangerouslySetInnerHTML ? (
            <Typography
              className={classes.whiteText}
              dangerouslySetInnerHTML={{ __html: title }}
              variant="body2"
            />
          ) : (
            <Typography className={classes.whiteText} variant="body2">
              {title}
            </Typography>
          )}

          {status && (
            <Chip
              label={status}
              size="small"
              classes={{ root: classes.status, label: classes.statusLabel }}
              color="secondary"
              data-qa="title-status-label"
            />
          )}
          {statusCustom && (
            <Chip
              label={statusCustom}
              size="small"
              classes={{ root: classes.status, label: classes.statusLabel }}
              className={statusCustomStyle}
              color="secondary"
            />
          )}
        </Box>
      )}
      <Box
        display="flex"
        flexGrow={1}
        justifyContent="flex-end"
        alignItems="center"
      >
        {beforeElements || null}
        {children}
        {afterElements || null}
      </Box>
    </Box>
  );
};

export default React.memo(TitleBar);

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useSandboxPageStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '600px',
    padding: 0,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    padding: theme.spacing(2),
  },
  text: {
    width: '40%',
    textAlign: 'right',
  },
  titleContainer: {
    width: '40%',
  },
  exportButton: {
    marginLeft: '16px',
    marginRight: '16px',
  },
}));

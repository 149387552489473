import React from 'react';

import { Routes } from '../../navigation';
import { AuthContainer } from '../../components';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { RouteComponentProps } from 'react-router';

interface ForgotPasswordPageCmp
  extends React.FunctionComponent<ForgotPasswordPageProps> {
  route: string;
}

interface ForgotPasswordPageProps extends RouteComponentProps {}

const ForgotPasswordPage: ForgotPasswordPageCmp = () => {
  return (
    <AuthContainer
      title="Forgot Password"
      showNotACustomer={false}
      sx={{ alignSelf: 'auto !important' }}
    >
      <ForgotPasswordForm />
    </AuthContainer>
  );
};

ForgotPasswordPage.route = Routes.ForgotPasswordPage;

export default ForgotPasswordPage;

import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { Box, Divider, Switch, Typography } from '@mui/material';

import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

import {
  QuickShipStrategy,
  QUICK_SHIP_STRATEGY,
} from 'services/integrations/shipping';
import TextField from 'ui/components/TextField/TextField/TextField';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { DIMENSIONS_UNITS, Item, ItemType } from 'services/items';
import { fetchLocations } from 'services/locations';
import { TextFieldDimensions } from 'ui/components/TextField/TextFieldDimensions';
import { MultiFormatInput } from 'ui/components/TextField/MultiFormatInput';

import { useShippingWizardStyle } from '../../../../styled';
import { ShipStationContext } from '../../../../ShipStationProvider';
import { formatTypes, useGetCurrencySymbol } from 'helpers';

const GeneralSettingsForm: React.FC = () => {
  const classes = useShippingWizardStyle();
  const dispatch = useDispatch();

  const { settings, setSettings, errors } = useContext(ShipStationContext);

  const defaultFileType = useRef<HTMLInputElement>(null);
  const shippingItem = useRef<HTMLInputElement>(null);

  const currencySymbol = useGetCurrencySymbol();

  useEffect(() => {
    if (defaultFileType.current === null || shippingItem.current === null) {
      return;
    }
    if (!!errors.defaultFileType) {
      defaultFileType.current.scrollIntoView();
    }
    if (!!errors.shippingItemId) {
      shippingItem.current.scrollIntoView();
    }
  }, [errors]);

  const selectedQuickShipStrategy = useMemo(
    () =>
      QUICK_SHIP_STRATEGY.find((q) => q.id === settings.quickShipStrategy) ||
      null,
    [settings.quickShipStrategy]
  );

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchLocations());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleCheckboxChange = useHandleCheckboxChange(setSettings);

  const handleItemChange = useCallback(
    (item: Item | null) => {
      setSettings((old) => ({
        ...old,
        shippingItem: item ? item.id : null,
      }));
    },
    [setSettings]
  );

  const handleQuickShipStrategyChange = useCallback(
    (e: any, quickShip: QuickShipStrategy | null) => {
      setSettings((old) => ({
        ...old,
        quickShipStrategy: quickShip ? quickShip.id : null,
      }));
    },
    [setSettings]
  );

  const handleDimensionUnitChange = useCallback(
    (itemIndex: number) => {
      const dimensionUnit = DIMENSIONS_UNITS[itemIndex].name;
      setSettings({
        ...settings,
        cartonDimensions: {
          ...settings.cartonDimensions,
          unit: dimensionUnit,
        },
      });
    },
    [settings, setSettings]
  );

  const handleDimensionsChange = useCallback(
    (unit: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSettings({
        ...settings,
        cartonDimensions: {
          ...settings.cartonDimensions,
          [unit]: event.target.value ? parseFloat(event.target.value) : null,
        },
      });
    },
    [setSettings, settings]
  );

  const handleAmountTypeChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSettings((old) => ({
        ...old,
        markup: {
          ...old.markup,
          type: e.target.innerText === '%' ? 'percent' : 'flat',
        },
      }));
    },
    [setSettings]
  );

  const handleMarkupChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value;
      setSettings((old) => ({
        ...old,
        markup: {
          ...old.markup,
          value: value ? parseFloat(value) : null,
        },
      }));
    },
    [setSettings]
  );

  const handleTextFieldChange = useHandleTextFieldChange(setSettings, settings);

  const formatPhoneNumber = (phoneNumber: string) => {
    // convert the raw number to (xxx) xxx-xxx format
    const x = phoneNumber
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x![2] ? x![1] : `(${x![1]}) ${x![2]}${x![3] ? `-${x![3]}` : ''}`;
  };

  const handleRecipientPhoneChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const formated = e.target.value ? formatPhoneNumber(e.target.value) : null;
    setSettings({ ...settings, alternatePhone: formated });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
    >
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Fulfillment Options
        </Typography>
      </Box>

      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Fulfill shipment after purchasing label
        </Typography>

        <Switch
          checked={settings.fulfillShipments}
          onChange={handleCheckboxChange}
          name="fulfillShipments"
          color="primary"
        />
      </Box>
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Apply shipping cost to FB package details field
        </Typography>

        <Switch
          checked={settings.applyCostToCarton}
          onChange={handleCheckboxChange}
          name="applyCostToCarton"
          color="primary"
        />
      </Box>
      <Box py={1}>
        <Divider />
      </Box>
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Miscellaneous Settings
        </Typography>
      </Box>
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Quick Ship Strategy
        </Typography>
        <Box width="45%">
          <Autocomplete
            options={QUICK_SHIP_STRATEGY}
            value={selectedQuickShipStrategy}
            placeholder="Select quick ship strategy"
            getOptionLabel={(option) => option.name}
            onChange={handleQuickShipStrategyChange}
            error={!!errors.quickShipStrategy}
          />
        </Box>
      </Box>
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Alternative recipient phone
        </Typography>
        <Box width="45%">
          <TextField
            placeholder="Enter alternative recipient phone"
            value={settings.alternatePhone}
            name="alternatePhone"
            onChange={handleRecipientPhoneChange}
            error={!!errors.alternatePhone}
          />
        </Box>
      </Box>
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Alternative recipient email
        </Typography>
        <Box width="45%">
          <TextField
            placeholder="Enter alternative recipient email"
            name="alternateEmail"
            value={settings.alternateEmail}
            onChange={handleTextFieldChange}
            error={!!errors.alternateEmail}
          />
        </Box>
      </Box>
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Default Shipping Item
        </Typography>
        <Box width="45%">
          <ItemsAutocomplete
            value={settings.shippingItem || null}
            placeholder="Select default shipping item"
            itemTypes={[ItemType.Shipping]}
            additionalInputProps={{ inputRef: shippingItem }}
            onChange={handleItemChange}
            error={!!errors.shippingItem}
          />
        </Box>
      </Box>
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Markup
        </Typography>

        <Box width="45%">
          <MultiFormatInput
            className="redesign"
            variant="standard"
            type="text"
            placeholder="Enter amount"
            options={formatTypes(currencySymbol)}
            onFormatChange={handleAmountTypeChanged}
            decimalPlaces={settings.markup.type === 'flat' ? 2 : 3}
            onChange={handleMarkupChange}
            activeIndex={settings.markup.type === 'flat' ? 1 : 0}
            value={settings.markup.value}
          />
        </Box>
      </Box>
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Preset package dimensions for quoting (LxWxH)
        </Typography>

        <Box width="45%">
          <TextFieldDimensions
            heightValue={settings.cartonDimensions.height}
            widthValue={settings.cartonDimensions.width}
            lengthValue={settings.cartonDimensions.length}
            onHeightChange={handleDimensionsChange('height')}
            onWidthChange={handleDimensionsChange('width')}
            onLengthChange={handleDimensionsChange('length')}
            onMenuChange={handleDimensionUnitChange}
            dimension={settings.cartonDimensions.unit}
            label=""
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(GeneralSettingsForm);

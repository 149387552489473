import React, { memo, useCallback } from 'react';
import { Box, Switch, Typography } from '@mui/material';

import {
  ShippoNonDeliveryType,
  ShipStationContentsType,
} from 'services/integrations/shipping/shippo';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { useHandleCheckboxChange } from 'services/forms/hooks';

import { useShippingWizardStyle } from '../../../../styled';
import { useInternationalSettings } from '../../../../ShipStationProvider';

const InternationalSettingsForm: React.FC = () => {
  const classes = useShippingWizardStyle();

  const { settings, setSettings, errors } = useInternationalSettings();

  const handleCheckboxChange = useHandleCheckboxChange(setSettings);

  const handleAutocompleteChange = useCallback(
    (
        name:
          | 'nonDeliveryOption'
          | 'contentsType'
          | 'incoterms'
          | 'eelPfc'
          | 'exporterReference'
          | 'importerReference'
          | 'taxIdType'
      ) =>
      (e: React.ChangeEvent<{}>, v: any) => {
        setSettings((old) => ({ ...old, [name]: v || null }));
      },
    [setSettings]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
      py={1}
    >
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          International Shipping
        </Typography>
        <Box>
          <Switch
            checked={settings.enabled}
            onChange={handleCheckboxChange}
            name="enabled"
            color="primary"
          />
        </Box>
      </Box>
      {settings.enabled && (
        <>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Non-delivery option
            </Typography>
            <Box width="45%">
              <Autocomplete
                options={Object.values(ShippoNonDeliveryType)}
                value={settings.nonDeliveryOption}
                onChange={handleAutocompleteChange('nonDeliveryOption')}
                placeholder="Non-delivery option"
                error={!!errors.nonDeliveryOption}
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Contents type
            </Typography>
            <Box width="45%">
              <Autocomplete
                options={Object.values(ShipStationContentsType)}
                value={settings.contentsType}
                onChange={handleAutocompleteChange('contentsType')}
                placeholder="Contents type"
                error={!!errors.contentsType}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(InternationalSettingsForm);

export interface SandboxPageProps {}

export interface SandboxPageCmp
  extends React.FunctionComponent<SandboxPageProps> {
  route: string;
}

export enum SandboxPageModalType {
  CLEAR = 'clear',
  POPULATE = 'populate',
  COPY = 'copy',
}

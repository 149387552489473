import React, { useCallback, useRef, useState } from 'react';
import { Dates, getErrorMessage } from 'helpers';

import { fetchSearch, getSearches } from 'services/representatives/redux';
import {
  transformRepresentative,
  Representative,
} from 'services/representatives';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { Pagination } from 'services/search';
import { useUrlQueryObject } from 'services/url';

import { Routes } from '../../navigation';
import {
  RepresentativesSearchResults,
  RepresentativesAdvancedSearch,
  RepresentativeDetailCard,
} from './components';
import { RepresentativesPageProps, RepresentativePageCmp } from './types';
import {
  advancedSearchReduxActions,
  initialPagination,
  displayNameMap,
  initialRepresentativesASFormValues,
  REPRESENTATIVE_COLUMNS,
  RepresentativesPageAction,
} from './consts';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import { useDispatch } from 'react-redux';
import { showProgressAlert } from 'services/alert/redux';
import { logErrorCtx } from 'app/logging';

const RepresentativesPage: RepresentativePageCmp = (
  props: RepresentativesPageProps
) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeRepresentativeId,
  } = props;

  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [, extendUrlQuery] = useUrlQueryObject();
  const hiddenInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleActiveRepresentativeClose = useCallback(
    () => extendUrlQuery({ activeId: null }),
    [extendUrlQuery]
  );

  const handleAddNewPress = useCallback(() => {
    extendUrlQuery({ activeId: -1 });
  }, [extendUrlQuery]);

  const { startFetching, startCsvFetching } = useBackgroundTasks();

  const handleRepresentativeClicked = (id: number | null) =>
    extendUrlQuery({ activeId: id });

  const handlePaginationChange = (newPagination: Pagination) => {
    fetchSearchResult({ pagination: newPagination });
  };

  const exportRepresentatives = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.Representatives);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);

  const importRepresentatives = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleRepresentativesPageAction = useCallback(
    (action: RepresentativesPageAction, date?: Dates) => {
      switch (action) {
        case RepresentativesPageAction.Export:
          exportRepresentatives();
          break;
        case RepresentativesPageAction.Import:
          importRepresentatives();
          break;
        case RepresentativesPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    [importRepresentatives, exportRepresentatives]
  );

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification(
        'Your Representatives import has been initiated.'
      );

      startCsvFetching();

      event.target.value = '';
      try {
        await startBackgroundImport(
          BackgroundType.Representatives,
          uploadedFile
        );
        dispatch(
          showProgressAlert(
            'Your Import to Representatives has been initiated. This may take a few minutes to complete.',
            BackgroundType.Uom,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);
        const error = e as Error;
        logErrorCtx('Error in startBackgroundImport', {
          stackTrace: error.stack,
          error,
          description: message,
          component: 'RepresentativesPage',
        });
        return;
      }
    },
    [dispatch]
  );

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={REPRESENTATIVE_COLUMNS(activeDate)}
        initialFormValues={initialRepresentativesASFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={RepresentativesAdvancedSearch}
        displayNameMap={displayNameMap}
        pageName="Representatives"
      >
        <PaperSlidingLayout shown={Boolean(activeRepresentativeId)}>
          <RepresentativesSearchResults
            representatives={searchResult.items}
            activeRepresentativeId={activeRepresentativeId}
            activeDate={activeDate}
            onPageAction={handleRepresentativesPageAction}
            handleRepresentativeClick={handleRepresentativeClicked}
            onAddNewPress={handleAddNewPress}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            isLoadingRepresentatives={isLoadingSearchResult}
          />
          <RepresentativeDetailCard
            representatives={searchResult.items}
            activeRepresentativeId={activeRepresentativeId}
            onClose={handleActiveRepresentativeClose}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
    </>
  );
};

RepresentativesPage.route = Routes.RepresentativesPage;

export default withSearchResults<Representative>(RepresentativesPage, {
  url: '/v1/representatives',
  dataAdapter: transformRepresentative,
  columns: REPRESENTATIVE_COLUMNS(Dates.DateCreated),
  fetchSearch,
  getSearches,
  initialPagination,
});

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { isValid, showNotification } from 'services/api';
import { getAuth } from 'services/auth';
import { useUrlQueryObject } from 'services/url';
import { Routes as AppRoutes } from 'app';
import { Routes as PublicRoutes } from 'ui/modules/public/navigation';

import { AuthWrapperProps, AuthWrapperCmp } from './types';
import { calculateForwardUrl } from './helpers';
import { getActiveUser } from 'services/user';
import { isProd } from 'helpers';
import { isTestEnv } from 'services/api/testEnv';
import { datadogRum } from '@datadog/browser-rum';
import { useFlags } from 'helpers/useFlags';

/**
 * Component that checks if user is logged in and renders loader while it does that
 * LOGGED IN -> forwards him to the url he wanted to visit or to Home '/'
 * NOT LOGGED IN -> transfers him to login with query parameter forwardUrl of URL he wanted to visit
 */
export const AuthWrapper: AuthWrapperCmp = (props: AuthWrapperProps) => {
  const {
    children,
    location: { pathname, search },
    history: { replace },
  } = props;
  const [searchObject] = useUrlQueryObject();
  const auth = useSelector(getAuth);
  const flags = useFlags();

  //Appcue Identify Call
  const activeUser = useSelector(getActiveUser);

  const isProdEnv = isProd();
  const isSandboxEnv = isTestEnv();

  useEffect(() => {
    if (
      window.Appcues != undefined &&
      window.Appcues != null &&
      activeUser.userGlobalId
    ) {
      window.Appcues.identify(activeUser.userGlobalId, {
        tenantID: activeUser.tenant ?? null,
        email: activeUser.user?.email ?? null,
        isProdEnv: isProdEnv || null,
        isSandboxEnv: isSandboxEnv || null,
      });
    }
  }, [window.Appcues, activeUser.userGlobalId, isSandboxEnv]);

  useEffect(() => {
    if (flags.datadogUserSession && activeUser.userGlobalId) {
      datadogRum.setUser({
        id: activeUser.userGlobalId.toString(),
        name: activeUser.user?.firstName + ' ' + activeUser.user?.lastName,
        email: activeUser.user?.email || '',
        tenant: activeUser.tenant,
      });
    } else {
      datadogRum.clearUser();
    }
  }, [activeUser.userGlobalId, flags.datadogUserSession]);

  const [userValidated, setUserValidated] = React.useState(false);

  const isLoginRoute =
    pathname === PublicRoutes.LoginPage ||
    pathname === PublicRoutes.CreateAccountPage ||
    pathname === PublicRoutes.ForgotPasswordPage ||
    pathname === PublicRoutes.ChangePasswordPage ||
    pathname === PublicRoutes.TermsOfService ||
    pathname === PublicRoutes.QboDisconnect;

  React.useEffect(() => {
    if (searchObject.successMsg) {
      showNotification(searchObject.successMsg, {
        variant: 'success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const updateUserValidation = async () => {
      if (isValid(auth)) {
        // if user wanted to go to login redirect him to home or forward url if there is some
        if (pathname.indexOf(PublicRoutes.LoginPage) === 0) {
          const url = pathname + search;
          const forwardUrl = url.split('forwardUrl=').slice(1).join('');
          const forwardUrlResolved = forwardUrl || AppRoutes.AppHome;
          replace(forwardUrlResolved as string);
        }
      } else if (
        !isLoginRoute &&
        pathname.indexOf(PublicRoutes.LoginPage) === -1
      ) {
        // if user didn't want to go to login redirect him there and put current
        // pathname as forward Url
        replace({
          pathname: PublicRoutes.LoginPage,
          search: calculateForwardUrl(pathname, search),
        });

        // Show error if error happend when force log out
        if (auth._status.errorMessage) {
          showNotification(auth._status.errorMessage, { variant: 'error' });
        }

        // Stop ChurnZero on logout
        if (window.ChurnZero !== undefined) {
          window.ChurnZero.push(['stop']);
        }
      }
      setUserValidated(true);
    };

    updateUserValidation();
  }, [pathname, search, replace, auth, isLoginRoute]);

  if (!isLoginRoute && !userValidated) {
    return <LinearProgress />;
  }

  return children;
};

export default withRouter<AuthWrapperProps, AuthWrapperCmp>(AuthWrapper);

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessIcon from '@mui/icons-material/Business';
import PaymentIcon from '@mui/icons-material/Payment';
import RepeatIcon from '@mui/icons-material/Repeat';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

import { NavigationDescriptor } from './types';
import { companyRowDescriptor, companyCardId } from '../CompanyCard';
import { pickingRowDescriptor, pickingCardId } from '../PickingOrderCard';
import { salesOrderRowDescriptor, salesOrderCardId } from '../SalesOrderCard';
import { shippingRowDescriptor, shippingCardId } from '../ShippingCard';
import { trackingCardId, trackingRowDescriptor } from '../TrackingCard';
import {
  purchaseOrderCardId,
  purchaseOrderRowDescriptor,
} from '../PurchaseOrderCard';
import { accountingCardId, accountingRowDescriptor } from '../AccountingCard';
import { PermissionType } from 'services/permissions';
import { IconNames } from 'ui/theme';

export const cardNavigationDescriptor: NavigationDescriptor[] = [
  {
    id: salesOrderCardId,
    rowDescriptor: salesOrderRowDescriptor(),
    icon: MonetizationOnIcon,
    iconName: IconNames.MenuSales,
    title: 'Sales Order',
    separate: false,
    permissions: [PermissionType.SettingsSalesOrderView],
  },
  {
    id: purchaseOrderCardId,
    rowDescriptor: purchaseOrderRowDescriptor(),
    icon: PaymentIcon,
    iconName: IconNames.MenuPurchasing,
    title: 'Purchase Order',
    separate: false,
    permissions: [PermissionType.SettingsPurchaseOrderView],
  },
  // {
  //   id: emailCardId,
  //   rowDescriptor: emailRowDescriptor,
  //   icon: EmailIcon,
  //   title: 'Emailing',
  //   separate: false,
  //   permissions: [PermissionType.SettingsEmailingView],
  // },

  {
    id: pickingCardId,
    rowDescriptor: pickingRowDescriptor,
    icon: AssignmentIcon,
    iconName: IconNames.FBOPicking,
    title: 'Picking',
    separate: false,
    permissions: [PermissionType.SettingsPickingView],
  },
  {
    id: trackingCardId,
    rowDescriptor: trackingRowDescriptor,
    icon: TrackChangesIcon,
    iconName: IconNames.TrackingAim,
    title: 'Tracking',
    separate: false,
  },
  {
    id: shippingCardId,
    rowDescriptor: shippingRowDescriptor,
    icon: LocalShippingIcon,
    iconName: IconNames.FBOShipping,
    title: 'Shipping',
    separate: false,
    permissions: [PermissionType.SettingsShippingView],
  },
  {
    id: accountingCardId,
    rowDescriptor: accountingRowDescriptor([]),
    icon: RepeatIcon,
    iconName: IconNames.FBOAccountMapping,
    title: 'Account Mapping',
    separate: false,
    permissions: [PermissionType.SettingsAccountMappingView],
  },
  {
    id: companyCardId,
    rowDescriptor: companyRowDescriptor(),
    icon: BusinessIcon,
    iconName: IconNames.FBOCompany,
    title: 'Company',
    separate: true,
    permissions: [PermissionType.SettingsCompanyView],
  },
];

import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box, Typography } from '@mui/material';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { Location } from 'services/locations';
import { useHandleTextFieldChange } from 'services/forms/hooks';
import {
  getItemInventory,
  initialItemInventory,
  ItemInventory,
} from 'services/inventory';
import { COMPANY_WIDE_ID } from 'services/locations/consts';
import { EACH_UOM_ID, getUoms } from 'services/uoms';
import { initialPickItem } from 'services/picking/consts';
import { PermissionType } from 'services/permissions';
import {
  getPickItemName,
  getPick,
  PickItem,
  pickItemIsInventoryType,
  updatePickItem,
  PickItemStatus,
} from 'services/picking';
import { Errors, validateYup } from 'services/forms/validation';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';

import { PickItemDetailsModalProps } from './types';
import { pickItemSchema } from '../../../../validations';

// FF CU-863hakwb6
import { useFlags } from 'helpers/useFlags';
import LocationItemAsyncAutocomplete from 'ui/components/Autocomplete/LocationItemAsyncAutocomplete/LocationItemAsyncAutocomplete';
// FF CU-863hakwb6

const PickItemDetailsModal: React.FC<PickItemDetailsModalProps> = (props) => {
  const { pickItem, onClose, onSave, show } = props;

  const { items: uoms } = useSelector(getUoms);

  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [formPickItem, setFormPickItem] = useState<PickItem>(initialPickItem);
  const [isLoading, setIsLoading] = useState(false);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);

  const isInventoryType = pickItemIsInventoryType(pickItem);

  // FF CU-863hakwb6
  const flags = useFlags();
  const expandsOptimizationFlag = flags.expandsOptimization;
  // FF CU-863hakwb6

  const destinationQty = useMemo(() => {
    if (formPickItem.pickToLocationId === COMPANY_WIDE_ID) {
      return itemInventory.availableToPickQty;
    }

    const destInventoryRow = itemInventory.inventoryRowList.find(
      (i) => i.locationId === formPickItem.pickToLocationId
    );

    return destInventoryRow ? destInventoryRow.availableToPickQty : 0;
  }, [itemInventory, formPickItem.pickToLocationId]);

  const locationQuantity: {
    [locationId: number]: number;
  } = useMemo(
    () =>
      itemInventory.inventoryRowList.reduce(
        (acc, i) => ({ ...acc, [i.locationId]: i.availableToPickQty }),
        {}
      ),
    [itemInventory]
  );

  const fromQty = useMemo(() => {
    if (formPickItem.pickFromLocationId === COMPANY_WIDE_ID) {
      return itemInventory.availableToPickQty;
    }

    const fromInventoryRow = itemInventory.inventoryRowList.find(
      (i) => i.locationId === formPickItem.pickFromLocationId
    );

    return fromInventoryRow ? fromInventoryRow.availableToPickQty : 0;
  }, [itemInventory, formPickItem.pickFromLocationId]);

  useEffect(() => {
    if (!show) {
      return;
    }

    setFormPickItem(pickItem);

    if (!isInventoryType) {
      return;
    }

    (async () => {
      setIsContentLoading(true);
      const inventory = await getItemInventory(pickItem.itemId || 0);
      setItemInventory(inventory);
      setIsContentLoading(false);
    })();

    // eslint-disable-next-line
  }, [show]);

  const handleLocationChange = useCallback(
    (locationType: string) => (value: Location | null) => {
      setFormPickItem({
        ...formPickItem,
        [locationType]: value ? value.id : null,
      });
    },
    [formPickItem]
  );

  const handleApplyClicked = useCallback(async () => {
    const isValid = validateYup(
      formPickItem,
      pickItemSchema(isInventoryType),
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    try {
      let updatedPick = await updatePickItem(
        formPickItem.pickId!,
        formPickItem,
        expandsOptimizationFlag
      );

      if (expandsOptimizationFlag) {
        updatedPick = await getPick(formPickItem.pickId!);
      }

      onSave(updatedPick);
    } catch {
      // nothing
    }

    setIsLoading(false);
  }, [formPickItem, isInventoryType, onSave]);

  const handleResetClicked = useCallback(() => {
    setFormPickItem(pickItem);
  }, [pickItem]);

  const handleTextFieldChanged = useHandleTextFieldChange(
    setFormPickItem,
    formPickItem
  );
  const handleNumberFieldChanged = useHandleTextFieldChange(
    setFormPickItem,
    formPickItem,
    false
  );

  return (
    <Modal
      open={show}
      onCancelClicked={onClose}
      onApplyClicked={handleApplyClicked}
      onResetClicked={handleResetClicked}
      status={pickItem.status || undefined}
      title={getPickItemName(pickItem) || ''}
      applyLabel="Save"
      withoutDefaultPadding
      permissions={[PermissionType.PickingEdit]}
      isLoading={isLoading}
      isLoadingContent={isContentLoading}
      dataQa="picking"
    >
      <Box p={4} pb={2} overflow="hidden">
        <Grid container spacing={2}>
          {isInventoryType ? (
            <>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  {flags.pickItemLocationRemoveZero ? (
                    <LocationItemAsyncAutocomplete
                      placeholder="Pick Origin"
                      onChange={handleLocationChange('pickFromLocationId')}
                      label="Pick Origin"
                      value={pickItem ? pickItem.pickFromLocationId : null}
                      dataQa="pick-finish-wizard-pick-origin"
                      companyWide={false}
                      disabled={
                        pickItem?.status === PickItemStatus.Committed ||
                        pickItem?.status === PickItemStatus.Finished
                      }
                      itemId={pickItem?.itemId ?? undefined}
                      renderOption={(props, option: Location) => {
                        return (
                          <li {...props}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Typography variant="body1">
                                {option.name}
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                {_.round(option.availableToPickQty!, 2)}
                              </Typography>
                            </Box>
                          </li>
                        );
                      }}
                    />
                  ) : (
                    <LocationsAsyncAutocomplete
                      placeholder="Pick Origin"
                      onChange={handleLocationChange('pickFromLocationId')}
                      label="Pick Origin"
                      value={formPickItem.pickFromLocationId}
                      permissions={[PermissionType.PickingEdit]}
                      companyWide={false}
                      disabled={
                        pickItem?.status === PickItemStatus.Committed ||
                        pickItem?.status === PickItemStatus.Finished
                      }
                      renderOption={(props, option: Location) => {
                        return (
                          <li {...props}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Typography variant="body1">
                                {option.displayName}
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                {`${locationQuantity[option.id!] || 0} ${_.get(
                                  formPickItem,
                                  'item.defaultUom.abbreviation',
                                  ''
                                )}`}
                              </Typography>
                            </Box>
                          </li>
                        );
                      }}
                      dataQa="picking-item-location-picker"
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className="redesign"
                    variant="standard"
                    label="Slot"
                    type="number"
                    name="slotNumber"
                    value={formPickItem.slotNumber}
                    permissions={[PermissionType.PickingEdit]}
                    onChange={handleNumberFieldChanged}
                    disableDebounce={true}
                    error={!!validationErrors.slotNumber}
                    autoComplete="nope"
                    dataQa="picking-item-slot"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className="redesign"
                    variant="standard"
                    readOnly
                    label="Quantity"
                    value={pickItem.quantity}
                    autoComplete="nope"
                    dataQa="picking-item-quantity"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextFieldQuantity
                    label="Quantity At Loc."
                    placeholder="Quantity"
                    name="amount"
                    value={fromQty}
                    selectedUomId={pickItem.uomId || EACH_UOM_ID}
                    error={false}
                    uoms={uoms}
                    readOnly
                    dataQa="picking-item-quantity-at-location"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={10}>
                  {flags.pickItemLocationRemoveZero ? (
                    <LocationItemAsyncAutocomplete
                      placeholder="Pick Destination"
                      onChange={handleLocationChange('pickToLocationId')}
                      label="Pick Destination"
                      value={pickItem ? pickItem.pickToLocationId : null}
                      dataQa="picking-item-destination"
                      companyWide={false}
                      disabled={pickItem?.status === PickItemStatus.Finished}
                      itemId={pickItem?.itemId ?? undefined}
                      renderOption={(props, option: Location) => {
                        return (
                          <li {...props}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Typography variant="body1">
                                {option.name}
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                {_.round(option.availableToPickQty!, 2)}
                              </Typography>
                            </Box>
                          </li>
                        );
                      }}
                    />
                  ) : (
                    <LocationsAsyncAutocomplete
                      placeholder="Pick Destination"
                      label="Pick Destination"
                      required
                      onChange={handleLocationChange('pickToLocationId')}
                      value={formPickItem.pickToLocationId}
                      companyWide={false}
                      disabled={pickItem?.status === PickItemStatus.Finished}
                      permissions={[PermissionType.PickingEdit]}
                      renderOption={(props, option: Location) => {
                        return (
                          <li {...props}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Typography variant="body1">
                                {option.displayName}
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                {`${locationQuantity[option.id!] || 0} ${_.get(
                                  formPickItem,
                                  'item.defaultUom.abbreviation',
                                  ''
                                )}`}
                              </Typography>
                            </Box>
                          </li>
                        );
                      }}
                      error={!!validationErrors.pickToLocationId}
                      dataQa="picking-item-destination"
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <TextFieldQuantity
                    label="Quantity At Des."
                    placeholder="Quantity"
                    name="amount"
                    value={destinationQty}
                    selectedUomId={pickItem.uomId || EACH_UOM_ID}
                    error={false}
                    uoms={uoms}
                    readOnly
                    dataQa="picking-item-quantity-at-destination"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className="redesign"
                    variant="standard"
                    label="Notes"
                    name="notes"
                    placeholder="Notes"
                    value={formPickItem.notes}
                    permissions={[PermissionType.PickingEdit]}
                    onChange={handleTextFieldChanged}
                    dataQa="picking-item-notes"
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item container spacing={2}>
              <Grid item xs={2}>
                <TextField
                  disabled
                  label="Quantity"
                  value={pickItem.quantity}
                  autoComplete="nope"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Slot"
                  type="number"
                  name="slotNumber"
                  permissions={[PermissionType.PickingEdit]}
                  value={formPickItem.slotNumber}
                  onChange={handleNumberFieldChanged}
                  disableDebounce={true}
                  error={!!validationErrors.slotNumber}
                  autoComplete="nope"
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  className="redesign"
                  variant="standard"
                  label="Notes"
                  name="notes"
                  permissions={[PermissionType.PickingEdit]}
                  placeholder="Notes"
                  value={formPickItem.notes}
                  onChange={handleTextFieldChanged}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(PickItemDetailsModal);

import React, { memo, useMemo } from 'react';

import { Modal } from 'ui/components/Modal/Modal';
import { TitleBar } from 'ui/components/TitleBar';
import { Icon } from 'ui/components/Icon';
import { IconButton as MuiIconButton } from '@mui/material';

import { GeneralSettingsForm } from '../../../GeneralSettingsForm';
import { InternationalSettingsForm } from '../../../InternationalSettingsForm';
import { ShippingExtrasSettingsForm } from '../../../ShippingExtrasSettingsForm';
import { ShippoCardModalProps } from '../types';
import { IconNames, ShippoLogoSmall } from 'ui/theme';
import { ShippoSettingsModalType } from '../../types';

const ShippoCardModal: React.FC<ShippoCardModalProps> = (props) => {
  const {
    open,
    onClose,
    onSaveClicked,
    isLoadingContent,
    isLoadingApply,
    modalType,
  } = props;

  const titleBarComponent = () => {
    return (
      <TitleBar title={modalType} imageUrl={ShippoLogoSmall}>
        <MuiIconButton onClick={onClose}>
          <Icon onDark name={IconNames.Close} />
        </MuiIconButton>
      </TitleBar>
    );
  };

  const form = useMemo(() => {
    switch (modalType) {
      case ShippoSettingsModalType.GeneralSettings:
        return <GeneralSettingsForm />;
      case ShippoSettingsModalType.InternationalSettings:
        return <InternationalSettingsForm />;
      case ShippoSettingsModalType.ShippingExtrasSettings:
        return <ShippingExtrasSettingsForm />;
      default:
        break;
    }
  }, [modalType]);

  return (
    <Modal
      open={open}
      onCancelClicked={onClose}
      onApplyClicked={onSaveClicked}
      withoutDefaultPadding
      nestedScrollAreas
      TitleBarComponent={titleBarComponent}
      maxWidth="sm"
      footerDivider="shadow"
      isLoadingContent={isLoadingContent}
      isLoading={isLoadingApply}
    >
      {form}
    </Modal>
  );
};

export default memo(ShippoCardModal);

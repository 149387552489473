import React, { useCallback } from 'react';

import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { Pagination } from 'services/search';
import {
  Class,
  transformClass,
  fetchSearch,
  getSearches,
} from 'services/classes';
import { useUrlQueryObject } from 'services/url';

import { ClassesAdvancedSearch } from './components';
import {
  advancedSearchReduxActions,
  initialPagination,
  initialASFormValues,
  displayNameMap,
  createDisplayValueMap,
} from './consts';
import {
  ClassesSearchResult,
  CLASSES_DETAILS_COLUMNS,
} from './components/ClassesSearchResults';
import { ClassDetailsCard } from './components/ClassDetailsCard';
import { Routes } from '../../navigation';
import { ClassesPageCmp, ClassesPageProps } from './types';
import { useGetIntlDateFormatString } from 'helpers';

const ClassesPage: ClassesPageCmp = (props: ClassesPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeClassId,
  } = props;

  const [, extendUrlQuery] = useUrlQueryObject();
  const intlFormatDate = useGetIntlDateFormatString();

  const handleActiveClassClose = useCallback(
    () => extendUrlQuery({ activeId: null }),
    [extendUrlQuery]
  );

  const handleAddNewPress = useCallback(() => {
    extendUrlQuery({ activeId: -1 });
  }, [extendUrlQuery]);

  const classClicked = useCallback(
    async (id: number) => {
      extendUrlQuery({ activeId: id });
    },
    [extendUrlQuery]
  );

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={CLASSES_DETAILS_COLUMNS.filter(
          (c) => c.field === 'name'
        )}
        initialFormValues={initialASFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={ClassesAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(intlFormatDate)}
        pageName="Classes"
      >
        <PaperSlidingLayout shown={Boolean(activeClassId)}>
          <ClassesSearchResult
            classes={searchResult.items}
            activeClassId={activeClassId}
            handleClassClicked={classClicked}
            onAddNewPress={handleAddNewPress}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            isLoadingClasses={isLoadingSearchResult}
          />
          <ClassDetailsCard
            activeClassId={activeClassId}
            onClose={handleActiveClassClose}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
    </>
  );
};

ClassesPage.route = Routes.ClassesPage;

export default withSearchResults<Class>(ClassesPage, {
  url: '/v1/accounting_class',
  dataAdapter: transformClass,
  columns: [],
  fetchSearch,
  getSearches,
  initialPagination,
  rehydrationThunks: [],
});

import React, { useCallback, useState } from 'react';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';

import { Page } from 'ui/components/Page/Page';
import { TitleBar } from 'ui/components/TitleBar';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import {
  clearEnvironment,
  copyProductionEnvironment,
  populateTestEnvironment,
} from 'services/sandbox';

import { Routes } from '../../navigation';
import {
  SandboxPageCmp,
  SandboxPageModalType,
  SandboxPageProps,
} from './types';
import { useSandboxPageStyle } from './styled';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SandboxPage: SandboxPageCmp = (props: SandboxPageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clearModalVisible, setClearModalVisible] = useState(false);
  const [populateModalVisible, setPopulateModalVisible] = useState(false);
  const [copyModalVisible, setCopyModalVisible] = useState(false);

  const classes = useSandboxPageStyle();

  const openModal = useCallback((modalType: SandboxPageModalType) => {
    switch (modalType) {
      case SandboxPageModalType.CLEAR:
        return setClearModalVisible(true);
      case SandboxPageModalType.POPULATE:
        return setPopulateModalVisible(true);
      case SandboxPageModalType.COPY:
        return setCopyModalVisible(true);
      default:
        return;
    }
  }, []);

  const closeModal = (modalType: SandboxPageModalType) => {
    switch (modalType) {
      case SandboxPageModalType.CLEAR:
        return setClearModalVisible(false);
      case SandboxPageModalType.POPULATE:
        return setPopulateModalVisible(false);
      case SandboxPageModalType.COPY:
        return setCopyModalVisible(false);
      default:
        return;
    }
  };

  const clearDataClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await clearEnvironment();
    } catch {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setClearModalVisible(false);
  }, []);

  const populateDataClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await populateTestEnvironment();
    } catch {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setPopulateModalVisible(false);
  }, []);

  const copyProductionDataClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await copyProductionEnvironment();
    } catch {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setCopyModalVisible(false);
  }, []);

  return (
    <>
      <Page>
        <Box className={classes.container}>
          <Paper id="waitingToExportId" className={classes.paper}>
            <NetworkSpinnerWrapper isLoading={!!isLoading} size={24}>
              <TitleBar dangerouslySetInnerHTML={true} title="Sandbox Data" />
              <Box className={classes.rowContainer}>
                <Box className={classes.titleContainer}>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: 'Clear sandbox data' }}
                  />
                </Box>
                <Button
                  onClick={() => openModal(SandboxPageModalType.CLEAR)}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  <b>Clear</b>
                </Button>
              </Box>
              <Divider />
              <Box className={classes.rowContainer}>
                <Box className={classes.titleContainer}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: 'Populate with sample data',
                    }}
                  />
                </Box>
                <Button
                  onClick={() => openModal(SandboxPageModalType.POPULATE)}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  <b>Populate</b>
                </Button>
              </Box>
              <Box className={classes.rowContainer}>
                <Box className={classes.titleContainer}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: 'Populate with my account data',
                    }}
                  />
                </Box>
                <Button
                  onClick={() => openModal(SandboxPageModalType.COPY)}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  <b>Populate</b>
                </Button>
              </Box>
            </NetworkSpinnerWrapper>
          </Paper>
        </Box>
      </Page>

      <ConfirmationModal
        open={clearModalVisible}
        title="Clear sandbox data"
        onConfirmClicked={clearDataClicked}
        onCancelClicked={() => closeModal(SandboxPageModalType.CLEAR)}
        body="You are about to clear your sandbox data. Do you want to continue?"
        confirmLabel="Yes"
        isLoading={isLoading}
      />

      <ConfirmationModal
        open={populateModalVisible}
        title="Populate Sandbox With Sample Data"
        onConfirmClicked={populateDataClicked}
        onCancelClicked={() => closeModal(SandboxPageModalType.POPULATE)}
        body="You are about to copy your sample data to your sandbox environment. You will lose any saved data in your sandbox. Do you want to continue?"
        confirmLabel="Yes"
        isLoading={isLoading}
      />

      <ConfirmationModal
        open={copyModalVisible}
        title="Populate Sandbox With My Account Data"
        onConfirmClicked={copyProductionDataClicked}
        onCancelClicked={() => closeModal(SandboxPageModalType.COPY)}
        body="You are about to copy your account data to your sandbox environment. You will lose any saved data in your sandbox. Do you want to continue?"
        confirmLabel="Yes"
        isLoading={isLoading}
      />
    </>
  );
};

SandboxPage.route = Routes.SandboxPage;

export default SandboxPage;

import React, { memo, useCallback, useContext, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { ShippingIntegrationSettings } from 'services/integrations/shipping';
import { validateYup } from 'services/forms/validation';
import {
  extraSettingsValidation,
  generalSettingsValidation,
  internationalSettingsValidation,
} from 'services/integrations/shipping/shippo/validations';
import { TitleBar } from 'ui/components/TitleBar';

import { ShippoSettingsModalType, ShippoCardProps } from './types';
import { ShippoContext } from '../../../../ShippoProvider';
import { useShippoCardStyle } from './styled';
import { ShippoCardModal } from './components';
import { ShippoLogoSmall } from 'ui/theme';

const getValidation = (
  settings: ShippingIntegrationSettings,
  type: ShippoSettingsModalType
): [any, any] => {
  switch (type) {
    case ShippoSettingsModalType.GeneralSettings:
      return [generalSettingsValidation, settings];
    case ShippoSettingsModalType.InternationalSettings:
      return [internationalSettingsValidation, settings.internationalSettings];
    case ShippoSettingsModalType.ShippingExtrasSettings:
      return [extraSettingsValidation, settings.extraSettings];
  }
};

const ShippoCard: React.FC<ShippoCardProps> = () => {
  const [activeModal, setActiveModal] =
    useState<ShippoSettingsModalType | null>(null);

  const { settings, saveSettings, setErrors } = useContext(ShippoContext);

  const classes = useShippoCardStyle();

  const [isLoadingApply, setIsLoadingApply] = useState(false);

  const openModal = useCallback(
    (type: ShippoSettingsModalType) => () => {
      setActiveModal(type);
    },
    []
  );

  const handleCloseModal = () => setActiveModal(null);

  const handleApplyClicked = (type: ShippoSettingsModalType) => async () => {
    const [validation, fields] = getValidation(settings, type);

    if (!validateYup(fields, validation, setErrors)) {
      return;
    }

    setIsLoadingApply(true);
    await saveSettings();

    setActiveModal(null);
    setIsLoadingApply(false);
  };

  return (
    <>
      <Box className={classes.container}>
        <Paper id="shippo" className={classes.paper}>
          <TitleBar title="Shippo" imageUrl={ShippoLogoSmall} />

          <Box className={classes.cardRow}>
            <Typography variant="body1" color="textPrimary">
              Status
            </Typography>

            <Typography variant="body1" color="primary">
              Connected
            </Typography>
          </Box>
          <>
            <Box
              className={`${classes.cardRow} ${classes.cardRowClickable}`}
              onClick={openModal(ShippoSettingsModalType.GeneralSettings)}
            >
              <Typography variant="body1" color="textPrimary">
                General Settings
              </Typography>

              <ArrowRightIcon />
            </Box>

            <Box
              className={`${classes.cardRow} ${classes.cardRowClickable}`}
              onClick={openModal(ShippoSettingsModalType.InternationalSettings)}
            >
              <Typography variant="body1" color="textPrimary">
                International Settings
              </Typography>

              <ArrowRightIcon />
            </Box>

            <Box
              className={`${classes.cardRow} ${classes.cardRowClickable}`}
              onClick={openModal(
                ShippoSettingsModalType.ShippingExtrasSettings
              )}
            >
              <Typography variant="body1" color="textPrimary">
                Shipping Extras
              </Typography>

              <ArrowRightIcon />
            </Box>
          </>
        </Paper>
      </Box>

      <ShippoCardModal
        open={activeModal === ShippoSettingsModalType.GeneralSettings}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked(
          ShippoSettingsModalType.GeneralSettings
        )}
        isLoadingApply={isLoadingApply}
        modalType={ShippoSettingsModalType.GeneralSettings}
      />

      <ShippoCardModal
        open={activeModal === ShippoSettingsModalType.InternationalSettings}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked(
          ShippoSettingsModalType.InternationalSettings
        )}
        isLoadingApply={isLoadingApply}
        modalType={ShippoSettingsModalType.InternationalSettings}
      />

      <ShippoCardModal
        open={activeModal === ShippoSettingsModalType.ShippingExtrasSettings}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked(
          ShippoSettingsModalType.ShippingExtrasSettings
        )}
        isLoadingApply={isLoadingApply}
        modalType={ShippoSettingsModalType.ShippingExtrasSettings}
      />
    </>
  );
};

export default memo(ShippoCard);

import React, { useEffect, useState } from 'react';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { getActiveUser } from 'services/user';
import { getAccessToken } from 'services/auth';
import { useUrlQueryObject } from 'services/url';

import { RouteLeavingGuardProps, StateData } from './types';
import { ConfirmationModal } from '../../Modal/ConfirmationModal';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const RouteLeavingGuard = ({
  when,
  navigate,
  onSubmit,
  shouldBlockNavigation,
  bypassRouteLeavingGuard = false,
}: RouteLeavingGuardProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [urlQuery] = useUrlQueryObject();
  const { user } = useSelector(getActiveUser);

  // We need to disable route guard on tests
  // Tests are too fast in filling the data so popup is apearing
  const isTestUser = () => {
    if (user && user.email) {
      return user.email.includes('_testuser@test.com');
    }
    return false;
  };

  useEffect(() => {
    if (bypassRouteLeavingGuard) {
      handleDontSaveClicked();
    }
  }, [bypassRouteLeavingGuard]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function

      navigate(lastLocation);
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    const isCurrentId = nextLocation.search.includes(
      urlQuery.activeId!.toString()
    );

    const state: StateData = nextLocation?.state || {};

    if (
      state.triggerRouteGuard ||
      (!confirmedNavigation &&
        shouldBlockNavigation(nextLocation) &&
        !isTestUser() &&
        getAccessToken() &&
        !isCurrentId)
    ) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }

    return true;
  };

  const handleDontSaveClicked = () => {
    setLastLocation({
      ...lastLocation,
      state: { triggerRouteGuard: false },
    } as Location);
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const handleSaveClicked = async (e: any) => {
    const success = await onSubmit(e);
    if (success) {
      setModalVisible(false);
      if (lastLocation) {
        navigate({
          ...lastLocation,
          state: { triggerRouteGuard: false },
        } as Location);
      }
    }
  };

  const FBODialogActions = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="space-evenly"
      sx={{ width: '100%', paddingLeft: '16px', paddingRight: '16px' }}
    >
      <FBOButton
        variant="secondary"
        color="neutral"
        size="medium"
        onClick={handleDontSaveClicked}
        data-qa="unsavedChanges-modal-dontSavebtn"
        style={{ flexGrow: 1, minWidth: '127px' }}
      >
        Don't save
      </FBOButton>
      <FBOButton
        variant="secondary"
        color="positive"
        size="medium"
        onClick={closeModal}
        data-qa="unsavedChanges-modal-cancelbtn"
        style={{
          flexGrow: 1,
          minWidth: '127px',
          marginLeft: '8px',
          marginRight: '8px',
        }}
      >
        Cancel
      </FBOButton>
      <FBOButton
        variant="primary"
        color="positive"
        size="medium"
        onClick={handleSaveClicked}
        data-qa="unsavedChanges-modal-savebtn"
        style={{ flexGrow: 1, minWidth: '127px' }}
      >
        Save
      </FBOButton>
    </Box>
  );

  return (
    <>
      {/* We need to use Prompt this is the only way we can prevent react router from changing route */}
      <Prompt when={when} message={handleBlockedNavigation} />

      {/* This is our custom modal which actually gets called */}
      <ConfirmationModal
        body={'Do you want to save changes before leaving this page?'}
        title={'Unsaved Changes'}
        onCancelClicked={closeModal}
        onConfirmClicked={handleSaveClicked}
        open={modalVisible}
        DialogActionsComponent={FBODialogActions}
      />
    </>
  );
};

export default RouteLeavingGuard;

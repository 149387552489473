import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import { UrlQueryObject } from './types';

export const useUrlQueryObject = <T extends UrlQueryObject>(): [
  T,
  (updatedQueries: T) => void
] => {
  const history = useHistory();

  return [
    useMemo(() => {
      const parsedValues = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });
      return {
        ...parsedValues,
        activeId: parseInt(parsedValues.activeId as string, 10),
      } as T;
    }, [history.location.search]),

    useCallback(
      (updatedQueries: T) => {
        const currentQueryObject = qs.parse(history.location.search, {
          ignoreQueryPrefix: true,
        });

        const newQueryObject = {
          ...currentQueryObject,
          ...updatedQueries,
        };

        const queryString = qs.stringify(newQueryObject, {
          skipNulls: true,
        });

        history.replace({
          ...history.location,
          search: queryString,
        });
      },
      [history]
    ),
  ];
};

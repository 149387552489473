import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Grid, Box, Typography, List } from '@mui/material';
import { useSelector } from 'react-redux';

import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { Carrier, CarrierService } from 'services/carriers';
import { replaceValueInCollection, removeValueFromCollection } from 'helpers';
import { defaultCarrier } from 'services/carriers/consts';
import { activeUserHasPermission } from 'services/user/redux';
import {
  postCarrier,
  deleteCarrier,
  fetchCarrier,
  putCarrier,
  restoreCarrier,
} from 'services/carriers/api';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import { useUrlQueryObject } from 'services/url';

import { CarrierServiceListItem } from './CarrierServiceListItem';
import { createNewCarrierService } from './helpers';
import { CarrierDetailsCardCmp, CarrierDetailsCardProps } from './types';
import { yupCarriersSchema } from './validations';
import { CarriersTitleBar } from '../CarriersTitleBar';
import { editCarrierPermissions } from '../helpers';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const CarrierDetailCard: CarrierDetailsCardCmp = (
  props: CarrierDetailsCardProps
) => {
  const { activeCarrierId, onClose, fetchSearchResult } = props;

  const [, setQueryParams] = useUrlQueryObject();

  const [activeCarrier, setActiveCarrier] = useState<Carrier>(defaultCarrier);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteServiceIndex, setDeleteServiceIndex] = useState<number>(-1);
  const [validationErrors, setValidationErrors] = useState<Errors>({});

  const oldState = useRef<Carrier | null>(defaultCarrier);

  const firstInputElement = useRef<HTMLInputElement>(null);

  const editPermission = editCarrierPermissions(activeCarrier);

  const canClick = useSelector(activeUserHasPermission(editPermission));

  useEffect(() => {
    if (activeCarrier.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }
  }, [activeCarrier.id]);

  useEffect(() => {
    const asyncFc = async (id: number) => {
      setIsLoading(true);
      try {
        const customer = await fetchCarrier(id);
        oldState.current = customer;
        setActiveCarrier(customer);
      } catch (err) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    };

    if (!activeCarrierId || activeCarrierId === -1) {
      oldState.current = { ...defaultCarrier, id: activeCarrierId };
      setActiveCarrier({ ...defaultCarrier, id: activeCarrierId });
    } else {
      asyncFc(activeCarrierId);
    }
    setValidationErrors({});
  }, [activeCarrierId]);

  const setCarrierServices = useCallback(
    (carrierServices: React.SetStateAction<CarrierService[]>) => {
      if (typeof carrierServices === 'function') {
        setActiveCarrier((old) => ({
          ...old,
          carrierServiceList: carrierServices(old.carrierServiceList),
        }));
        return;
      }

      setActiveCarrier((old) => ({
        ...old,
        carrierServiceList: carrierServices,
      }));
    },
    [setActiveCarrier]
  );

  const saveClicked = useCallback(
    (close: boolean = false) =>
      async () => {
        if (
          !validateYup(activeCarrier, yupCarriersSchema, setValidationErrors)
        ) {
          return false;
        }

        setIsLoading(true);

        // Create new
        if (!activeCarrier.id || activeCarrier.id === -1) {
          try {
            const newActiveCarrier = await postCarrier(activeCarrier);
            oldState.current = newActiveCarrier;
            setActiveCarrier(newActiveCarrier);
            await fetchSearchResult();
            setQueryParams({ activeId: newActiveCarrier.id });
            if (close) {
              setQueryParams({ activeId: null });
              onClose();
              return true;
            }
          } catch {
            setIsLoading(false);
            return false;
          }
          setIsLoading(false);
          return true;
        }

        // Update
        try {
          const newActiveCarrier = await putCarrier(activeCarrier);
          oldState.current = newActiveCarrier;
          setActiveCarrier(newActiveCarrier);
          await fetchSearchResult();
        } catch {
          setIsLoading(false);
          return false;
        }
        if (close) {
          onClose();
        }
        setIsLoading(false);
        return true;
      },
    [activeCarrier, fetchSearchResult, onClose, setQueryParams]
  );

  const deleteCarrierClicked = useCallback(async () => {
    try {
      await deleteCarrier(activeCarrier.id!);
      await fetchSearchResult();

      setDeleteModalVisible(false);
      onClose();
    } catch {
      // continue regardless of error
    }
  }, [activeCarrier, fetchSearchResult, onClose]);

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreCarrier(activeCarrierId!);
        const restoredCarrier = await fetchCarrier(activeCarrierId!);
        oldState.current = restoredCarrier;
        setActiveCarrier(restoredCarrier);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activeCarrierId]
  );

  const handleDeleteCarrierModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  const handleDeleteServiceModalHide = () => {
    setDeleteServiceIndex(-1);
  };

  const handleTextInputChanged = useHandleTextFieldChange<Carrier>(
    setActiveCarrier,
    activeCarrier
  );

  const addNewServiceClicked = () => {
    const newCarrierService = createNewCarrierService(activeCarrier);
    setCarrierServices((old) => [...old, newCarrierService]);
  };

  const handleDeleteCarrierService = useCallback(
    (index: number) => () => {
      const service = activeCarrier.carrierServiceList[index];
      if (service.id === null || service.id < 0) {
        setCarrierServices((old) => removeValueFromCollection(old, index));
      } else {
        setCarrierServices(
          (old) =>
            replaceValueInCollection(old, { ...service, deleted: true }, index)!
        );
      }
    },
    [setCarrierServices, activeCarrier.carrierServiceList]
  );

  return (
    <DetailsCard
      isLoading={isLoading}
      onSubmit={saveClicked(false)}
      state={activeCarrier}
      oldState={oldState}
    >
      <CarriersTitleBar
        activeCarrier={activeCarrier}
        onSave={saveClicked(true)}
        onUndeleteClicked={handleUndeleteClicked}
        onClose={onClose}
        deleteModalVisible={handleDeleteCarrierModalVisible(true)}
      />
      <Box p={4} overflow="auto">
        <Grid container spacing={2}>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Name"
                placeholder="Enter name"
                name="name"
                autoComplete="off"
                fullWidth
                value={activeCarrier.name}
                required
                permissions={editPermission}
                inputRef={firstInputElement}
                onChange={handleTextInputChanged}
                error={!!validationErrors.name}
                disabled={activeCarrier ? activeCarrier.readOnly : false}
                dataQa="carrier-name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="SCAC"
                placeholder="Enter SCAC"
                name="scac"
                fullWidth
                permissions={editPermission}
                value={activeCarrier.scac}
                onChange={handleTextInputChanged}
                disabled={activeCarrier ? activeCarrier.readOnly : false}
                dataQa="carrier-scac"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Description"
              placeholder="Enter description"
              name="description"
              multiline
              permissions={editPermission}
              fullWidth
              value={activeCarrier.description}
              onChange={handleTextInputChanged}
              disabled={activeCarrier ? activeCarrier.readOnly : false}
              dataQa="carrier-description"
            />
          </Grid>
          <Grid item xs={12}>
            <Box pt={3}>
              <Typography>
                <b>Services</b>
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <List disablePadding>
                    {activeCarrier.carrierServiceList.map((service, index) => {
                      return (
                        !service.deleted &&
                        !activeCarrier.deleted && (
                          <CarrierServiceListItem
                            key={index}
                            carrierService={service}
                            onDelete={handleDeleteCarrierService(index)}
                            setCarrierServices={setCarrierServices}
                            carrierValidation={validationErrors}
                            index={index}
                            activeCarrierId={activeCarrierId}
                          />
                        )
                      );
                    })}
                  </List>
                </Grid>
                {canClick && (
                  <Grid item xs={12}>
                    <FBOButton
                      variant="tertiary"
                      color="neutral"
                      size="medium"
                      icon={IconNames.FBOAddCircle}
                      onClick={addNewServiceClicked}
                      data-qa="carrier-add-new-service"
                    >
                      Add New Service
                    </FBOButton>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Carrier"
        body={`This will delete '${_.get(
          activeCarrier,
          'name',
          'Carrier'
        )}', are you sure?`}
        onCancelClicked={handleDeleteCarrierModalVisible(false)}
        onConfirmClicked={deleteCarrierClicked}
        confirmLabel={'Delete'}
        cancelLabel={'Cancel'}
        confirmButtonRed
      />
      <ConfirmationModal
        open={deleteServiceIndex > -1}
        title="Delete service"
        body={`This will delete ${
          activeCarrier.name ? "'" + activeCarrier.name + "' " : ' '
        }service, are you sure?`}
        onCancelClicked={handleDeleteServiceModalHide}
        onConfirmClicked={deleteCarrierClicked}
        confirmLabel={'Delete'}
        cancelLabel={'Cancel'}
        confirmButtonRed
      />
    </DetailsCard>
  );
};

export default memo(CarrierDetailCard);

import React, { memo } from 'react';
import { Box, Switch, Typography, Divider } from '@mui/material';

import { ShippoRecipientType } from 'services/integrations/shipping/shippo';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

import { useShippingWizardStyle } from '../../../../styled';
import { useExtraSettings } from '../../../../ShipStationProvider';

const ShippingExtrasSettingsForm: React.FC = () => {
  const classes = useShippingWizardStyle();

  const { settings, setSettings, errors } = useExtraSettings();

  const handleCheckboxChange = useHandleCheckboxChange(setSettings);

  const handleRecipientTypeChange = (
    e: any,
    recipientType: ShippoRecipientType | null
  ) => {
    setSettings((old) => ({ ...old, recipientType }));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
      py={1}
    >
      <Box className={classes.wizardRow}>
        <Box>
          <Typography variant="body1" color="textPrimary">
            Saturday Delivery
          </Typography>
        </Box>

        <Switch
          checked={settings.saturdayDelivery}
          onChange={handleCheckboxChange}
          name="saturdayDelivery"
          color="primary"
        />
      </Box>

      <Divider />

      <Box className={classes.wizardRow}>
        <Box>
          <Typography variant="body1" color="textPrimary">
            Use Insurance
          </Typography>
        </Box>

        <Switch
          checked={settings.useInsurance}
          onChange={handleCheckboxChange}
          name="useInsurance"
          color="primary"
        />
      </Box>

      <Box>
        <Divider />
      </Box>

      <Box className={classes.wizardRow}>
        <Box>
          <Typography variant="body1" color="textPrimary">
            Contains Alcohol
          </Typography>
        </Box>

        <Switch
          checked={settings.containsAlcohol}
          onChange={handleCheckboxChange}
          name="containsAlcohol"
          color="primary"
        />
      </Box>
      {settings.containsAlcohol && (
        <Box className={classes.wizardRow}>
          <Box>
            <Typography variant="body1" color="textPrimary">
              Recipient Type
            </Typography>
          </Box>

          <Box width="45%">
            <Autocomplete
              options={Object.values(ShippoRecipientType)}
              placeholder="Select Type"
              onChange={handleRecipientTypeChange}
              value={settings.recipientType}
              error={!!errors.recipientType}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(ShippingExtrasSettingsForm);

import React, { memo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Typography, ButtonBase } from '@mui/material';
import _ from 'lodash';

import { ModuleNavigationItemStatus } from 'services/moduleNavigation';
import { Routes as SoRoutes } from 'ui/modules/sales';
import { Routes as PoRoutes } from 'ui/modules/purchasing';

import { PurchaseNavigationProps } from './types';
import { BreadCrumbLocation } from '../BreadCrumb/types';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme';
import FBOBreadCrumb from '../BreadCrumb/FBOBreadCrumb';

const FBOPurchaseNavigation: React.FC<PurchaseNavigationProps> = (props) => {
  const { items } = props;

  const {
    purchaseOrderId,
    pickIds = [],
    shipIds = [],
    receiptId,
    salesOrderIds = [],
  } = items;

  const classes = {
    icon: 'redesign',
    skeleton: 'redesign',
    navigation: 'redesign navigation',
    navigationContainer: 'redesign navigation-container',
    returnNavigation: 'redesign return-navigation',
    returnItem: 'redesign return-item',
    returnIcon: 'redesign',
  };

  const location = useLocation();
  const history = useHistory();

  const hasPicks = !_.isEmpty(pickIds);
  const hasShips = !_.isEmpty(shipIds);
  const hasSales = !_.isEmpty(salesOrderIds);

  const onPurchaseOrderPage = location.pathname.includes(
    PoRoutes.PurchaseOrderPage
  );
  const onReceiving = location.pathname.includes(PoRoutes.ReceivingPage);

  const purchaseOrderStatus = !purchaseOrderId
    ? ModuleNavigationItemStatus.Undefined
    : onPurchaseOrderPage
    ? ModuleNavigationItemStatus.Active
    : ModuleNavigationItemStatus.InActive;

  const receivingStatus = !receiptId
    ? ModuleNavigationItemStatus.Undefined
    : onReceiving
    ? ModuleNavigationItemStatus.Active
    : ModuleNavigationItemStatus.InActive;

  const purchaseOrderRoute = `${PoRoutes.PurchaseOrderPage}?activeId=${purchaseOrderId}`;
  const receivingRoute = `${PoRoutes.ReceivingPage}?activeId=${receiptId}`;

  const pickingRoute = `${SoRoutes.PickingPage}?activeId=${_.last(
    pickIds.sort()
  )}`;

  const shippingRoute = `${SoRoutes.ShippingPage}?activeId=${_.last(
    shipIds.sort()
  )}`;

  const salesRoute = `${SoRoutes.SalesOrderPage}?activeId=${_.last(
    salesOrderIds.sort()
  )}`;

  return (
    <Box className={classes.navigationContainer}>
      <Box className={classes.navigation}>
        <FBOBreadCrumb
          location={BreadCrumbLocation.Left}
          status={purchaseOrderStatus}
          zIndex={3}
          onClick={() =>
            history.push(purchaseOrderRoute, { triggerRouteGuard: true })
          }
          data-qa="spacial-navigation-purchase-order"
          disabled={
            purchaseOrderStatus === ModuleNavigationItemStatus.Undefined
          }
          disableRipple
          disableTouchRipple
        >
          <Typography variant="body2">Purchase Order</Typography>
        </FBOBreadCrumb>
        <Icon name={IconNames.FBOCaratRightSmall} />
        <FBOBreadCrumb
          location={BreadCrumbLocation.Right}
          status={receivingStatus}
          zIndex={2}
          onClick={() =>
            history.push(receivingRoute, { triggerRouteGuard: true })
          }
          data-qa="spacial-navigation-receiving"
          disabled={receivingStatus === ModuleNavigationItemStatus.Undefined}
          disableRipple
          disableTouchRipple
        >
          <Typography variant="body2">Receiving</Typography>
        </FBOBreadCrumb>
      </Box>
      {(hasPicks || hasShips || hasSales) && (
        <Box className={classes.returnNavigation}>
          {hasPicks && (
            <ButtonBase
              className={classes.returnItem}
              onClick={() =>
                history.push(pickingRoute, { triggerRouteGuard: true })
              }
              data-qa="return-navigation-picking"
              disableRipple
              disableTouchRipple
            >
              <Typography variant="body2">To Picking</Typography>
            </ButtonBase>
          )}
          {hasShips && (
            <ButtonBase
              className={classes.returnItem}
              onClick={() =>
                history.push(shippingRoute, { triggerRouteGuard: true })
              }
              data-qa="return-navigation-shipping"
              disableRipple
              disableTouchRipple
            >
              <Typography variant="body2">To Shipping</Typography>
            </ButtonBase>
          )}
          {hasSales && (
            <ButtonBase
              className={classes.returnItem}
              onClick={() =>
                history.push(salesRoute, { triggerRouteGuard: true })
              }
              data-qa="return-navigation-sales-order"
              disableRipple
              disableTouchRipple
            >
              <Typography variant="body2">To Sales Order</Typography>
            </ButtonBase>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(FBOPurchaseNavigation);

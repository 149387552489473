import React, { memo, useContext } from 'react';

import { ShipStationProps } from './types';
import { ShipStationContext } from '../../ShipStationProvider';
import { ShipStationWizard } from './components';
import { ShipStationCard } from './components/ShipStationCard';

const ShipStation: React.FC<ShipStationProps> = () => {
  const { isConnected, isSetupNeeded } = useContext(ShipStationContext);

  if (!isConnected) {
    return null;
  }

  return (
    <>
      {!isSetupNeeded && <ShipStationCard />}
      {isSetupNeeded && <ShipStationWizard />}
    </>
  );
};

export default memo(ShipStation);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { Page } from 'ui/components/Page/Page';
import {
  fetchSettingsCompanies,
  getSettingsCompany,
} from 'services/settings/company/redux';
import {
  ExportHistory,
  getHistory,
  getHistoryDetails,
  initialDataWithPagination,
  initialPagination,
} from 'services/accounting';
import {
  getQuickbooksAccountIsConnected,
  quickbooksDisconnect,
} from 'services/integrations/quickbooks';
import { Pagination } from 'services/search';
import { DataWithPagination } from 'services/api';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { Routes as IntegrationRoutes } from 'ui/modules/integrations/navigation/routes';
import { Routes as AccountingRoutes } from 'ui/modules/accounting';

import {
  AccountingSettingsPageCmp,
  AccountingSettingsPageProps,
} from './types';
import { Routes } from '../../navigation';
import { CardHistory, CardWaitingToExport, MappingModal } from './components';
import { useAccountingSettingsPageStyle } from './styled';
import {
  BackgroundStatus,
  BackgroundType,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import {
  getXeroAccountIsConnected,
  xeroDisconnect,
} from 'services/integrations/xero';
import { ErrorBoundary } from '@datadog/rum-react-integration';
import { FallbackUI } from 'ui/components/Modal/ErrorMessage/common';
import TaxRateMappingModal from './components/TaxRateMappingModal/components/TaxRateMappingModal';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { PermissionType } from 'services/permissions';

const AccountingSettingsPage: AccountingSettingsPageCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: AccountingSettingsPageProps
) => {
  const classes = useAccountingSettingsPageStyle();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { backgroundTasks } = useBackgroundTasks();

  const companySettings = useSelector(getSettingsCompany);
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const quickbooksConnected = useSelector(getQuickbooksAccountIsConnected);
  const [isInternationalConnected, setisInternationalConnected] =
    useState(false);
  useEffect(() => {
    if (companySettings.country !== 'US') {
      if (xeroConnected) {
        setisInternationalConnected(true);
      }
      if (quickbooksConnected) {
        setisInternationalConnected(true);
      }
    }
  }, [xeroConnected, quickbooksConnected, companySettings]);
  const integrationName = quickbooksConnected ? 'QuickBooks Online' : 'Xero';

  const [showMappingModal, setShowMappingModal] = useState(false);
  const [showTaxMappingModal, setShowTaxMappingModal] = useState(false);

  const [exportHistory, setExportHistory] = useState<
    DataWithPagination<ExportHistory>
  >(initialDataWithPagination);
  const [, setHistoryDetails] = useState<DataWithPagination<ExportHistory>>(
    initialDataWithPagination
  );
  const [disconnectModalVisible, setDisconnectModalVisible] = useState(false);
  const [disconnectMappingModalVisible, setDisconnectMappingModalVisible] =
    useState(false);

  const backgroundTask = useMemo(() => {
    const backgroundTasksQbo = backgroundTasks.filter(
      (bg) => bg.type === BackgroundType.Qbo
    );

    return _.last(backgroundTasksQbo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundTasks]);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSettingsCompanies());
  }, [dispatch]);

  useEffect(() => {
    getHistoryAndHistoryDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get initial data for Previus Export and History tables
  const getHistoryAndHistoryDetails = useCallback(async () => {
    try {
      const historyResp = await getHistory(initialPagination);
      setExportHistory(historyResp);
      if (!_.isEmpty(historyResp)) {
        const historyDetailsResp = await getHistoryDetails(
          historyResp.data[0].id,
          initialPagination
        );
        setHistoryDetails(historyDetailsResp);
      }
    } catch (e) {
      // Ignore error
    }
  }, []);

  useEffect(() => {
    if (backgroundTask) {
      if (
        backgroundTask.status === BackgroundStatus.Completed ||
        backgroundTask.status === BackgroundStatus.Failed
      ) {
        getHistoryAndHistoryDetails();
      }
    }
  }, [backgroundTask, getHistoryAndHistoryDetails]);

  const handleMappingClicked = useCallback(() => {
    setShowMappingModal(true);
  }, []);
  const handleTaxMappingClicked = useCallback(() => {
    setShowTaxMappingModal(true);
  }, []);

  useEffect(() => {
    setShowMappingModal(location.hash === '#showAccountModal');
  }, [location.hash]);

  // History table pagination handler
  const handleHistoryPaginationChange = useCallback(
    async (pagination: Pagination) => {
      try {
        const historyResp = await getHistory(pagination);
        setExportHistory(historyResp);
      } catch (e) {
        // Ignore error
      }
    },
    []
  );

  const qboDisconnectClicked = useCallback(() => {
    setDisconnectMappingModalVisible(true);
    setDisconnectModalVisible(false);
  }, [setDisconnectMappingModalVisible]);

  const handleClearMappingsActions = useCallback(
    async (clearMappings: boolean = false) => {
      if (quickbooksConnected) {
        // Lint skip to be removed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(quickbooksDisconnect(clearMappings));
      } else {
        // @ts-ignore
        dispatch(xeroDisconnect(clearMappings));
      }
      setDisconnectMappingModalVisible(false);
      history.push(IntegrationRoutes.IntegrationsPage);
    },
    [history, dispatch, quickbooksConnected]
  );
  return (
    <ErrorBoundary fallback={FallbackUI}>
      <Page direction="row" height="auto">
        <Box className={classes.cardContainer}>
          <Box
            display="flex"
            justifyContent="space-between"
            width="832px"
            pb={2}
          >
            <Box className={classes.companyNameContainer}>
              <Typography
                align="left"
                variant="h4"
                className={classes.companyName}
              >
                {companySettings.name}
              </Typography>
            </Box>
          </Box>
          <CardWaitingToExport
            onMappingClicked={handleMappingClicked}
            onTaxMappingClicked={handleTaxMappingClicked}
            isInternationalConnected={isInternationalConnected}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            width="832px"
            pb={4}
          >
            <FBOButton
              onClick={() => setDisconnectModalVisible(true)}
              data-qa="accounting-disconnect"
              variant="primary"
              color="negative"
              size="medium"
              permissions={[PermissionType.AccountingEdit]}
            >
              Disconnect
            </FBOButton>
            <Box className={classes.companyNameContainer}></Box>
          </Box>

          <CardHistory
            history={exportHistory}
            handlePaginationChange={handleHistoryPaginationChange}
          />
        </Box>
      </Page>
      <MappingModal
        open={showMappingModal}
        onClose={() => {
          history.push(AccountingRoutes.AccountingSettingsPage);
          setShowMappingModal(false);
        }}
      />
      <TaxRateMappingModal
        open={showTaxMappingModal}
        onClose={() => {
          history.push(AccountingRoutes.AccountingSettingsPage);
          setShowTaxMappingModal(false);
        }}
      />
      <ConfirmationModal
        open={disconnectModalVisible}
        title={`Disconnect ${integrationName} integration?`}
        onConfirmClicked={qboDisconnectClicked}
        onCancelClicked={() => setDisconnectModalVisible(false)}
        body={`You are about to disconnect ${integrationName} integration from your account. Do you want to continue?`}
        confirmButtonRed
        confirmLabel="Yes"
      />
      <ConfirmationModal
        open={disconnectMappingModalVisible}
        title={`Disconnect ${integrationName} integration?`}
        onConfirmClicked={() => handleClearMappingsActions(true)}
        onCancelClicked={() => handleClearMappingsActions()}
        body={`Would you like to clear the mapping between Fishbowl Online and ${integrationName}?
        You should only clear mappings if you're going to reconnect to a different version or a different account of ${integrationName}.`}
        confirmButtonRed
        confirmLabel="Yes"
        cancelLabel="No"
      />
    </ErrorBoundary>
  );
};

AccountingSettingsPage.route = Routes.AccountingSettingsPage;

export default AccountingSettingsPage;

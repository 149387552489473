import React from 'react';
import { Switch } from 'react-router-dom';

import { PermissionType } from 'services/permissions';
import SecureRoute from 'ui/components/Route/SecureRoute';
import { DownloadsPage } from '../pages';

import { Routes } from './routes';
import { NotificationsRouterCmp, NotificationsRouterProps } from './types';

const NotificationsRouter: NotificationsRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: NotificationsRouterProps
) => {
  return (
    <Switch>
      <SecureRoute
        permissions={[PermissionType.CustomersView]}
        path={DownloadsPage.route}
        exact
        component={DownloadsPage}
      />
    </Switch>
  );
};

NotificationsRouter.route = Routes.NotificationsRouter;

export default NotificationsRouter;

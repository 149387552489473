import React, { useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchLocations,
  fetchSearch,
  getSearches,
  transformLocation,
  LOCATION_COLUMNS,
  Location,
  getLocations,
} from 'services/locations';
import { Pagination } from 'services/search';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { fetchSettingsCompanies } from 'services/settings/company/redux';
import { useUrlQueryObject } from 'services/url';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { showProgressAlert } from 'services/alert/redux';
import { Dates, getErrorMessage } from 'helpers';

import { Routes } from '../../navigation';
import {
  LocationSearchResults,
  LocationAdvancedSearch,
  LocationOrderModal,
} from './components';
import { LocationPageCmp, LocationPageProps } from './types';
import {
  advancedSearchReduxActions,
  createDisplayValueMap,
  displayNameMap,
  initialLocationASFormValues,
  initialPagination,
} from './consts';
import { LocationsPageAction } from './components/LocationSearchResults/consts';
import { logErrorCtx } from 'app/logging';
import FBOLocationDetailsCard from './components/LocationDetailsCard/FBOLocationDetailsCard';

const LocationPage: LocationPageCmp = (props: LocationPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeLocationId,
  } = props;

  // showCompanyWide FLAG TO BE IMPLEMENTED LATER WITH REORDER POINTS
  const showCompanyWide = false;

  const { items: locations } = useSelector(getLocations);

  const [, extendUrlQuery] = useUrlQueryObject();
  const dispatch = useDispatch();

  const [showOrderModal, setShowOrderModal] = useState(false);
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);

  const onActiveLocationClose = () => extendUrlQuery({ activeId: null });

  const handleAddNewPress = () => extendUrlQuery({ activeId: -1 });

  const hiddenInput = useRef<HTMLInputElement>(null);

  const { startFetching, startCsvFetching } = useBackgroundTasks();

  const exportLocations = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.Location);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);

  const importLocations = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Location import has been initiated.');

      startCsvFetching();
      event.target.value = '';
      try {
        await startBackgroundImport(BackgroundType.Location, uploadedFile);
        dispatch(
          showProgressAlert(
            'Your Import to Locations has been initiated. This may take a few minutes to complete.',
            BackgroundType.Location,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);
        const error = e as Error;
        logErrorCtx('Error in startBackgroundImport', {
          stackTrace: error.stack,
          error,
          description: message,
          component: 'LocationPage',
        });
        return;
      }
    },
    [dispatch]
  );

  const handleLocationsPageAction = useCallback(
    (action: LocationsPageAction, date?: Dates) => {
      switch (action) {
        case LocationsPageAction.Export:
          exportLocations();
          break;
        case LocationsPageAction.Import:
          importLocations();
          break;
        case LocationsPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    [exportLocations, importLocations]
  );

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  const handleLocationOrderModalClose = useCallback(
    () => setShowOrderModal(false),
    []
  );

  const handleLocationClicked = (id: number | null) =>
    extendUrlQuery({ activeId: id });

  const handleLocationOrderModalApply = useCallback(() => {
    fetchSearchResult();
    setShowOrderModal(false);
  }, [fetchSearchResult]);

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={LOCATION_COLUMNS(activeDate)}
        initialFormValues={initialLocationASFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={LocationAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(locations)}
        pageName="Locations"
      >
        <PaperSlidingLayout shown={Boolean(activeLocationId)}>
          <LocationSearchResults
            locations={
              showCompanyWide
                ? searchResult.items
                : searchResult.items.filter((i: any) => !i.companyWide)
            }
            activeLocationId={activeLocationId}
            activeDate={activeDate}
            handleLocationClick={handleLocationClicked}
            onAddNewPress={handleAddNewPress}
            isLoadingLocations={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            onOrderClicked={() => setShowOrderModal(true)}
            onLocationsPageAction={handleLocationsPageAction}
          />

          <FBOLocationDetailsCard
            activeLocationId={activeLocationId}
            onClose={onActiveLocationClose}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <LocationOrderModal
        open={showOrderModal}
        onClose={handleLocationOrderModalClose}
        onApply={handleLocationOrderModalApply}
      />
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
    </>
  );
};

LocationPage.route = Routes.LocationPage;

export default withSearchResults<Location>(LocationPage, {
  url: '/v1/locations',
  expand: 'address',
  dataAdapter: transformLocation,
  columns: LOCATION_COLUMNS(Dates.DateCreated),
  initialPagination,
  getSearches,
  fetchSearch,
  rehydrationThunks: [fetchLocations, fetchSettingsCompanies],
});

import React, {
  memo,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { copyProductionEnvironment } from 'services/sandbox';
import { sideDrawerStatus } from '../FBODrawer';

const PopulateSandboxAccountData = React.forwardRef((_props, ref) => {
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { drawerExpanded } = useContext(sideDrawerStatus);
  const copyProductionDataClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await copyProductionEnvironment();
      history.go(0);
    } catch {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setCopyModalVisible(false);
  }, [history]);
  useEffect(() => {}, [drawerExpanded]);
  return (
    <>
      {drawerExpanded && (
        <Button
          ref={ref as React.RefObject<HTMLButtonElement>}
          onClick={() => setCopyModalVisible(true)}
          className="sync"
          disableRipple
        >
          Sync Account Data
        </Button>
      )}

      <ConfirmationModal
        open={copyModalVisible}
        title="Populate Sandbox With My Account Data"
        onConfirmClicked={copyProductionDataClicked}
        onCancelClicked={() => setCopyModalVisible(false)}
        body="You are about to copy your account data to your sandbox environment. You will lose any saved data in your sandbox. Do you want to continue?"
        confirmLabel="Yes"
        isLoading={isLoading}
      />
    </>
  );
});

export default memo(PopulateSandboxAccountData);

import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box, Grid } from '@mui/material';

import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { VendorAutocomplete } from 'ui/components/Autocomplete/VendorAutocomplete';
import { MultiFormatInput } from 'ui/components/TextField/MultiFormatInput';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { getVendors } from 'services/vendors/redux';
import {
  Channel,
  ChannelTaxType,
  createTaxRate,
  deleteTaxRate,
  fetchTaxRateById,
  initialTaxRate,
  restoreTaxRate,
  TaxRate,
  TaxType,
  updateTaxRate,
} from 'services/taxRates';
import { useUrlQueryObject } from 'services/url';
import { useHandleTextFieldChange } from 'services/forms';
import { Vendor } from 'services/vendors';
import { formatTypes, roundToDecimals, useGetCurrencySymbol } from 'helpers';
import { Errors, validateYup } from 'services/forms/validation';

import { TaxRateDetailsCardProps } from './types';
import { yupTaxRateSchema } from './validations';
import { setSelectedValueRequest } from './helpers';
import { editTaxRatePermissions } from '../helpers';
import { TaxRatesTitleBar } from '../TaxRatesTitleBar';
import { InternationalTaxRates } from 'ui/modules/accounting/pages/AccountingSettingsPage/components/TaxRateMappingModal/components/InternationalTaxRates';
import {
  getXeroAccountIsConnected,
  InternationalTaxRate,
} from 'services/integrations/xero';
import { useFlags } from 'helpers/useFlags';
import { getSettingsCompany } from 'services/settings/company';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

const TaxRateDetailCard: React.FC<TaxRateDetailsCardProps> = (props) => {
  const {
    activeTaxRateId,
    onClose,
    fetchSearchResult,
    isInternationalConnected,
    accountingType,
  } = props;
  const { items: vendors } = useSelector(getVendors);
  const flags = useFlags();

  const [, setQueryParams] = useUrlQueryObject();

  const currencySymbol = useGetCurrencySymbol();

  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTaxRate, setActiveTaxRate] = useState<TaxRate>(initialTaxRate);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [selectedType, setSelectedType] = useState(
    activeTaxRate.channelTaxType
  );
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const countryCode = useSelector(getSettingsCompany);
  const [isInternational, setIsInternational] = useState(false);
  const [selectedTaxRate, setSelectedTaxRate] =
    useState<InternationalTaxRate | null>(
      activeTaxRate.channel
        ? ({
            name: activeTaxRate.mappedTaxName || '',
            rate: activeTaxRate.channelTaxRate || '',
            code: activeTaxRate.code || '',
            taxType: activeTaxRate.channelTaxType || '',
          } as unknown as InternationalTaxRate)
        : null
    );
  const oldState = useRef<TaxRate | null>(initialTaxRate);

  const firstInputElement = useRef<HTMLInputElement>(null);

  const editPermissions = editTaxRatePermissions(activeTaxRate);

  useEffect(() => {
    if (activeTaxRate.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
      setSelectedType(activeTaxRate.channelTaxType);
    }
  }, [activeTaxRate.id]);
  useEffect(() => {}, [isInternationalConnected]);
  useEffect(() => {
    if (countryCode.country !== 'US') setIsInternational(true);
  }, [isInternational]);
  useEffect(() => {
    const asyncFc = async (taxRateId: number) => {
      setIsLoading(true);
      try {
        const newTaxRate = await fetchTaxRateById(taxRateId);
        oldState.current = newTaxRate;
        setActiveTaxRate(newTaxRate);
        setSelectedValueRequest(newTaxRate, setSelectedValue);
      } catch (err) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    };

    if (activeTaxRateId && activeTaxRateId !== -1) {
      asyncFc(activeTaxRateId);
    } else {
      const newTaxRate: TaxRate = {
        ...initialTaxRate,
        id: activeTaxRateId,
      };
      oldState.current = newTaxRate;
      setActiveTaxRate(newTaxRate);
      setSelectedValue(null);
    }
    setValidationErrors({});
  }, [activeTaxRateId, isInternationalConnected]);

  useEffect(() => {
    if (activeTaxRate.channel) {
      setSelectedTaxRate({
        name: activeTaxRate.mappedTaxName || '',
        rate: activeTaxRate.channelTaxRate || '',
        code: activeTaxRate.code || '',
        taxType: activeTaxRate.channelTaxType || null,
      } as unknown as InternationalTaxRate);
    }
  }, [activeTaxRate.channel, isInternationalConnected]);
  const selectedVendor = useMemo(() => {
    return vendors.find((c) => c.id === activeTaxRate.agencyId) || null;
  }, [vendors, activeTaxRate]);

  const handleVendorChange = useCallback(
    (v: Vendor | null) => {
      setActiveTaxRate((old) => ({ ...old, agencyId: v ? v.id : null }));
    },
    [setActiveTaxRate]
  );
  const handleTaxTypeChange = useCallback(
    (e: any, v: ChannelTaxType | null) => {
      setActiveTaxRate((old) => ({
        ...old,
        channelTaxType: v,
      }));
    },
    [setActiveTaxRate]
  );
  const handleInternationalTaxChange = useCallback(
    (taxRate: InternationalTaxRate): boolean => {
      if (!taxRate) {
        return false;
      }
      setSelectedType(taxRate.taxType);
      setSelectedValue(taxRate.rate);
      setActiveTaxRate((old) => ({
        ...old,
        mappedTaxName: taxRate.name,
        channel: xeroConnected ? Channel.Xero : Channel.Qbo,

        accountingId: taxRate.code,
        channelTaxType: taxRate.taxType,
      }));
      return true;
    },
    [setActiveTaxRate, setSelectedType]
  );
  const saveTaxRateClicked = useCallback(
    (close: boolean = false) =>
      async () => {
        if (
          !validateYup(activeTaxRate, yupTaxRateSchema, setValidationErrors)
        ) {
          return false;
        }

        setIsLoading(true);

        // Create new Tax Rate
        if (!activeTaxRate.id || activeTaxRate.id === -1) {
          try {
            const newActiveTaxRate = await createTaxRate(activeTaxRate);
            oldState.current = newActiveTaxRate;
            setActiveTaxRate(newActiveTaxRate);
            await fetchSearchResult();
            setQueryParams({ activeId: newActiveTaxRate.id });
            if (close) {
              setQueryParams({ activeId: null });
              onClose();
              return true;
            }
          } catch {
            setIsLoading(false);
            return false;
          }
          setIsLoading(false);
          return true;
        }

        // Update Tax Rate
        try {
          const newActiveTaxRate = await updateTaxRate(activeTaxRate);
          oldState.current = newActiveTaxRate;
          setActiveTaxRate(newActiveTaxRate);
          await fetchSearchResult();
        } catch {
          setIsLoading(false);
          return false;
        }
        if (close) {
          onClose();
        }
        setIsLoading(false);
        return true;
      },
    [activeTaxRate, fetchSearchResult, onClose, setQueryParams]
  );

  const deleteTaxRateClicked = useCallback(async () => {
    oldState.current = activeTaxRate;
    try {
      await deleteTaxRate(activeTaxRate.id!);
      await fetchSearchResult();
    } catch {
      return;
    }

    setDeleteModalVisible(false);
    onClose();
  }, [activeTaxRate, fetchSearchResult, onClose]);

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreTaxRate(activeTaxRateId!);
        const restoredTaxRate = await fetchTaxRateById(activeTaxRateId!);
        oldState.current = restoredTaxRate;
        setActiveTaxRate(restoredTaxRate);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activeTaxRateId]
  );

  const handleTaxChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = event.target.value;

      const percentage = value ? parseFloat(value) / 100 : 0;

      if (activeTaxRate.taxType === TaxType.percentage) {
        setActiveTaxRate((old) => ({
          ...old,
          percentage: roundToDecimals(percentage, 5),
          amount: 0,
        }));
      } else {
        setActiveTaxRate((old) => ({
          ...old,
          amount: value ? parseFloat(value) : null,
          percentage: 0,
        }));
      }
      setSelectedValue(value ? parseFloat(value) : null);
    },
    [activeTaxRate.taxType]
  );

  const handleTextFieldChange = useHandleTextFieldChange<TaxRate>(
    setActiveTaxRate,
    activeTaxRate
  );

  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  const handleTaxTypeChanged = (e: any) => {
    const taxTypeValue =
      e.target.innerText === currencySymbol
        ? TaxType.flatRate
        : TaxType.percentage;

    setActiveTaxRate((old) => ({
      ...old,
      taxType: taxTypeValue,
      percentage: selectedValue ? selectedValue / 100 : null,
      amount: selectedValue,
    }));
  };

  return (
    <DetailsCard
      onSubmit={saveTaxRateClicked(false)}
      isLoading={isLoading}
      state={activeTaxRate}
      oldState={oldState}
    >
      <TaxRatesTitleBar
        activeTaxRate={activeTaxRate}
        onSave={saveTaxRateClicked(true)}
        onClose={onClose}
        onUndeleteClicked={handleUndeleteClicked}
        deleteModalVisible={handleDeleteModalVisible(true)}
      />
      <Box m={4}>
        <Grid container item justifyContent="flex-start" spacing={2} xs={12}>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Name"
                placeholder="Enter name"
                name="name"
                autoComplete="off"
                fullWidth
                permissions={editPermissions}
                value={activeTaxRate.name}
                required
                inputRef={firstInputElement}
                onChange={handleTextFieldChange}
                error={!!validationErrors.name}
                dataQa="tax-rate-name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Code"
                placeholder="Enter code"
                name="code"
                autoComplete="off"
                fullWidth
                permissions={editPermissions}
                value={activeTaxRate.code}
                required
                onChange={handleTextFieldChange}
                error={!!validationErrors.code}
                dataQa="tax-rate-code"
              />
            </Grid>
          </Grid>

          {isInternationalConnected && flags.intlTaxRates && (
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                value={selectedType}
                disabled={isInternationalConnected}
                label="Type"
              />
            </Grid>
          )}

          {!isInternationalConnected &&
            flags.intlTaxRates &&
            isInternational && (
              <Grid item xs={6}>
                <Autocomplete
                  options={[
                    ChannelTaxType.Purchase,
                    ChannelTaxType.Sales,
                    ChannelTaxType.Both,
                  ]}
                  label="Type"
                  value={selectedType}
                  onChange={handleTaxTypeChange}
                  dataQa="intil-tax-type"
                />
              </Grid>
            )}
          <Grid item xs={isInternational && flags.intlTaxRates ? 6 : 12}>
            <VendorAutocomplete
              onChange={handleVendorChange}
              value={selectedVendor}
              label="Vendor"
              required
              permissions={editPermissions}
              error={!!validationErrors.agencyId}
              helperText={validationErrors.agencyId}
              dataQa="tax-rate-vendor"
            />
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <MultiFormatInput
                className="redesign"
                variant="standard"
                type="text"
                label="Amount"
                placeholder="Enter amount"
                value={selectedValue}
                onFormatChange={handleTaxTypeChanged}
                onChange={handleTaxChange}
                permissions={editPermissions}
                options={formatTypes(currencySymbol)}
                decimalPlaces={
                  activeTaxRate.taxType === TaxType.percentage ? 3 : 2
                }
                error={
                  activeTaxRate.taxType === TaxType.percentage
                    ? !!validationErrors.percentage
                    : !!validationErrors.amount
                }
                activeIndex={
                  activeTaxRate.taxType === TaxType.percentage ? 0 : 1
                }
                required
                dataQa="tax-rate-amount"
                disabled={isInternationalConnected && flags.intlTaxRates}
              />
            </Grid>
            <Grid item xs={6}>
              {isInternationalConnected && flags.intlTaxRates && (
                <InternationalTaxRates
                  accountingType={accountingType}
                  label={xeroConnected ? 'Xero mapping' : 'quickbook mapping'}
                  selectedOptionCallback={handleInternationalTaxChange}
                  // @ts-ignore
                  selectedValue={selectedTaxRate}
                  dataQa="intil-tax-rate"
                  disabled={false}
                  setSelectedValue={setSelectedTaxRate}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Tax rate"
        body={`This will delete '${_.get(
          activeTaxRate,
          'name',
          'Tax Rate'
        )}', are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={deleteTaxRateClicked}
        confirmLabel={'Delete'}
        cancelLabel={'Cancel'}
        confirmButtonRed
      />
    </DetailsCard>
  );
};

export default memo(TaxRateDetailCard);

import React, { memo } from 'react';
import { Box, Grid } from '@mui/material';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { QuillTextEditor } from 'ui/components/TextField/QuillTextEditor';

const OrderEmailsTab = () => {
  return (
    <Box p={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField type="text" placeholder="Subject" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <QuillTextEditor placeholder="Message" onChange={_.noop} value={''} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(OrderEmailsTab);

import React from 'react';
import { Switch } from 'react-router-dom';

import { PermissionType } from 'services/permissions';
import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes } from './routes';
import { VendorsPage } from '../pages';
import { PurchasingRouterProps, PurchasingRouterCmp } from './types';
import { PurchaseOrderPage } from '../pages/PurchaseOrderPage';
import { ReceivingPage } from '../pages/ReceivingPage';

const PurchasingRouter: PurchasingRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: PurchasingRouterProps
) => {
  return (
    <Switch>
      <SecureRoute
        permissions={[PermissionType.VendorsView]}
        path={VendorsPage.route}
        exact
        component={VendorsPage}
      />
      <SecureRoute
        permissions={[PermissionType.PurchaseOrderView]}
        path={PurchaseOrderPage.route}
        exact
        component={PurchaseOrderPage}
      />
      <SecureRoute
        permissions={[PermissionType.ReceivingView]}
        path={ReceivingPage.route}
        exact
        component={ReceivingPage}
      />
    </Switch>
  );
};

PurchasingRouter.route = Routes.PurchasingRouter;

export default PurchasingRouter;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Divider } from '@mui/material';
import _ from 'lodash';

import { colorPalette } from 'ui/theme';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import {
  deletePricingRule,
  fetchPricingRule,
  initialPricingRule,
  postPricingRule,
  PricingRule,
  putPricingRule,
} from 'services/pricingRules';
import { showNotification } from 'services/api';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import { useUrlQueryObject } from 'services/url';
import { PermissionType } from 'services/permissions';

import {
  PricingRulesCustomers,
  PricingRulesPriceAdjustment,
  PricingRulesProducts,
  PricingRulesSidebar,
} from './components';
import { PricingRulesCustomersGroup } from './components/PricingRulesCustomers/types';
import { PricingRulesProductsGroup } from './components/PricingRulesProducts/types';
import { PricingRulesDetailsCardProps } from './types';
import { usePricingRulesDetailsCardStyle } from './styled';
import { getYupSchema } from './validations';
import { getCheckedCustomersGroup, getCheckedProductsGroup } from './helpers';
import { getErrorMessage } from 'helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const PricingRulesDetailsCard: React.FC<PricingRulesDetailsCardProps> = (
  props
) => {
  const { activePricingRuleId, onClose, fetchSearchResult } = props;

  const classes = usePricingRulesDetailsCardStyle();

  const [, setQueryParams] = useUrlQueryObject();

  const [activePricingRule, setActivePricingRule] =
    useState<PricingRule>(initialPricingRule);
  const [checkedCustomersGroup, setCheckedCustomersGroup] = useState(
    PricingRulesCustomersGroup.Everyone
  );
  const [checkedProductsGroup, setCheckedProductsGroup] = useState(
    PricingRulesProductsGroup.All
  );
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const oldState = useRef<PricingRule | null>(initialPricingRule);

  const canEditPricingRules =
    activePricingRule.id && activePricingRule.id > 0
      ? [PermissionType.PricinRulesEdit]
      : [PermissionType.PricinRulesCreate];

  useEffect(() => {
    const asyncFc = async (id: number) => {
      setIsLoading(true);
      try {
        const pricingRule = await fetchPricingRule(id);
        oldState.current = pricingRule;
        setActivePricingRule(pricingRule);
        setCheckedCustomersGroup(getCheckedCustomersGroup(pricingRule));
        setCheckedProductsGroup(getCheckedProductsGroup(pricingRule));
      } catch (e) {
        const message = getErrorMessage(e);
        showNotification(`${message} - pricing rule couldn't be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);
        onClose();
        return;
      }
      setIsLoading(false);
    };

    if (!activePricingRuleId || activePricingRuleId === -1) {
      oldState.current = { ...initialPricingRule, id: activePricingRuleId };
      setActivePricingRule({ ...initialPricingRule, id: activePricingRuleId });
      setCheckedCustomersGroup(PricingRulesCustomersGroup.Everyone);
      setCheckedProductsGroup(PricingRulesProductsGroup.All);
      return;
    } else {
      asyncFc(activePricingRuleId);
    }
    setValidationErrors({});
    // eslint-disable-next-line
  }, [activePricingRuleId]);

  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  const handleTextFieldChange = useHandleTextFieldChange<PricingRule>(
    setActivePricingRule,
    activePricingRule
  );
  const handleNumberFieldChange = useHandleTextFieldChange<PricingRule>(
    setActivePricingRule,
    activePricingRule,
    true
  );

  const saveClicked = useCallback(
    (close: boolean = false) =>
      async () => {
        if (
          !validateYup(
            activePricingRule,
            getYupSchema(
              activePricingRule,
              checkedCustomersGroup,
              checkedProductsGroup,
              activePricingRule.type
            ),
            setValidationErrors
          )
        ) {
          return false;
        }

        setIsLoading(true);

        // Create new
        if (!activePricingRule.id || activePricingRule.id === -1) {
          try {
            const newActivePricingRule = await postPricingRule(
              activePricingRule
            );
            oldState.current = newActivePricingRule;
            setActivePricingRule(newActivePricingRule);
            await fetchSearchResult();
            setQueryParams({ activeId: newActivePricingRule.id });
            if (close) {
              setQueryParams({ activeId: null });
              onClose();
              return true;
            }
          } catch {
            setIsLoading(false);
            return false;
          }

          setIsLoading(false);
          return true;
        }

        // Update
        try {
          const newActivePricingRule = await putPricingRule(activePricingRule);
          oldState.current = newActivePricingRule;
          setActivePricingRule(newActivePricingRule);
          await fetchSearchResult();
        } catch {
          setIsLoading(false);
          return false;
        }
        if (close) {
          onClose();
        }
        setIsLoading(false);
        return true;
      },
    [
      activePricingRule,
      fetchSearchResult,
      onClose,
      setQueryParams,
      checkedCustomersGroup,
      checkedProductsGroup,
    ]
  );

  const deleteCustomerClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await deletePricingRule(activePricingRule.id!);
      await fetchSearchResult();
      onClose();
    } catch {
      // continue regardless of error
    }

    setIsLoading(false);
    setDeleteModalVisible(false);
  }, [activePricingRule, fetchSearchResult, onClose]);

  return (
    <>
      <DetailsCard
        isLoading={isLoading}
        onSubmit={saveClicked(false)}
        state={activePricingRule}
        oldState={oldState}
      >
        <FBOTitleBar
          title={activePricingRule.name || 'New Pricing Rule'}
          sx={{
            borderBottom: `1px solid ${colorPalette.redesign.background3}`,
          }}
        >
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="pricing-rule-save"
            type="submit"
            permissions={canEditPricingRules}
          >
            Save
          </FBOButton>
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="pricing-rule-save-and-close"
            permissions={canEditPricingRules}
            onClick={saveClicked(true)}
          >
            Save And Close
          </FBOButton>
          {activePricingRuleId !== -1 && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="negative"
              size="medium"
              icon="TrashCan"
              data-qa="pricing-rule-delete"
              permissions={[PermissionType.PricinRulesDelete]}
              onClick={handleDeleteModalVisible(true)}
            >
              Delete
            </FBOButton>
          )}
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="medium"
            icon="FBOClose"
            data-qa="pricing-rules-close"
            onClick={onClose}
          />
        </FBOTitleBar>

        {/* TODO : RESTYLING : SWAP WITH GRID V2 after FF is pulled */}
        <Box display="flex" flexGrow={1} overflow="hidden" position="relative">
          <Box className={classes.content} p={3}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="text"
                  label="Name"
                  placeholder="Enter name"
                  name="name"
                  autoComplete="nope"
                  permissions={canEditPricingRules}
                  value={activePricingRule.name}
                  onChange={handleTextFieldChange}
                  required
                  error={!!validationErrors.name}
                  dataQa="pricing-rule-name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="text"
                  label="Description"
                  placeholder="Enter description"
                  name="description"
                  autoComplete="nope"
                  permissions={canEditPricingRules}
                  value={activePricingRule.description}
                  onChange={handleTextFieldChange}
                  dataQa="pricing-rule-description"
                />
              </Grid>
              <Grid item xs={6}>
                <PricingRulesCustomers
                  activePricingRule={activePricingRule}
                  setActivePricingRule={setActivePricingRule}
                  checkedCustomersGroup={checkedCustomersGroup}
                  setCheckedCustomersGroup={setCheckedCustomersGroup}
                  validationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                />
              </Grid>

              <Grid item xs={6}>
                <PricingRulesProducts
                  activePricingRule={activePricingRule}
                  setActivePricingRule={setActivePricingRule}
                  checkedProductsGroup={checkedProductsGroup}
                  setCheckedProductsGroup={setCheckedProductsGroup}
                  validationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <PricingRulesPriceAdjustment
                  activePricingRule={activePricingRule}
                  setActivePricingRule={setActivePricingRule}
                  validationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.sidebar}>
            <PricingRulesSidebar
              activePricingRule={activePricingRule}
              setActivePricingRule={setActivePricingRule}
              handleTextFieldChange={handleNumberFieldChange}
              validationErrors={validationErrors}
            />
          </Box>
        </Box>
      </DetailsCard>

      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Pricing Rule"
        body={`This will delete '${_.get(
          activePricingRule,
          'name',
          'Pricing Rule'
        )}', are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={deleteCustomerClicked}
        confirmLabel="Delete"
        confirmButtonRed
      />
    </>
  );
};

export default PricingRulesDetailsCard;

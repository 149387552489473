import React, { memo } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { TabPanel } from 'ui/components/TabPanel';

import { useFBOWizardStyle } from './styled';
import { FBOWizardProps } from './types';
import { WizardFooter } from './components';
import FBOTitleBar from '../FBOTitleBar/FBOTitleBar';

const FBOWizard: React.FC<FBOWizardProps> = (props) => {
  const {
    title,
    steps = [],
    children = [],
    activeStep = 0,
    activeSubStep = 0,
    isLoading = false,
    hideSteps = false,
    disableNext = false,
    finishButtonLabel,
    customFooter,
    onNext,
    onPrevious,
    onChange,
    status,
  } = props;

  if (children.length !== steps.length) {
    throw Error('Number of steps must match number of children');
  }

  const classes = useFBOWizardStyle();

  const handleActiveTabChange = (
    _: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Box>
      {!!title && <FBOTitleBar title={title} statusCustom={status} />}
      <Box display="flex">
        {!hideSteps && (
          <Box className={classes.sidebar}>
            <Tabs
              value={activeStep}
              onChange={handleActiveTabChange}
              textColor="primary"
              variant="scrollable"
              orientation="vertical"
              scrollButtons={false}
              className={classes.tabs}
              sx={{ maxHeight: 'initial', minHeight: 'initial' }}
            >
              {steps.map((step, index) => (
                <Tab
                  key={`tab_${index}`}
                  label={
                    <Box>
                      <Box className={classes.tabVerticalLabel}>
                        {step.label}
                      </Box>
                      {!!step.substeps && activeStep === index && (
                        <Box>
                          <ul className={classes.tabVerticalList}>
                            {step.substeps.map((substep, indexSubstep) => (
                              <li
                                key={`substep_${index}_${indexSubstep}`}
                                className={
                                  activeSubStep === indexSubstep
                                    ? classes.activeSubStep
                                    : undefined
                                }
                              >
                                {substep.label}
                              </li>
                            ))}
                          </ul>
                        </Box>
                      )}
                    </Box>
                  }
                  icon={step.icon}
                  iconPosition={'start'}
                  className={`${classes.tab} ${classes.tabVertical}`}
                  disabled={step.disabled}
                  disableRipple
                />
              ))}
            </Tabs>
          </Box>
        )}
        <Box className={classes.content}>
          {children.map((child, index) => (
            <TabPanel
              value={activeStep}
              index={index}
              noSpacing
              key={`tab_panel_${index}`}
            >
              {activeStep === index ? child : null}
            </TabPanel>
          ))}
        </Box>
      </Box>
      <WizardFooter
        customFooter={customFooter}
        onNext={onNext}
        onPrevious={onPrevious}
        steps={steps}
        disableNext={disableNext}
        finishButtonLabel={finishButtonLabel}
        activeStep={activeStep}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default memo(FBOWizard);

import React, { useCallback, useRef, useState } from 'react';

import {
  fetchPaymentTerms,
  fetchSearch,
  getSearches,
} from 'services/paymentTerms/redux';
import {
  transformPaymentTerm,
  PAYMENT_TERM_COLUMNS,
  PaymentTerm,
} from 'services/paymentTerms';
import { Pagination } from 'services/search';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { useUrlQueryObject } from 'services/url';
import { Dates, getErrorMessage } from 'helpers';

import { Routes } from '../../navigation';
import {
  PaymentTermsSearchResults,
  PaymentTermsDetailsCard,
  PaymentTermsAdvancedSearch,
} from './components';
import { PaymentTermsCmp, PaymentTermsPageProps } from './types';
import {
  advancedSearchReduxActions,
  displayNameMap,
  initialPaymentTermASFormValues,
  initialPagination,
  displayValueMap,
  PaymentTermsPageAction,
} from './consts';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { showProgressAlert } from 'services/alert/redux';
import { logErrorCtx } from 'app/logging';
import { useDispatch } from 'react-redux';

const PaymentTermsPage: PaymentTermsCmp = (props: PaymentTermsPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activePaymentTermId,
  } = props;

  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const hiddenInput = useRef<HTMLInputElement>(null);
  const [, extendUrlQuery] = useUrlQueryObject();
  const { startFetching, startCsvFetching } = useBackgroundTasks();
  const handleActivePaymentTermClose = () => extendUrlQuery({ activeId: null });
  const handleAddNewPress = () => extendUrlQuery({ activeId: -1 });
  const dispatch = useDispatch();
  const handlePaymentTermClicked = (id: number | null) =>
    extendUrlQuery({ activeId: id });

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );
  const exportPaymentTerms = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.PaymentTerm);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);
  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Payment Terms import has been initiated.');

      startCsvFetching();

      event.target.value = '';
      try {
        await startBackgroundImport(BackgroundType.PaymentTerm, uploadedFile);
        dispatch(
          showProgressAlert(
            'Your Import to Payment Terms has been initiated. This may take a few minutes to complete.',
            BackgroundType.PaymentTerm,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);
        const error = e as Error;
        logErrorCtx('Error in startBackgroundImport', {
          stackTrace: error.stack,
          error,
          description: message,
          component: 'PaymentTermsPage',
        });
        return;
      }
    },
    [dispatch]
  );
  const importPaymentTerms = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handlePageAction = useCallback(
    (action: PaymentTermsPageAction, date?: Dates) => {
      switch (action) {
        case PaymentTermsPageAction.Export:
          exportPaymentTerms();
          break;
        case PaymentTermsPageAction.Import:
          importPaymentTerms();
          break;
        case PaymentTermsPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    [exportPaymentTerms, importPaymentTerms]
  );

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={PAYMENT_TERM_COLUMNS(activeDate)}
        initialFormValues={initialPaymentTermASFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={PaymentTermsAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={displayValueMap}
        pageName="Payment Terms"
      >
        <PaperSlidingLayout shown={Boolean(activePaymentTermId)}>
          <PaymentTermsSearchResults
            paymentTerms={searchResult.items}
            activePaymentTermId={activePaymentTermId}
            activeDate={activeDate}
            onPageAction={handlePageAction}
            handlePaymentTermClick={handlePaymentTermClicked}
            onAddNewPress={handleAddNewPress}
            isLoadingPaymentTerms={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
          />
          <PaymentTermsDetailsCard
            activePaymentTermId={activePaymentTermId}
            onClose={handleActivePaymentTermClose}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
    </>
  );
};

PaymentTermsPage.route = Routes.PaymentTermsPage;

export default withSearchResults<PaymentTerm>(PaymentTermsPage, {
  url: '/v1/payment_terms',
  dataAdapter: transformPaymentTerm,
  columns: PAYMENT_TERM_COLUMNS(Dates.DateCreated),
  initialPagination,
  fetchSearch,
  getSearches,
  rehydrationThunks: [fetchPaymentTerms],
});

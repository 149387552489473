import React, { memo, useCallback, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';

import TabPanel from 'ui/components/TabPanel/TabPanel';

import OrderEmailsTab from '../OrderEmailsTab/OrderEmailsTab';

const SaleOrdersEmailTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );
  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        indicatorColor="primary"
      >
        <Tab label="Estimate" />
        <Tab label="Open" />
        <Tab label="Partial" />
        <Tab label="Fulfilled" />
      </Tabs>
      <TabPanel value={activeTab} index={0} flexGrow noSpacing>
        <Box display="flex" flexDirection="column" width="100%" flexGrow={1}>
          <OrderEmailsTab />
        </Box>
      </TabPanel>
      <TabPanel value={activeTab} index={1} flexGrow noSpacing>
        <Box display="flex" flexDirection="column" width="100%" flexGrow={1}>
          <OrderEmailsTab />
        </Box>
      </TabPanel>
      <TabPanel value={activeTab} index={2} flexGrow noSpacing>
        <Box display="flex" flexDirection="column" width="100%" flexGrow={1}>
          <OrderEmailsTab />
        </Box>
      </TabPanel>
      <TabPanel value={activeTab} index={3} flexGrow noSpacing>
        <Box display="flex" flexDirection="column" width="100%" flexGrow={1}>
          <OrderEmailsTab />
        </Box>
      </TabPanel>
    </>
  );
};

export default memo(SaleOrdersEmailTabs);

import React, { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import {
  fetchMostPurchasedItems,
  fetchOpenPurchaseOrders,
  fetchOpenReceipts,
} from 'services/dashboard/api';
import { PurchaseOrder } from 'services/purchaseOrders';
import {
  OpenPurchaseOrder,
  OpenReceipt,
  TopPurchasedItem,
} from 'services/dashboard';
import { Pagination } from 'services/search';
import { DataWithPagination } from 'services/api';
import { Routes as ItemRoutes } from 'ui/modules/materials';
import { Routes as PurchaseRoutes } from 'ui/modules/purchasing';
import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';

import { DashboardTable } from '../DashboardTable';
import {
  TOP_PURCHASED_ITEMS_COLUMNS,
  OPEN_PURCHASE_ORDER_COLUMNS,
  OPEN_RECEIPTS_COLUMNS,
} from './consts';
import { DashboardPurchasingProps } from './types';
import { dashboardInitialDataWithPagination, DATE_FORMAT } from '../../consts';

const DashboardPurchasing: React.FC<DashboardPurchasingProps> = ({
  fromStartDate,
  fromEndDate,
}) => {
  const [purchaseOrders, setPurchaseOrders] = useState<
    DataWithPagination<PurchaseOrder>
  >(dashboardInitialDataWithPagination);
  const [receipts, setReceipts] = useState<DataWithPagination<OpenReceipt>>(
    dashboardInitialDataWithPagination
  );
  const [topPurchasedItems, setTopPurchasedItems] = useState<
    DataWithPagination<TopPurchasedItem>
  >(dashboardInitialDataWithPagination);

  const [openPurchaseOrders, setOpenPurchaseOrders] = useState<
    DataWithPagination<OpenPurchaseOrder>
  >(dashboardInitialDataWithPagination);

  const currencyFormatter = useCurrencyFormatter();
  const intlDateFormat = useGetIntlDateFormatString();

  useEffect(() => {
    getPurchaseOrders(purchaseOrders.pagination);
    getOpenReceipts(receipts.pagination);
    getTopPurchasedItems(topPurchasedItems.pagination);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromStartDate, fromEndDate]);

  const getPurchaseOrders = async (pagination: Pagination) => {
    if (!fromStartDate.isValid() || !fromEndDate.isValid()) {
      return;
    }

    try {
      const openPurchaseOrders = await fetchOpenPurchaseOrders(
        pagination,
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT)
      );
      setOpenPurchaseOrders(openPurchaseOrders);
    } catch {
      setPurchaseOrders(dashboardInitialDataWithPagination);
    }
  };

  const getOpenReceipts = async (pagination: Pagination) => {
    if (!fromStartDate.isValid() || !fromEndDate.isValid()) {
      return;
    }

    try {
      const openReceipts = await fetchOpenReceipts(
        pagination,
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT)
      );
      setReceipts(openReceipts);
    } catch {
      setReceipts(dashboardInitialDataWithPagination);
    }
  };

  const getTopPurchasedItems = async (pagination: Pagination) => {
    if (!fromStartDate.isValid() || !fromEndDate.isValid()) {
      return;
    }

    try {
      const purchasedItems = await fetchMostPurchasedItems(
        pagination,
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT)
      );

      setTopPurchasedItems(purchasedItems);
    } catch {
      setTopPurchasedItems(dashboardInitialDataWithPagination);
    }
  };

  const handlePurchaseOrdersPaginationChange = (pagination: Pagination) => {
    setPurchaseOrders({ ...purchaseOrders, pagination });
    getPurchaseOrders(pagination);
  };

  const handleReceiptsPaginationChange = (pagination: Pagination) => {
    setReceipts({ ...receipts, pagination });
    getOpenReceipts(pagination);
  };

  const handleTopPurchasedItemsPaginationChange = (pagination: Pagination) => {
    setTopPurchasedItems({ ...topPurchasedItems, pagination });
    getTopPurchasedItems(pagination);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <DashboardTable
        title="Top Purchased Items"
        routeLink={ItemRoutes.ItemsPage}
        routeLinkLabel="Go to items"
        data={topPurchasedItems.data}
        pagination={topPurchasedItems.pagination}
        columns={TOP_PURCHASED_ITEMS_COLUMNS}
        selectableItems={false}
        onPaginationChange={handleTopPurchasedItemsPaginationChange}
        containerHeight={460}
      />
      <DashboardTable
        title="Open Purchase Orders"
        routeLink={PurchaseRoutes.PurchaseOrderPage}
        routeLinkLabel="Go to purchase orders"
        data={openPurchaseOrders.data}
        columns={OPEN_PURCHASE_ORDER_COLUMNS(intlDateFormat, currencyFormatter)}
        selectableItems={false}
        pagination={purchaseOrders.pagination}
        onPaginationChange={handlePurchaseOrdersPaginationChange}
        containerHeight={460}
      />
      <DashboardTable
        title="Open Receipts"
        routeLink={PurchaseRoutes.ReceivingPage}
        routeLinkLabel="Go to receiving"
        data={receipts.data}
        columns={OPEN_RECEIPTS_COLUMNS(intlDateFormat)}
        selectableItems={false}
        pagination={receipts.pagination}
        onPaginationChange={handleReceiptsPaginationChange}
        containerHeight={460}
      />
    </Box>
  );
};

export default memo(DashboardPurchasing);

import React from 'react';
import { Switch } from 'react-router-dom';

import { PermissionType } from 'services/permissions';
import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes } from './routes';
import { DashboardRouterCmp } from './types';
import { DashboardPage, HomePage } from '../pages';

import { FBOFlowPage } from '../pages/FlowPage/FBOFlowPage';

const HomeRouter: DashboardRouterCmp = () => {
  return (
    <Switch>
      <SecureRoute
        path={DashboardPage.route}
        exact
        component={DashboardPage}
        permissions={[PermissionType.DashboardView]}
      />
      <SecureRoute path={Routes.FlowPage} exact component={FBOFlowPage} />
      <SecureRoute path={HomePage.route} component={HomePage} />
    </Switch>
  );
};

HomeRouter.route = Routes.HomeRouter;

export default HomeRouter;

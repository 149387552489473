import React, { memo } from 'react';
import { Box } from '@mui/material';

import { WizardFooterProps } from './types';
import { useWizardStyle } from '../../styled';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const WizardFooter: React.FC<WizardFooterProps> = (props) => {
  const {
    activeStep,
    steps,
    isLoading = false,
    customFooter: CustomFooter,
    secondaryButtonComp,
    finishButtonLabel = 'Finish',
    disableNext,
    onPrevious,
    onNext,
  } = props;

  const classes = useWizardStyle();

  const hasFooter = !!CustomFooter || !!onPrevious || !!onNext;

  if (!hasFooter) return null;

  const fboFooter = (
    <Box className={classes.footer}>
      {CustomFooter ? (
        CustomFooter
      ) : (
        <>
          {!!onPrevious && (
            <FBOButton
              variant="secondary"
              color="neutral"
              size="medium"
              onClick={onPrevious}
              disabled={activeStep === 0}
              loading={isLoading}
              data-qa="wizard-back"
            >
              Back
            </FBOButton>
          )}
          <Box flex={1} />
          {activeStep === steps.length - 1 && secondaryButtonComp
            ? secondaryButtonComp
            : null}
          {!!onNext && (
            <FBOButton
              variant="primary"
              color="positive"
              size="medium"
              disabled={disableNext}
              loading={isLoading}
              onClick={onNext}
              data-qa="wizard-next"
              sx={{ minWidth: 0 }}
            >
              {activeStep === steps.length - 1 ? finishButtonLabel : 'Next'}
            </FBOButton>
          )}
        </>
      )}
    </Box>
  );

  return fboFooter;
};

export default memo(WizardFooter);

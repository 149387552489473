import React from 'react';
import { Box, Typography } from '@mui/material';
import { useFlowPageStyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

export interface FBOFlowCardProps {
  title: string;
  description: string;
  link: string;

  dataQa?: string;
}

export const FBOFlowCard: React.FC<FBOFlowCardProps> = (props) => {
  const { link, description, title, dataQa } = props;

  const classes = useFlowPageStyle();

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.redesignFlowCard}
    >
      <FBOButton
        variant="tertiary"
        color="positive"
        size="medium"
        data-qa={`${dataQa}-link`}
        onClick={() => null}
        href={link}
      >
        {title}
      </FBOButton>
      <Typography className={classes.redesignFlowCardDescription}>
        {description}
      </Typography>
    </Box>
  );
};

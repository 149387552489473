import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, Checkbox, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';

import {
  findNextNegativeId,
  handleUrlLinkClicked,
  replaceValueInCollection,
  getExchangeRate,
  USCountryString,
} from 'helpers';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { RepresentativesAutocomplete } from 'ui/components/Autocomplete/RepresentativesAutocomplete';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { AddressAutocomplete } from 'ui/components/Autocomplete/AddressAutocomplete';
import { ActiveItemIdState } from 'ui/components/Page/WithSearchResults';
import { PhoneInputField } from 'ui/components/TextField/PhoneInputField';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { CurrencyAutocomplete } from 'ui/components/Autocomplete/CurrencyAutocomplete';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { Address } from 'services/addresses';
import { getPaymentTerms } from 'services/paymentTerms';
import { getRepresentatives } from 'services/representatives';
import { useHandleTextFieldChange } from 'services/forms';
import { Customer, fetchCustomerById, getCustomers } from 'services/customers';
import { getCarriers, Carrier, CarrierService } from 'services/carriers';
import { getOrderPriorities } from 'services/settings/orderPriorities';
import { Tag } from 'services/tags';
import { Class } from 'services/classes/types';
import {
  CustomField,
  fetchCustomFieldsAPI,
  ObjectType,
  useCustomFields,
} from 'services/customFields';
import { getClasses } from 'services/classes';
import { Currency } from 'services/currencies';
import { getSettingsCompany } from 'services/settings/company';

import { editCustomerPermissions } from '../../../helpers';
import { GeneralTabsProps } from './types';
import { TaxRatesAutocomplete } from 'ui/components/Autocomplete/TaxRatesAutocomplete';
import { TaxRateVariants } from 'ui/components/Autocomplete/TaxRatesAutocomplete/types';
import { ChannelTaxType, TaxRate } from 'services/taxRates';
import { stringToDecimal } from 'services/forms';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';
import FBOCustomFields from 'ui/components/CustomFields/CustomFields/FBOCustomFields';
import {
  useHandleCheckboxChange,
  useEnterKeyPressHandler,
} from 'services/forms/hooks';

const GeneralTab: React.FC<GeneralTabsProps> = (props) => {
  const {
    activeCustomer,
    setActiveCustomer,
    validationErrors,
    customFieldsErrors,
    oldState,
  } = props;

  const { items: paymentTerms } = useSelector(getPaymentTerms);
  const { items: salesRepresentatives } = useSelector(getRepresentatives);
  const { items: carriers } = useSelector(getCarriers);
  const { items: parentCustomers } = useSelector(getCustomers);
  const { items: orderPriorities } = useSelector(getOrderPriorities);

  const [selectedParentCustomer, setSelectedParentCustomer] =
    useState<Customer | null>(null);

  const classes = useSelector(getClasses);

  const handleTextFieldChange = useHandleTextFieldChange<Customer>(
    setActiveCustomer,
    activeCustomer
  );

  const handleEnterPress = useEnterKeyPressHandler();

  const editPermission = editCustomerPermissions(activeCustomer);
  const isInternationalCustomer: boolean =
    useSelector(getSettingsCompany).country !== USCountryString;

  const firstInputElement = useRef<HTMLInputElement>(null);

  const setCustomFields = useCustomFields<Customer>(setActiveCustomer);

  const useMultiCurrencyEnabled =
    useSelector(getSettingsCompany).useMultiCurrency;

  const selectedPaymentTerm = useMemo(() => {
    return (
      paymentTerms.find((p) => p.id === activeCustomer.paymentTermId) || null
    );
  }, [paymentTerms, activeCustomer]);

  const selectedRepresentative = useMemo(() => {
    return (
      salesRepresentatives.find(
        (p) => p.id === activeCustomer.representativeId
      ) || null
    );
  }, [salesRepresentatives, activeCustomer]);

  const existingAddresses = useMemo(
    () => activeCustomer.addresses.filter((a) => !a.deleted),
    [activeCustomer.addresses]
  );

  const selectedBillToAddress = useMemo(
    () => existingAddresses.find((a) => a.defaultBillTo === true) || null,
    [existingAddresses]
  );

  const selectedShipToAddress = useMemo(
    () => existingAddresses.find((a) => a.defaultShipTo === true) || null,
    [existingAddresses]
  );

  useEffect(() => {
    const getParentCustomerById = async (id: number) => {
      try {
        const parentCustomerFromApi = await fetchCustomerById(id);
        setSelectedParentCustomer(parentCustomerFromApi);
      } catch (e) {
        setSelectedParentCustomer(null);
      }
    };
    const parentCustomerFromState = parentCustomers.find(
      (p) => p.id === activeCustomer.parentCustomerId
    );

    if (!activeCustomer.parentCustomerId) {
      setSelectedParentCustomer(null);
    } else if (parentCustomerFromState) {
      setSelectedParentCustomer(parentCustomerFromState);
    } else if (activeCustomer.parentCustomerId) {
      getParentCustomerById(activeCustomer.parentCustomerId);
    }
  }, [parentCustomers, activeCustomer?.parentCustomerId, activeCustomer?.id]);

  const selectedCarrier = useMemo(() => {
    return (
      carriers.find((c) => c.id === activeCustomer.defaultCarrierId) || null
    );
  }, [carriers, activeCustomer]);

  const selectedService = useMemo(() => {
    if (!selectedCarrier) {
      return null;
    }
    return (
      selectedCarrier.carrierServiceList.find(
        (c) => c.id === activeCustomer.defaultCarrierServiceId
      ) || null
    );
  }, [activeCustomer, selectedCarrier]);

  const selectedOrderPriority = useMemo(() => {
    return (
      orderPriorities.find(
        (p) => p.id === activeCustomer.defaultOrderPriorityId
      ) || null
    );
  }, [orderPriorities, activeCustomer]);

  useEffect(() => {
    if (activeCustomer.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }
  }, [activeCustomer.id]);

  useEffect(() => {
    // Fetch customFields and add them to state
    if (activeCustomer.id === ActiveItemIdState.New) {
      (async () => {
        const customFields = (
          await fetchCustomFieldsAPI({}, ObjectType.Customer)
        ).data;
        if (oldState.current) {
          oldState.current.customFields = customFields;
        }
        setCustomFields(customFields);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCustomer.id]);

  const handleAddressApply = useCallback(
    (type: 'defaultBillTo' | 'defaultShipTo') => async (address: Address) => {
      setActiveCustomer((old) => ({
        ...old,
        addresses: [
          ...old.addresses.map((a) => ({ ...a, [type]: false })),
          { ...address, id: findNextNegativeId(old.addresses), [type]: true },
        ],
      }));
    },
    [setActiveCustomer]
  );

  const handleAutocompleteChange = useCallback(
    (name: string) => (e: any, v: any) => {
      setActiveCustomer((old) => ({ ...old, [name]: v ? v.id : null }));
    },
    [setActiveCustomer]
  );

  const selectedClass = useMemo(() => {
    return (
      classes.find((v) => v.id === activeCustomer.accountingClassId) || null
    );
  }, [activeCustomer, classes]);

  const autocompleteLabelResolver = useCallback(
    (option) => option.displayName || option.name,
    []
  );

  const handleTagsChange = useCallback(
    (values: Tag[]) => setActiveCustomer((old) => ({ ...old, tags: values })),
    [setActiveCustomer]
  );

  const customFieldChanged = useCallback(
    (customField: CustomField) => {
      const index = activeCustomer.customFields.findIndex(
        (c) => c.id === customField.id
      );
      setCustomFields(
        (old) => replaceValueInCollection<CustomField>(old, customField, index)!
      );
    },
    [activeCustomer.customFields, setCustomFields]
  );

  const handleDefaultAddress =
    (type: 'defaultBillTo' | 'defaultShipTo') =>
    (e: any, address: Address | null) => {
      // if address is selected, we want to set it's default flag
      // otherwise all default flags are false
      setActiveCustomer((old) => ({
        ...old,
        addresses: old.addresses.map((a) => ({
          ...a,
          [type]: address ? a.id === address.id : false,
        })),
      }));
    };

  const handleClassChange = (accountingClass: Class | null) => {
    setActiveCustomer((old) => ({
      ...old,
      accountingClassId: accountingClass ? accountingClass.id : null,
    }));
  };

  const handleCarrierChange = useCallback(
    (e: React.ChangeEvent<{}>, carrier: Carrier | null) => {
      setActiveCustomer((old) => ({
        ...old,
        defaultCarrierId: carrier ? carrier.id : null,
        defaultCarrierServiceId: null,
      }));
    },
    [setActiveCustomer]
  );

  const handleServiceChange = useCallback(
    (e: React.ChangeEvent<{}>, service: CarrierService | null) => {
      setActiveCustomer((old) => ({
        ...old,
        defaultCarrierServiceId: service ? service.id : null,
      }));
    },
    [setActiveCustomer]
  );

  const handleCurrencyChange = useCallback(
    (currency: Currency | null) => {
      setActiveCustomer((old) => ({
        ...old,
        currencyId: currency ? currency.id : null,
        currency: currency,
      }));
    },
    [setActiveCustomer]
  );

  const handleTaxRateChange = useCallback(
    (taxRate: TaxRate | null) => {
      setActiveCustomer((old) => ({
        ...old,
        salesTax: taxRate,
        taxId: taxRate ? taxRate.id : null,
      }));
    },
    [setActiveCustomer]
  );

  const handleTaxExemptChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setActiveCustomer((old) => ({
        ...old,
        taxExempt: checked,
        taxId: null,
        salesTax: null,
      }));
    },
    [setActiveCustomer]
  );

  const handleExchangeRateChange = useCallback(
    (e: any) => {
      const value = e.target.value;

      setActiveCustomer((old) => ({
        ...old,
        customExchangeRate: value === '' ? value : stringToDecimal(value),
      }));
    },
    [setActiveCustomer]
  );

  const resetRate = useCallback(() => {
    setActiveCustomer((old) => ({
      ...old,
      customExchangeRate: null,
    }));
  }, [setActiveCustomer]);

  const handleCheckboxChange = useHandleCheckboxChange(setActiveCustomer);

  return (
    <Grid
      sx={{ overflowY: 'scroll', padding: '16px' }}
      container
      spacing={2}
      disableEqualOverflow
    >
      <Grid xs={4}>
        <Typography style={{ marginBottom: '16px' }} variant="h5">
          Details
        </Typography>
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="Name"
          placeholder="Enter customer name"
          name="name"
          autoComplete="off"
          fullWidth
          inputRef={firstInputElement}
          value={activeCustomer.name}
          required
          onChange={handleTextFieldChange}
          permissions={editPermission}
          error={!!validationErrors.name}
          dataQa="customer-name"
        />
        <Autocomplete
          onChange={handleAutocompleteChange('paymentTermId')}
          value={selectedPaymentTerm}
          label="Payment Terms"
          placeholder="Select payment terms"
          options={paymentTerms}
          getOptionLabel={autocompleteLabelResolver}
          permissions={editPermission}
          error={!!validationErrors.paymentTermId}
          required
          autoSelect={false}
          dataQa="customer-payment-term"
        />
        <RepresentativesAutocomplete
          onChange={(representative) =>
            handleAutocompleteChange('representativeId')({}, representative)
          }
          value={selectedRepresentative}
          label="Sales Representative"
          placeholder="Select sales representative"
          permissions={editPermission}
          dataQa="customer-sales-representative"
          autoSelect={false}
        />
      </Grid>
      <Grid xs={4}>
        <Typography style={{ marginBottom: '16px' }} variant="h5">
          Phone
        </Typography>
        <PhoneInputField
          label="Office"
          placeholder="Enter office phone number"
          name="office"
          autoComplete="nope"
          fullWidth
          value={activeCustomer.office}
          permissions={editPermission}
          onChange={handleTextFieldChange}
          data-qa="customer-office"
          error={!!validationErrors.phone}
        />
        <PhoneInputField
          label="Mobile"
          placeholder="Enter mobile phone number"
          name="mobile"
          autoComplete="nope"
          fullWidth
          value={activeCustomer.mobile}
          permissions={editPermission}
          onChange={handleTextFieldChange}
          data-qa="customer-mobile"
        />
        <PhoneInputField
          label="Fax"
          placeholder="Enter fax number"
          name="fax"
          autoComplete="nope"
          fullWidth
          value={activeCustomer.fax}
          permissions={editPermission}
          onChange={handleTextFieldChange}
          data-qa="customer-fax"
        />
      </Grid>
      <Grid xs={4}>
        <Typography style={{ marginBottom: '16px' }} variant="h5">
          Contact Email
        </Typography>
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="Email"
          placeholder="Enter email address"
          name="email"
          autoComplete="nope"
          fullWidth
          value={activeCustomer.email}
          error={!!validationErrors.email}
          permissions={editPermission}
          onChange={handleTextFieldChange}
          dataQa="customer-email"
        />
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="CC"
          placeholder="Enter cc"
          name="cc"
          fullWidth
          value={activeCustomer.cc}
          permissions={editPermission}
          onChange={handleTextFieldChange}
          dataQa="customer-cc"
          error={!!validationErrors.cc}
        />
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="BCC"
          placeholder="Enter bcc"
          name="bcc"
          fullWidth
          value={activeCustomer.bcc}
          permissions={editPermission}
          onChange={handleTextFieldChange}
          dataQa="customer-bcc"
          error={!!validationErrors.bcc}
        />
      </Grid>
      <Grid xs={12}>
        <Divider />
      </Grid>
      <Grid xs={4}>
        <Typography style={{ marginBottom: '16px' }} variant="h5">
          Address
        </Typography>
        <AddressAutocomplete
          label="Default Billing Address"
          value={selectedBillToAddress}
          addresses={activeCustomer.addresses}
          permissions={editPermission}
          onChange={handleDefaultAddress('defaultBillTo')}
          modalApplyClicked={handleAddressApply('defaultBillTo')}
          dataQa="customer-default-billing-address"
          placeholder="Select default billing address"
        />
        <AddressAutocomplete
          label="Default Shipping Address"
          value={selectedShipToAddress}
          addresses={activeCustomer.addresses}
          permissions={editPermission}
          onChange={handleDefaultAddress('defaultShipTo')}
          modalApplyClicked={handleAddressApply('defaultShipTo')}
          dataQa="customer-default-shipping-address"
          placeholder="Select default shipping address"
        />
      </Grid>
      <Grid xs={4}>
        <Typography style={{ marginBottom: '16px' }} variant="h5">
          Shipping
        </Typography>
        <Autocomplete
          label="Carrier"
          name="carrierId"
          value={selectedCarrier}
          permissions={editPermission}
          placeholder="Select carrier"
          options={carriers}
          getOptionLabel={(option: Carrier) => option.name || 'Unknown Carrier'}
          onChange={handleCarrierChange}
          autoSelect={false}
          dataQa="customer-carrier"
        />
        <Autocomplete
          label="Service"
          name="service"
          value={selectedService}
          permissions={editPermission}
          placeholder="Select service"
          options={selectedCarrier ? selectedCarrier.carrierServiceList : []}
          getOptionLabel={(option: CarrierService) =>
            option.name || 'Uknown service'
          }
          onChange={handleServiceChange}
          autoSelect={false}
          dataQa="customer-service"
        />
        <Autocomplete
          label="Order Priority"
          name="orderPriority"
          value={selectedOrderPriority}
          placeholder="Select priority"
          options={orderPriorities}
          permissions={editPermission}
          getOptionLabel={autocompleteLabelResolver}
          onChange={handleAutocompleteChange('defaultOrderPriorityId')}
          autoSelect={false}
          dataQa="customer-order-priority"
        />
      </Grid>
      <Grid xs={4}>
        <Typography style={{ marginBottom: '16px' }} variant="h5">
          Accounting
        </Typography>
        {!isInternationalCustomer && (
          <>
            <FormControlLabel
              className="redesign"
              style={{ marginBottom: '16px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={activeCustomer.taxExempt}
                  name="taxExempt"
                  onChange={handleTaxExemptChange}
                  color="primary"
                  onKeyPress={handleEnterPress}
                />
              }
              label="Tax Exempt"
            />
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Tax Exempt Number"
              placeholder="Enter tax exempt number"
              name="taxExemptNumber"
              permissions={editPermission}
              disabled={!activeCustomer.taxExempt}
              value={activeCustomer.taxExemptNumber}
              onChange={handleTextFieldChange}
              dataQa="customerGeneralTab-taxExemptNumber-field"
            />
          </>
        )}
        <TaxRatesAutocomplete
          label="Sales Tax"
          placeholder="Choose Tax"
          taxRateVariant={TaxRateVariants.Percentage}
          value={activeCustomer.taxId}
          onChange={handleTaxRateChange}
          disabled={activeCustomer.taxExempt}
          dataQa="customerGeneralTab-salesTax-field"
          channelTaxType={ChannelTaxType.Sales}
        />
        <ClassAutocomplete
          label="Class"
          placeholder="Select Class"
          value={selectedClass}
          onChange={handleClassChange}
          permissions={editPermission}
          dataQa="customerGeneralTab-classField"
        />
      </Grid>
      <Grid xs={12}>
        <Divider />
      </Grid>
      {useMultiCurrencyEnabled && (
        <Grid xs={4}>
          <Typography style={{ marginBottom: '36px' }} variant="h5">
            Currency
          </Typography>
          <CurrencyAutocomplete
            value={activeCustomer.currency}
            label="Currency"
            placeholder="Currency"
            onChange={handleCurrencyChange}
            disabled={activeCustomer.hasSalesOrders}
            dataQa="customer-currency"
          />
          <TextField
            className="redesign"
            variant="standard"
            type="number"
            label="Rate"
            placeholder="Enter rate"
            name="rate"
            fullWidth
            value={getExchangeRate(activeCustomer)}
            onChange={handleExchangeRateChange}
            disableDebounce={true}
            dataQa="customerGeneralTab-rateField"
          />
          <FBOButton
            variant="secondary"
            color="neutral"
            size="medium"
            disabled={
              _.get(activeCustomer, 'customExchangeRate', null) === null
            }
            onClick={resetRate}
            data-qa="reset-button"
          >
            Reset
          </FBOButton>
        </Grid>
      )}
      <Grid xs={useMultiCurrencyEnabled ? 4 : 6}>
        <Typography style={{ marginBottom: '16px' }} variant="h5">
          Parent Customer
        </Typography>
        {selectedParentCustomer && (
          <FormControlLabel
            value="billWithParent"
            name="billWithParent"
            color="neutral"
            control={
              <Checkbox
                className={'redesign'}
                color="primary"
                inputProps={
                  {
                    'data-qa': 'bill-with-parent-checkbox',
                  } as any
                }
                checked={Boolean(activeCustomer?.billWithParent)}
                onChange={handleCheckboxChange}
              />
            }
            label={<Typography>Bill With Parent</Typography>}
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              display: 'flex',
              marginTop: '-25px',
            }}
          />
        )}
        <CustomersAutocomplete
          onChange={(v: Customer | null) =>
            handleAutocompleteChange('parentCustomerId')({}, v)
          }
          value={selectedParentCustomer}
          label="Parent Customer"
          placeholder="Select parent customers"
          permissions={editPermission}
          dataQa="customer-parent-customer"
          idsToRemove={activeCustomer.id ? [activeCustomer.id] : []}
        />

        <PhoneInputField
          label="Phone"
          placeholder="Enter phone number"
          name="name"
          fullWidth
          value={selectedParentCustomer && selectedParentCustomer.office}
          disabled
          data-qa="customer-parent-phone"
        />
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="Email"
          name="name"
          placeholder="Enter email address"
          autoComplete="off"
          fullWidth
          onChange={handleTextFieldChange}
          value={selectedParentCustomer && selectedParentCustomer.email}
          disabled
          dataQa="customer-parent-email"
        />
      </Grid>
      <Grid xs={useMultiCurrencyEnabled ? 4 : 6}>
        <Typography style={{ marginBottom: '16px' }} variant="h5">
          Miscellaneous
        </Typography>
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="URL"
          placeholder="Enter URL"
          name="url"
          autoComplete="nope"
          fullWidth
          permissions={editPermission}
          value={activeCustomer.url}
          onChange={handleTextFieldChange}
          dataQa="customer-url"
          error={!!validationErrors.url}
          InputProps={{
            endAdornment: (
              <>
                {activeCustomer.url && (
                  <FBOButton
                    variant="tertiary"
                    color="neutral"
                    size="medium"
                    icon={IconNames.IconLinkHorizontal}
                    onClick={() => handleUrlLinkClicked(activeCustomer.url)}
                    data-qa="general-tab-url-horizontal-link-button"
                  />
                )}
              </>
            ),
          }}
        />
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="Notes"
          placeholder="Enter notes"
          name="notes"
          multiline
          rows={4}
          fullWidth
          permissions={editPermission}
          value={activeCustomer.notes}
          onChange={handleTextFieldChange}
          dataQa="customer-notes"
        />
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="Alert Notes"
          placeholder="Enter alert notes"
          name="alertNotes"
          multiline
          rows={4}
          fullWidth
          permissions={editPermission}
          value={activeCustomer.alertNotes}
          onChange={handleTextFieldChange}
          dataQa="customer-alert-notes"
        />
        <TagsAsyncAutocomplete
          label="Tags"
          permissions={editPermission}
          value={activeCustomer.tags}
          onChange={handleTagsChange}
          dataQa="customer-tag"
        />
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="Other"
          placeholder="Other"
          name="other"
          fullWidth
          permissions={editPermission}
          value={activeCustomer.other}
          onChange={handleTextFieldChange}
          dataQa="customer-other"
        />
      </Grid>
      <Grid container xs={12}>
        <FBOCustomFields
          customFields={activeCustomer.customFields}
          onFieldChange={customFieldChanged}
          errors={customFieldsErrors}
          permissions={editPermission}
        />
      </Grid>
    </Grid>
  );
};

export default memo(GeneralTab);

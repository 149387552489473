import React, { useEffect, createRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { Dates, getErrorMessage, useGetIntlDateFormatString } from 'helpers';
import { fetchSearch, getSearches } from 'services/items/redux';
import { transformItem, Item, deleteItem, deleteItems } from 'services/items';
import { fetchLocations } from 'services/locations';
import { useUrlQueryObject } from 'services/url';
import { fetchVendors } from 'services/vendors';
import { fetchUoms } from 'services/uoms';
import { fetchTaxRates } from 'services/taxRates';
import { Pagination } from 'services/search';
import { fetchTags, fetchTagsAPI, Tag } from 'services/tags';
import { ObjectType } from 'services/customFields';
import { fetchTrackingTypes } from 'services/settings/tracking';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import { showProgressAlert } from 'services/alert/redux';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { CustomFieldsModal } from 'ui/components/CustomFields/CustomFieldsModal';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  removeModuleNavigation,
} from 'services/moduleNavigation';
import { fetchSettingsCompanies } from 'services/settings/company';
import { fetchSettingsSalesOrders } from 'services/settings/salesOrders';
import { fetchSettingsPurchaseOrders } from 'services/settings/purchaseOrders';

import { Routes } from '../../navigation';
import {
  ItemSearchResults,
  ItemDetailsCard,
  ItemsAdvancedSearch,
  ITEM_COLUMNS,
} from './components';
import {
  initialItemsFormValues,
  advancedSearchReduxActions,
  initialPagination,
  createDisplayValueMap,
  displayNameMap,
} from './consts';
import { ItemsPageProps, ItemsPageCmp } from './types';
import {
  ItemPageAction,
  ItemPageRowAction,
} from './components/ItemSearchResults/consts';
import {
  ItemTab,
  ItemUrlQueryObject,
} from './components/ItemDetailsCard/types';
import { handleItemActiveTab } from './components/ItemDetailsCard/helpers';
import { logErrorCtx } from 'app/logging';
import { ErrorBoundary } from '@datadog/rum-react-integration';
import { FallbackUI } from 'ui/components/Modal/ErrorMessage/common';
import { ModuleNavigation } from 'app/components/AppBar/components';
import { fetchUsers } from 'services/user';
import { fetchUsers as fetchUsersV2 } from 'services/userV2';

const ItemsPage: ItemsPageCmp = (props: ItemsPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId,
  } = props;

  const [urlQuery, extendUrlQuery] = useUrlQueryObject<ItemUrlQueryObject>();
  const dispatch = useDispatch();

  const [clone, setClone] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<Item | null>();
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [customFieldsModalVisible, setCustomFieldsModalVisible] =
    useState(false);
  const [searchTags, setSearchTags] = useState<Tag[]>([]);
  const [bypassRouteLeavingGuard, setBypassRouteLeavingGuard] = useState(false);

  const { startFetching, startCsvFetching } = useBackgroundTasks();
  const intlFormatDate = useGetIntlDateFormatString();

  const hiddenItemsInput = createRef<HTMLInputElement>();
  const hiddenItemLocationsInput = createRef<HTMLInputElement>();
  const hiddenItemReorderPointInput = createRef<HTMLInputElement>();
  const hiddenVendorItemInput = createRef<HTMLInputElement>();

  const activeItem = _.find(searchResult.items, { id: activeItemId });

  useEffect(() => {
    handleItemActiveTab(activeItem, urlQuery, extendUrlQuery);
  }, [activeItem, urlQuery, extendUrlQuery]);

  // watch advanced search columns and fetch selected tags
  useEffect(() => {
    const tagIds = _.get(
      searchResult.advancedSearch.columns,
      ['tags.id'],
      null
    ) as number[];

    if (tagIds) {
      (async () => {
        try {
          const resTags = await fetchTagsAPI({}, tagIds);

          setSearchTags(resTags.data);
        } catch {
          //ignore Error
        }
      })();
    }
  }, [searchResult.advancedSearch.columns]);

  useEffect(() => {
    dispatch(clearModuleNavigation(ModuleNavigationType.Materials));

    return () => {
      dispatch(removeModuleNavigation());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportData = useCallback(
    async (type: BackgroundType) => {
      showDownloadNotification();
      try {
        await startBackgroundExport(type);
        startFetching();
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error while starting background export', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description: 'Export while exporting in itemsPage',
          component: 'ItemsPage',
        });
      }
    },
    [startFetching]
  );

  const importItems = useCallback(() => {
    hiddenItemsInput.current!.click();
  }, [hiddenItemsInput]);

  const importItemLocations = useCallback(() => {
    hiddenItemLocationsInput.current!.click();
  }, [hiddenItemLocationsInput]);

  const importReorderPoint = useCallback(() => {
    hiddenItemReorderPointInput.current!.click();
  }, [hiddenItemReorderPointInput]);

  const importVendorItems = useCallback(() => {
    hiddenVendorItemInput.current!.click();
  }, [hiddenVendorItemInput]);

  const handleHiddenInput = useCallback(
    (type: BackgroundType) =>
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
          return;
        }

        let notificationText = '';

        switch (type) {
          case BackgroundType.Item:
            notificationText = 'Item';
            break;
          case BackgroundType.ItemLocation:
            notificationText = 'Item Location';
            break;
          case BackgroundType.ReorderPoint:
            notificationText = 'Reorder Point';
            break;
          case BackgroundType.VendorItem:
            notificationText = 'Vendor Item';
            break;
        }

        const uploadedFile = event.target.files[0];
        showLoadingNotification(
          `Your ${notificationText} import has been initiated.`
        );

        startCsvFetching();

        event.target.value = '';
        try {
          await startBackgroundImport(type, uploadedFile);
          dispatch(
            showProgressAlert(
              'Your Import to Items has been initiated. This may take a few minutes to complete.',
              type,
              BackgroundAction.Import
            )
          );
        } catch (e) {
          const error = e as Error;
          const message = getErrorMessage(error);
          logErrorCtx('Error in startBackgroundImport', {
            error,
            stackTrace: error.stack,
            title: 'Background Import Failed',
            description: message,
            component: 'ItemsPage',
          });
          return;
        }
      },
    [dispatch]
  );

  const handleCloseItemInfoCard = () =>
    extendUrlQuery({ activeId: null, activeTab: null });

  const onAddNewClick = () => {
    extendUrlQuery({ activeId: -1, activeTab: ItemTab.General });
  };

  const handlePaginationChange = (newPagination: Pagination) => {
    fetchSearchResult({ pagination: newPagination });
  };

  const handleItemClick = useCallback(
    (id: number | null) => {
      setClone(false);
      extendUrlQuery({
        activeId: id,
        activeTab: urlQuery.activeTab || ItemTab.General,
      });
    },
    [extendUrlQuery, urlQuery.activeTab]
  );

  const handleItemPageAction = useCallback(
    (action: ItemPageAction, value?: Dates) => {
      switch (action) {
        case ItemPageAction.ExportItems:
          exportData(BackgroundType.Item);
          break;
        case ItemPageAction.ImportItems:
          importItems();
          break;
        case ItemPageAction.ImportItemLocations:
          importItemLocations();
          break;
        case ItemPageAction.ExportItemLocations:
          exportData(BackgroundType.ItemLocation);
          break;
        case ItemPageAction.ChangeDate:
          setActiveDate(value!);
          break;
        case ItemPageAction.CustomFields:
          setCustomFieldsModalVisible(true);
          break;
        case ItemPageAction.ImportReorderPoint:
          importReorderPoint();
          break;
        case ItemPageAction.ExportReorderPoint:
          exportData(BackgroundType.ReorderPoint);
          break;
        case ItemPageAction.ImportVendorItems:
          importVendorItems();
          break;
        case ItemPageAction.ExportVendorItems:
          exportData(BackgroundType.VendorItem);
          break;
      }
    },
    [
      importItems,
      exportData,
      importItemLocations,
      importReorderPoint,
      importVendorItems,
    ]
  );

  const handleAction = useCallback(
    async (type: ItemPageRowAction, value: any) => {
      switch (type) {
        case ItemPageRowAction.Duplicate: {
          setClone(true);
          extendUrlQuery({
            activeId: value.id,
            activeTab: ItemTab.General,
          });
          break;
        }
        case ItemPageRowAction.Delete: {
          setItemToDelete(value);
          break;
        }
      }
    },
    [extendUrlQuery]
  );

  const handleDeleteSingle = useCallback(async () => {
    setDeleteModalLoading(true);
    try {
      await deleteItem(itemToDelete!);
      await fetchSearchResult();
      setItemToDelete(null);
    } catch {
      // Ignore error
    }
    setDeleteModalLoading(false);
  }, [itemToDelete, fetchSearchResult]);

  const hideDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const onDeleteClicked = useCallback(() => setDeleteModalVisible(true), []);

  const handleDeleteConfirm = useCallback(async () => {
    setDeleteModalLoading(true);
    const itemsToDelete = searchResult.items.filter((i) =>
      selectedItems.includes(i.id!)
    );
    try {
      await deleteItems(itemsToDelete);
      await fetchSearchResult();
      setSelectedItems([]);
    } catch {
      // Ignore error
    }

    hideDeleteModal();
    setDeleteModalLoading(false);
  }, [searchResult.items, selectedItems, fetchSearchResult, hideDeleteModal]);

  useEffect(() => {
    setBypassRouteLeavingGuard(false);
  }, [activeItemId]);

  return (
    <ErrorBoundary fallback={FallbackUI}>
      <ModuleNavigation />
      <PageWithAdvancedSearch
        detailCardColumns={ITEM_COLUMNS(activeDate)}
        initialFormValues={initialItemsFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={ItemsAdvancedSearch}
        displayValueMap={createDisplayValueMap(intlFormatDate, searchTags)}
        displayNameMap={displayNameMap}
        pageName="Items"
      >
        <PaperSlidingLayout shown={Boolean(activeItemId)}>
          <ItemSearchResults
            items={searchResult.items}
            columns={ITEM_COLUMNS(activeDate)}
            activeItemId={activeItemId}
            handleItemClick={handleItemClick}
            handleAddNewClick={onAddNewClick}
            isLoadingItems={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onAction={handleAction}
            onDeleteClicked={onDeleteClicked}
            onItemPageAction={handleItemPageAction}
          />
          <ItemDetailsCard
            itemId={activeItemId}
            fetchSearchResult={fetchSearchResult}
            onClose={handleCloseItemInfoCard}
            clone={clone}
            setClone={setClone}
            urlQuery={urlQuery}
            extendUrlQuery={extendUrlQuery}
            bypassRouteLeavingGuard={bypassRouteLeavingGuard}
            setBypassRouteLeavingGuard={setBypassRouteLeavingGuard}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Items"
        body={`This will delete all selected Items and all related sale items. Are you sure?`}
        onCancelClicked={hideDeleteModal}
        onConfirmClicked={handleDeleteConfirm}
        isLoading={deleteModalLoading}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
      {itemToDelete && (
        <ConfirmationModal
          open={true}
          title="Delete Item"
          body={`This will delete ${itemToDelete.name} and  all related sale items, are you sure?`}
          onCancelClicked={() => setItemToDelete(null)}
          onConfirmClicked={handleDeleteSingle}
          isLoading={deleteModalLoading}
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmButtonRed
        />
      )}
      <CustomFieldsModal
        open={customFieldsModalVisible}
        setVisible={setCustomFieldsModalVisible}
        module={ObjectType.Item}
      />
      <input
        type="file"
        ref={hiddenItemsInput}
        hidden
        onChange={handleHiddenInput(BackgroundType.Item)}
        accept=".csv"
      />
      <input
        type="file"
        ref={hiddenItemLocationsInput}
        hidden
        onChange={handleHiddenInput(BackgroundType.ItemLocation)}
        accept=".csv"
      />
      <input
        type="file"
        ref={hiddenItemReorderPointInput}
        hidden
        onChange={handleHiddenInput(BackgroundType.ReorderPoint)}
        accept=".csv"
      />
      <input
        type="file"
        ref={hiddenVendorItemInput}
        hidden
        onChange={handleHiddenInput(BackgroundType.VendorItem)}
        accept=".csv"
      />
    </ErrorBoundary>
  );
};

ItemsPage.route = Routes.ItemsPage;

export default withSearchResults<Item>(ItemsPage, {
  url: '/v1/items',
  expand: 'images,defaultUom,saleItems,bundleItems',
  dataAdapter: transformItem,
  quickSearchColumns: [
    'name',
    'upc',
    'sku',
    'itemType',
    'saleItems.name',
    'description',
  ],
  columns: [],
  fetchSearch,
  getSearches,
  initialPagination,
  rehydrationThunks: [
    fetchVendors,
    fetchUoms,
    fetchLocations,
    fetchTaxRates,
    fetchTrackingTypes,
    fetchSettingsCompanies,
    fetchSettingsSalesOrders,
    fetchSettingsPurchaseOrders,
    fetchTags,
    fetchUsers,
    fetchUsersV2,
  ],
});

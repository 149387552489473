import React from 'react';
import { Switch } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes } from './routes';
import { SandboxPage } from '../pages';
import { SandboxRouterCmp, SandboxRouterProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SandboxRouter: SandboxRouterCmp = (props: SandboxRouterProps) => {
  return (
    <Switch>
      <SecureRoute path={SandboxPage.route} exact component={SandboxPage} />
    </Switch>
  );
};

SandboxRouter.route = Routes.SandboxRouter;

export default SandboxRouter;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const usePickItemCommitDetailsStyle = makeStyles((theme: Theme) => ({
  gridWithPadding: {
    padding: theme.spacing(3),
    flexShrink: 0,
  },
  inventoryTableGrid: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}));

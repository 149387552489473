import { ShippingSignatureConfirmationType } from '.';
import { ShippoReferenceField } from './types';

export const initialShippoSettings = {
  id: null,
  order: null,
};

export enum ShippoNonDeliveryType {
  ABANDON = 'Abandon',
  RETURN = 'Return',
}

export enum ShippoContentsType {
  DOCUMENTS = 'Documents',
  GIFT = 'Gift',
  SAMPLE = 'Sample',
  MERCHANDISE = 'Merchandise',
  HUMANITARIAN_DONATION = 'Humanitarian Donation',
  RETURN_MERCHANDISE = 'Return Merchandise',
  OTHER = 'Other',
}

export enum ShipStationContentsType {
  DOCUMENTS = 'Documents',
  GIFT = 'Gift',
  MERCHANDISE = 'Merchandise',
  RETURNED_GOODS = 'Return Goods',
  SAMPLE = 'Sample',
}

export enum ShippoDefaultIncotermsType {
  DDP = 'DDP',
  DDU = 'DDU',
  FCA = 'FCA',
}

export enum ShippoRecipientType {
  licensee = 'Licensee',
  consumer = 'Consumer',
}

export const shippoSignatureConfirmationType: ShippingSignatureConfirmationType[] =
  [
    {
      key: 'STANDARD',
      name: 'Standard',
    },
    {
      key: 'ADULT',
      name: 'Adult',
    },
    {
      key: 'CERTIFIED',
      name: 'Certified',
    },
    {
      key: 'INDIRECT',
      name: 'Indirect',
    },
    {
      key: 'CARRIER_CONFIRMATION',
      name: 'Carrier Confirmation',
    },
  ];

export const shipStationSignatureConfirmationType: ShippingSignatureConfirmationType[] =
  [
    {
      key: 'delivery',
      name: 'Delivery',
    },
    {
      key: 'signature',
      name: 'Signature',
    },
    {
      key: 'adult_signature',
      name: 'Adult Signature',
    },
    {
      key: 'direct_signature',
      name: 'Direct Signature (Fedex only)',
    },
  ];

export enum ShippoPackagingType {
  STANDARD = 'Standard',
  ADULT = 'Adult',
  CERTIFIED = 'Certified',
  INDIRECT = 'Indirect',
  CARRIER_CONFIRMATION = 'Carrier Confirmation',
}

export enum EEL_PFC {
  NOEEI_30_37_a = 'NOEEI 30 37 a',
  NOEEI_30_37_h = 'NOEEI 30 37 h',
  NOEEI_30_37_f = 'NOEEI 30 37 f',
  NOEEI_30_36 = 'NOEEI 30 36',
  AES_ITN = 'AES ITN',
}

export enum ShippoLabelFileType {
  'PNG' = 'PNG',
  'PNG_2.3x7.5' = 'PNG_2.3x7.5',
  'PDF' = 'PDF',
  'PDF_2.3x7.5' = 'PDF_2.3x7.5',
  'PDF_4x6' = 'PDF_4x6',
  'PDF_4x8' = 'PDF_4x8',
  'PDF_A4' = 'PDF_A4',
  'PDF_A6' = 'PDF_A6',
  'ZPLII' = 'ZPLII',
}

export enum ShippoCodMethodsType {
  SECURED_FUNDS = 'Secured Funds',
  CASH = 'Cash',
  ANY = 'Any',
}

export enum ShippoTaxIdType {
  EIN = 'EIN',
  VAT = 'VAT',
}

export enum ShippoB13FilingOption {
  FIELD_ELECTRONICALLY = 'Filed Electronically',
  SUMMARY_REPORTING = 'Summary Reporting',
  NOT_REQUIRED = 'Not Required',
}

export const shippoReferenceFields: ShippoReferenceField[] = [
  { key: 'salesOrder.id', label: 'Order ID' },
  { key: 'salesOrder.number', label: 'Order Number' },
  { key: 'salesOrder.customer.name', label: 'Customer Name' },
  { key: 'salesOrder.customerPONumber', label: 'Customer PO' },
  { key: 'salesOrder.emails', label: 'Email' },
  { key: 'salesOrder.phone', label: 'Phone' },
  { key: 'salesOrder.notes', label: 'Notes' },
  { key: 'salesOrder.dateCreated', label: 'Date Created' },
  { key: 'salesOrder.dateIssued', label: 'Date Issued' },
  { key: 'salesOrder.dateScheduled', label: 'Date Scheduled' },
  { key: 'salesOrder.dateExpired', label: 'Date Expired' },
  { key: 'salesOrder.shippingTerm', label: 'Shipping Terms' },
  { key: 'salesOrder.paymentTerm.name', label: 'Payment Terms' },
  { key: 'salesOrder.representative.name', label: 'Sales Representative' },
];

import React from 'react';
import { Switch } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { FeedbackPage } from '../pages/FeatureRequestPage';
import { Routes } from './routes';
import { FeedbackRouterCmp } from './types';

const FeedbackRouter: FeedbackRouterCmp = () => {
  return (
    <Switch>
      <SecureRoute exact component={FeedbackPage} path={FeedbackPage.route} />
    </Switch>
  );
};

FeedbackRouter.route = Routes.FeedbackRouter;

export default FeedbackRouter;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Dates } from 'helpers';
import {
  getSearches,
  fetchSearch,
  fetchReceipts,
  transformReceipt,
  Receipt,
  voidReceipts,
} from 'services/receiving';
import { fetchCarriers } from 'services/carriers';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  removeModuleNavigation,
} from 'services/moduleNavigation';
import { Routes } from 'ui/modules/purchasing/navigation';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { useUrlQueryObject } from 'services/url';
import { fetchLocations, getLocations } from 'services/locations';
import { fetchUoms } from 'services/uoms';
import { fetchCustomers, getCustomers } from 'services/customers';
import { Pagination } from 'services/search';
import { fetchVendors, getVendors } from 'services/vendors';
import { fetchSettingsPurchaseOrders } from 'services/settings/purchaseOrders';
import { fetchItem, Item } from 'services/items';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import { ReceivingPageCmp, ReceivingPageProps } from './types';
import {
  initialPagination,
  initialReceivingFormValues,
  advancedSearchReduxActions,
  displayNameMap,
  createDisplayValueMap,
} from './consts';
import {
  ReceivingAdvancedSearch,
  ReceivingSearchResults,
  RECEIVING_COLUMNS,
  ReceivingDetailsCard,
} from './components';
import { ReceivingPageAction } from './components/ReceivingSearchResults/consts';
import { ModuleNavigation } from 'app/components/AppBar/components';

const ReceivingPage: ReceivingPageCmp = (props: ReceivingPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeReceiptId,
  } = props;

  const dispatch = useDispatch();

  const [, setNextSalesOrdersNumber] = useState<number>(-1);
  const [searchItem, setSearchItem] = useState<Item | null>(null);
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateScheduled);
  const [voidModalVisible, setVoidModalVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [voidLoadingVisible, setVoidLoadingVisible] = useState(false);

  const { items: locations } = useSelector(getLocations);
  const { items: customers } = useSelector(getCustomers);
  const { items: vendors } = useSelector(getVendors);

  const [, extendUrlQuery] = useUrlQueryObject();

  useEffect(() => {
    dispatch(clearModuleNavigation(ModuleNavigationType.Purchase));

    return () => {
      dispatch(removeModuleNavigation());
    };
  }, [dispatch]);

  // watch advanced search columns and fetch selected item
  useEffect(() => {
    const itemId = _.get(
      searchResult.advancedSearch.columns,
      'receiptItems.itemId',
      null
    );

    if (itemId) {
      (async () => {
        try {
          const resItem = await fetchItem(itemId as number);
          setSearchItem(resItem);
        } catch {
          // nothing
        }
      })();
    }
  }, [searchResult.advancedSearch.columns]);

  const handleActiveReceiptClose = useCallback(
    () => extendUrlQuery({ activeId: null }),
    [extendUrlQuery]
  );

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  const getNextSalesOrdersNumber = useCallback(async () => {
    setNextSalesOrdersNumber(1);
  }, []);

  const handleAddNewPress = useCallback(async () => {
    await getNextSalesOrdersNumber();
    extendUrlQuery({ activeId: -1 });
  }, [extendUrlQuery, getNextSalesOrdersNumber]);

  const receiptClicked = useCallback(
    (receiptId: number) => {
      extendUrlQuery({ activeId: receiptId });
    },
    [extendUrlQuery]
  );

  const hideVoidModal = useCallback(() => {
    setVoidModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const onReceiveClicked = useCallback(() => setVoidModalVisible(true), []);

  const handleVoidReceiptsConfirm = useCallback(async () => {
    try {
      setVoidLoadingVisible(true);
      await voidReceipts(selectedItems);
      await fetchSearchResult();
      setVoidLoadingVisible(false);
      hideVoidModal();
    } catch {
      // Ignore error
    }
  }, [selectedItems, hideVoidModal, fetchSearchResult]);

  const handlePageAction = useCallback(
    (action: ReceivingPageAction, date: Dates) => {
      switch (action) {
        case ReceivingPageAction.ChangeDate:
          setActiveDate(date);
          break;
      }
    },
    []
  );

  return (
    <>
      <ModuleNavigation />
      <PageWithAdvancedSearch
        detailCardColumns={RECEIVING_COLUMNS(activeDate)}
        initialFormValues={initialReceivingFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(
          locations,
          customers,
          vendors,
          searchItem
        )}
        AdvancedSearchFieldsCmp={ReceivingAdvancedSearch}
        showAllLabel="Show All Receipts"
        pageName="Receiving"
      >
        <PaperSlidingLayout shown={Boolean(activeReceiptId)}>
          <ReceivingSearchResults
            receipts={searchResult.items}
            activeReceiptId={activeReceiptId}
            handleReceiptClick={receiptClicked}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            onAddNewPress={handleAddNewPress}
            onPageAction={handlePageAction}
            activeDate={activeDate}
            isLoadingReceipts={isLoadingSearchResult}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onReceiveClicked={onReceiveClicked}
          />
          <ReceivingDetailsCard
            activeReceiptId={activeReceiptId}
            fetchSearchResult={fetchSearchResult}
            onClose={handleActiveReceiptClose}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <ConfirmationModal
        open={voidModalVisible}
        title="Void Receipts"
        body={`This will void all receipts, are you sure?`}
        onCancelClicked={hideVoidModal}
        onConfirmClicked={handleVoidReceiptsConfirm}
        confirmLabel="Void"
        cancelLabel="Cancel"
        isLoading={voidLoadingVisible}
      />
    </>
  );
};

ReceivingPage.route = Routes.ReceivingPage;

export default withSearchResults<Receipt>(ReceivingPage, {
  url: '/v1/receipts?expand=salesOrder.customer,purchaseOrder.vendor',
  expand: '',
  dataAdapter: transformReceipt,
  columns: RECEIVING_COLUMNS(Dates.DateScheduled),
  getSearches,
  fetchSearch,
  initialPagination,
  rehydrationThunks: [
    fetchCarriers,
    fetchLocations,
    fetchUoms,
    fetchCustomers,
    fetchVendors,
    fetchReceipts,
    fetchSettingsPurchaseOrders,
  ],
});

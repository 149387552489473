import React from 'react';
import { Switch } from 'react-router-dom';

import { Routes } from './routes';
import { CommerceRouterCmp } from './types';
import { DiscoverChannels } from '../pages/DiscoverChannels';
import { MyChannels } from '../pages/MyChannelsPage';
import { Settings } from '../pages/Settings';
import { SecureRoute } from 'ui/components/Route';
import { isTestEnv } from 'services/api/testEnv';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CommerceRouter: CommerceRouterCmp = () => {
  return (
    <Switch>
      <SecureRoute
        path={DiscoverChannels.route}
        exact
        component={DiscoverChannels}
        redirectToHome={isTestEnv()}
      />
      <SecureRoute
        path={MyChannels.route}
        exact
        component={MyChannels}
        redirectToHome={isTestEnv()}
      />
      <SecureRoute
        path={Settings.route}
        exact
        component={Settings}
        redirectToHome={isTestEnv()}
      />
    </Switch>
  );
};

CommerceRouter.route = Routes.CommerceRouter;

export default CommerceRouter;

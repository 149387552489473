import React, { memo, useMemo } from 'react';
import {
  Box,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';

import { TextField } from 'ui/components/TextField/TextField';
import Autocomplete from 'ui/components/Autocomplete/Autocomplete/Autocomplete';
import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { WeightUnit, WEIGHT_UNITS } from 'services/items';
import {
  ShippingSignatureConfirmationType,
  ShippoCodMethodsType,
  ShippoRecipientType,
  shippoSignatureConfirmationType,
  shipStationSignatureConfirmationType,
} from 'services/integrations/shipping/shippo';

import { ShipTabPropsWithErrors } from './types';
import { useCardRowStyle } from '../styled';

const ShippingExtrasTab = (props: ShipTabPropsWithErrors) => {
  const { shippingQuote, setShippingQuote, shippoConnected, errors } = props;

  const classes = useCardRowStyle();

  const selectedWeightUnit = useMemo(() => {
    return WEIGHT_UNITS.find((u) => u.name === shippingQuote.uom) || null;
  }, [shippingQuote.uom]);

  const handleCheckboxChange = useHandleCheckboxChange(setShippingQuote);

  const handleTextFieldChange = useHandleTextFieldChange(
    setShippingQuote,
    shippingQuote
  );

  const handleAutoCompleteChange =
    (name: string) => (e: any, value: string | null) => {
      setShippingQuote((old) => ({ ...old, [name]: value }));
    };

  const filteredSignatureTypes = useMemo(() => {
    return shippoConnected
      ? shippoSignatureConfirmationType
      : shipStationSignatureConfirmationType;
  }, [shippoConnected]);

  const selectedSignatureType = useMemo(() => {
    if (shippoConnected) {
      return (
        shippoSignatureConfirmationType.find(
          (s) => s.key === shippingQuote.signature
        ) || null
      );
    }

    return (
      shipStationSignatureConfirmationType.find(
        (s) => s.key === shippingQuote.signature
      ) || null
    );
  }, [shippingQuote.signature, shippoConnected]);

  return (
    <Box p={4} className={classes.tabPanelBox}>
      <Grid container spacing={2}>
        {shippoConnected && (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  className="redesign"
                  color="primary"
                  checked={shippingQuote.useRetailerRates}
                  name="useRetailerRates"
                  onChange={handleCheckboxChange}
                />
              }
              label="Use Retail Rates"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                color="primary"
                checked={shippingQuote.saturdayDelivery}
                name="saturdayDelivery"
                onChange={handleCheckboxChange}
              />
            }
            label="Saturday Delivery"
          />
        </Grid>
        {shippoConnected && (
          <React.Fragment>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    color="primary"
                    checked={shippingQuote.containsDryIce}
                    name="containsDryIce"
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <>
                    <Typography>Contains Dry Ice</Typography>
                    <Typography variant="caption">
                      UPS and FedEx only
                    </Typography>
                  </>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                type="number"
                label="Dry Ice Weight"
                placeholder="Enter weight"
                name="dryIceWeight"
                value={shippingQuote.dryIceWeight}
                onChange={handleTextFieldChange}
                disabled={!shippingQuote.containsDryIce}
                error={!!errors.dryIceWeight}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                options={WEIGHT_UNITS}
                getOptionLabel={(u: WeightUnit) => u.name}
                value={selectedWeightUnit}
                onChange={(e: any, v: any) =>
                  handleAutoCompleteChange('uom')(e, v ? v.name : null)
                }
                disabled={!shippingQuote.containsDryIce}
                error={!!errors.uom}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                color="primary"
                checked={shippingQuote.containsAlcohol}
                name="containsAlcohol"
                onChange={handleCheckboxChange}
              />
            }
            label={
              <>
                <Typography>Contains Alcohol</Typography>
                <Typography variant="caption">UPS and FedEx only</Typography>
              </>
            }
          />
        </Grid>
        {shippoConnected && (
          <Grid item xs={8}>
            <Autocomplete
              options={Object.values(ShippoRecipientType)}
              onChange={handleAutoCompleteChange('recipientType')}
              value={shippingQuote.recipientType}
              disabled={!shippingQuote.containsAlcohol}
              error={!!errors.recipientType}
              label="Recipient Type"
              placeholder="Select type"
            />
          </Grid>
        )}
        {shippoConnected && (
          <React.Fragment>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    color="primary"
                    checked={shippingQuote.cod}
                    name="cod"
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <>
                    <Typography>COD</Typography>
                    <Typography variant="caption">
                      UPS and FedEx only
                    </Typography>
                  </>
                }
              />
            </Grid>

            <Grid item xs={4}>
              <Autocomplete
                onChange={handleAutoCompleteChange('codMethod')}
                value={shippingQuote.codMethod}
                label="Method"
                placeholder="Select method"
                options={Object.values(ShippoCodMethodsType)}
                disabled={!shippingQuote.cod}
                error={!!errors.codMethod}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className="redesign"
                variant="standard"
                type="number"
                label="Amount"
                placeholder="Enter amount"
                name="codAmount"
                value={shippingQuote.codAmount}
                onChange={handleTextFieldChange}
                error={!!errors.codAmount}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className="redesign"
                variant="standard"
                value={shippingQuote.codCurrency}
                label="Currency"
                disabled
                error={!!errors.codCurrency}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                color="primary"
                checked={shippingQuote.signatureConfirmation}
                name="signatureConfirmation"
                onChange={handleCheckboxChange}
              />
            }
            label="Signature Confirmation"
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={filteredSignatureTypes}
            onChange={(e: any, v: ShippingSignatureConfirmationType | null) =>
              handleAutoCompleteChange('signature')(e, v ? v.key : null)
            }
            value={selectedSignatureType}
            label="Signature"
            placeholder="Select type"
            disabled={!shippingQuote.signatureConfirmation}
            getOptionLabel={(s: ShippingSignatureConfirmationType) => s.name}
            error={!!errors.signature}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(ShippingExtrasTab);

import React, { memo, useContext, useEffect } from 'react';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { disableTestEnv } from 'services/api/testEnv';
import { sideDrawerStatus } from '../FBODrawer';
import { Icon } from 'ui/components/Icon';

const LeaveSandbox = React.forwardRef((_props, ref) => {
  const history = useHistory();
  const { drawerExpanded } = useContext(sideDrawerStatus);

  const leaveSandbox = () => {
    disableTestEnv();

    history.replace(history.location.pathname);
    history.go(0);
  };
  useEffect(() => {}, [drawerExpanded]);

  return (
    <>
      {drawerExpanded && (
        <Button
          ref={ref as React.RefObject<HTMLButtonElement>}
          onClick={leaveSandbox}
          className="leave"
          disableRipple
        >
          <Icon name="Leave" height={13.33} width={13.33} />
          Leave Database
        </Button>
      )}
    </>
  );
});

export default memo(LeaveSandbox);

import React, { memo, useCallback, useContext, useState } from 'react';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { generalSettingsValidation } from 'services/integrations/shipping/shippo/validations';
import { validateYup } from 'services/forms/validation';
import CardWizard from 'ui/components/Wizard/CardWizard';

import { ShipStationWizardProps } from './types';

import { ShipStationContext } from '../../../../ShipStationProvider';
import { useShippingWizardStyle } from '../../../../styled';
import { GeneralSettingsForm } from '../GeneralSettingsForm';
import { ShippingExtrasSettingsForm } from '../ShippingExtrasSettingsForm';
import { InternationalSettingsForm } from '../InternationalSettingsForm';
import { ShipStationLogoSmall } from 'ui/theme';

const steps = (classes: any = {}, activeStep: number = 0) => [
  {
    label: 'General settings',
    icon:
      activeStep >= 1 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  },
  {
    label: 'International settings',
    icon:
      activeStep >= 2 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  },
  {
    label: 'Shipping Extras',
    icon: <RadioButtonUncheckedOutlinedIcon />,
  },
];

const ShipStationWizard: React.FC<ShipStationWizardProps> = () => {
  const classes = useShippingWizardStyle();

  const [activeStep, setActiveStep] = useState(0);

  const { settings, setErrors, saveSettings, isLoading } =
    useContext(ShipStationContext);

  const handleNext = async () => {
    //validation only for general settings
    if (
      activeStep === 0 &&
      !validateYup(settings, generalSettingsValidation, setErrors)
    ) {
      return;
    }

    // if last step
    if (activeStep === steps().length - 1) {
      saveSettings();

      return;
    }

    setActiveStep(activeStep + 1);
  };

  const handlePrevious = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  return (
    <CardWizard
      steps={steps(classes, activeStep)}
      title="Configure ShipStation"
      activeStep={activeStep}
      onNext={handleNext}
      onPrevious={handlePrevious}
      isLoading={isLoading}
      status="Beta"
      imageUrl={ShipStationLogoSmall}
    >
      {[
        <GeneralSettingsForm key={0} />,
        <InternationalSettingsForm key={1} />,
        <ShippingExtrasSettingsForm key={2} />,
      ]}
    </CardWizard>
  );
};

export default memo(ShipStationWizard);

/* eslint-disable */
import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Routes } from 'ui/modules/remotesupport/navigation/routes';
import {
  clearAdminAccessToken,
  getAdminAccessToken,
} from 'services/auth/services';

import FBOButton from '../FBOButton/FBOButton';

export type FBOAppBarCmp = React.FunctionComponent<FBOAppBarComponentProps>;

export interface FBOAppBarComponentProps {}

const FBOAppBar: FBOAppBarCmp = () => {
  const history = useHistory();

  const hasAdminAccessToken = useMemo(() => !!getAdminAccessToken(), []);

  const logOutRemoteAccess = useCallback(() => {
    clearAdminAccessToken();
    history.push(Routes.RemoteSupportPage);
    history.go(0);
  }, [history]);

  return (
    <Box data-qa="fbo-app-bar">
      {hasAdminAccessToken ? (
        <FBOButton
          variant="primary"
          color="negative"
          size="large"
          onClick={() => logOutRemoteAccess()}
          data-qa="remote-user-log-out"
          fullWidth
          style={{ marginBottom: '16px' }}
        >
          Log Out of Remote User
        </FBOButton>
      ) : null}
    </Box>
  );
};

export default FBOAppBar;

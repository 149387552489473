import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { getItemQuantity, ItemQuantityRow } from 'services/inventory';

import { Modal } from '../Modal';
import {
  ITEMS_QUANTITY_COLUMNS,
  ITEMS_QUANTITY_COLUMNS_ALLOCATION,
  ITEMS_QUANTITY_COLUMNS_COMMITTED,
} from './consts';
import { InventoryHeroModalProps } from './types';
import InventoryHeroRow from './InventoryHeroRow/InventoryHeroRow';
import { OrderType } from './InventoryHeroRow/consts';

const InventoryHeroModal: React.FC<InventoryHeroModalProps> = (props) => {
  const { isOpen, handleClose, item, quantityType, title } = props;

  const history = useHistory();

  const UOMabbreviation = _.get(item, 'defaultUom.abbreviation');
  const [itemQuantity, setItemQuantity] = useState<ItemQuantityRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isOpen || !item || !quantityType) {
      return;
    }
    (async () => {
      setIsLoading(true);
      try {
        const res = await getItemQuantity(item.id, quantityType);
        setItemQuantity(res as ItemQuantityRow[]);
      } catch (error) {
        // ignore error
      }
      setIsLoading(false);
    })();
  }, [isOpen, item, quantityType]);

  const handleItemClick = (id: number) => {
    let link = `/sales/sales-order?activeId=${id}`;
    const orderMatch = itemQuantity.find((item) => item.id === id);
    if (orderMatch && orderMatch.orderType === OrderType.PurchaseOrder) {
      link = `/purchasing/purchase-order?activeId=${id}`;
    }
    history.push(link);
  };
  const getQuantityColumns = (quantityType: string) => {
    switch (quantityType) {
      case 'allocated':
        return ITEMS_QUANTITY_COLUMNS_ALLOCATION;
      case 'committed':
        return ITEMS_QUANTITY_COLUMNS_COMMITTED;
      default:
        return ITEMS_QUANTITY_COLUMNS;
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancelClicked={handleClose}
        withoutDefaultPadding
        title={title}
      >
        <ItemsTable
          data={itemQuantity}
          onItemClick={handleItemClick}
          RenderCustomRow={InventoryHeroRow}
          columns={getQuantityColumns(quantityType || '')}
          emptyTableText={'NO DATA TO DISPLAY'}
          isLoadingData={isLoading}
          selectableItems={false}
          meta={[UOMabbreviation, quantityType]}
        />
      </Modal>
    </>
  );
};

export default InventoryHeroModal;

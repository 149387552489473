import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  memo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import {
  defaultUom,
  Uom,
  fetchUom,
  postUom,
  putUom,
  deleteUom,
  fetchUoms,
  restoreUom,
} from 'services/uoms';
import { useUrlQueryObject } from 'services/url';
import { useHandleTextFieldChange } from 'services/forms';
import { UomConversion, defaultUomConversion } from 'services/uoms/conversions';
import { activeUserHasPermission } from 'services/user/redux';
import { Errors, validateYup } from 'services/forms/validation';

import { UomDetailsCardProps, UomDetailsCardCmp } from './types';
import { uomSchema } from './validations';
import { UomTitleBar } from '../UomTitleBar';
import { editUomPermissions } from '../helpers';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';
import { FBOUomConversionListItem } from './FBOUomConversionListItem';

const FBOUomDetailsCard: UomDetailsCardCmp = (props: UomDetailsCardProps) => {
  const { activeUomId, fetchSearchResult, onClose } = props;

  const dispatch = useDispatch();

  const [, setQueryParams] = useUrlQueryObject();

  const [activeUom, setActiveUom] = useState<Uom>(defaultUom);
  const [errors, setErrors] = useState<Errors>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConversionInfo, setDeleteConversionInfo] = useState<{
    fromName: string;
    toName: string;
    index: number;
    isFromConversion: boolean;
  } | null>(null);

  const oldState = useRef<Uom | null>(defaultUom);
  const firstInputElement = useRef<HTMLInputElement>(null);

  const editPermission = editUomPermissions(activeUom);

  const canClick = useSelector(activeUserHasPermission(editPermission));

  const usedUomIds = useMemo(() => {
    const usedToIds = activeUom.toConversions
      .filter((c) => !!c.toUomId && !c.deleted)
      .map((c) => c.toUomId!);
    const usedFromIds = activeUom.fromConversions.map((c) => c.fromUomId!);
    return [...usedToIds, ...usedFromIds, activeUom.id!];
  }, [activeUom.fromConversions, activeUom.id, activeUom.toConversions]);

  useEffect(() => {
    if (activeUom.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }
  }, [activeUom.id]);

  useEffect(() => {
    const asyncFc = async () => {
      setIsLoading(true);
      let resUom;
      try {
        resUom = await fetchUom(activeUomId!);
      } catch {
        setIsLoading(false);
        return;
      }
      oldState.current = resUom;
      setActiveUom(resUom);
      setIsLoading(false);
    };

    if (activeUomId && activeUomId > 0) {
      asyncFc();
    } else {
      oldState.current = { ...defaultUom, id: activeUomId };
      setActiveUom({ ...defaultUom, id: activeUomId });
    }
    setErrors({});
  }, [activeUomId]);

  const handleSaveClicked = useCallback(
    (close?: boolean) => async () => {
      const isUomValid = validateYup(activeUom, uomSchema, setErrors);

      if (!isUomValid) {
        return false;
      }

      setIsLoading(true);

      if (activeUom.id === -1) {
        try {
          const newActiveUom = await postUom(activeUom);
          oldState.current = newActiveUom;
          setActiveUom(newActiveUom);
          await fetchSearchResult();
          setQueryParams({ activeId: newActiveUom.id });
        } catch {
          setIsLoading(false);
          return false;
        }
      } else {
        try {
          const newActiveUom = await putUom(activeUom);
          oldState.current = newActiveUom;
          setActiveUom(newActiveUom);
          await fetchSearchResult();
        } catch {
          setIsLoading(false);
          return false;
        }
      }

      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchUoms());
      if (close) {
        onClose();
      }
      setIsLoading(false);
      return true;
    },
    [activeUom, dispatch, fetchSearchResult, onClose, setQueryParams]
  );

  const handleTextInputChanged = useHandleTextFieldChange<Uom>(
    setActiveUom,
    activeUom
  );

  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  const handleDeleteConfirm = async () => {
    try {
      await deleteUom(activeUom.id!);
    } catch {
      return;
    }

    fetchSearchResult();
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchUoms());
    setDeleteModalVisible(false);
    onClose();
  };

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreUom(activeUomId!);
        const restoredUom = await fetchUom(activeUomId!);
        oldState.current = restoredUom;
        setActiveUom(restoredUom);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activeUomId]
  );

  const handleCheckBoxChanged = () => {
    setActiveUom((prevUom) => ({
      ...prevUom,
      defaultFlag: !prevUom.defaultFlag,
    }));
  };

  const handleToConversionEdited = (
    conversion: UomConversion,
    index: number
  ) => {
    const newConversions = [...activeUom.toConversions];
    newConversions[index] = conversion;
    setActiveUom((prevUom) => ({ ...prevUom, toConversions: newConversions }));
  };

  const handleFromConversionEdited = (
    conversion: UomConversion,
    index: number
  ) => {
    const newConversions = [...activeUom.fromConversions];
    newConversions[index] = conversion;
    setActiveUom((prevUom) => ({
      ...prevUom,
      fromConversions: newConversions,
    }));
  };

  const handleNewConversionClicked = () => {
    setActiveUom((prevUom) => ({
      ...prevUom,
      toConversions: [...prevUom.toConversions, defaultUomConversion],
    }));
  };

  const handleDeleteConversionConfirm = () => {
    if (deleteConversionInfo!.isFromConversion) {
      const fromConversion: UomConversion = {
        ...activeUom.fromConversions[deleteConversionInfo!.index],
        deleted: true,
      };
      handleFromConversionEdited(fromConversion, deleteConversionInfo!.index);
      setDeleteConversionInfo(null);
      return;
    }

    const toConversion: UomConversion = {
      ...activeUom.toConversions[deleteConversionInfo!.index],
      deleted: true,
    };
    handleToConversionEdited(toConversion, deleteConversionInfo!.index);
    setDeleteConversionInfo(null);
  };

  const handleDeleteConversionModalHide = () => {
    setDeleteConversionInfo(null);
  };

  const handleDeleteConversion =
    (isFromConversion: boolean) =>
    (fromName: string, toName: string, index: number) => {
      setDeleteConversionInfo({ fromName, toName, index, isFromConversion });
    };

  const renderConversions = (
    conversions: UomConversion[],
    receivedFromConversion: boolean
  ) => {
    const onEditResolvedFnc = receivedFromConversion
      ? handleFromConversionEdited
      : handleToConversionEdited;

    return conversions.map(
      (conversion, index) =>
        !conversion.deleted && (
          <FBOUomConversionListItem
            conversion={conversion}
            activeUomName={activeUom.name}
            index={index}
            activeUomId={activeUomId}
            uomIdsBeingUsed={usedUomIds}
            onEditConversion={onEditResolvedFnc}
            onDeleteConversion={handleDeleteConversion(receivedFromConversion)}
            key={index}
            conversionsError={errors}
          />
        )
    );
  };

  return (
    <DetailsCard
      onSubmit={handleSaveClicked(false)}
      isLoading={isLoading}
      state={activeUom}
      oldState={oldState}
    >
      <UomTitleBar
        activeUom={activeUom}
        onSave={handleSaveClicked()}
        onClose={onClose}
        deleteModalVisible={handleDeleteModalVisible(true)}
        onUndeleteClicked={handleUndeleteClicked}
      />
      <Box p={4} overflow="auto">
        <Grid container spacing={2}>
          <Grid container xs={12} spacing={1}>
            <Grid xs={5}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Name"
                placeholder="Enter name"
                name="name"
                autoComplete="off"
                fullWidth
                permissions={editPermission}
                error={!!errors.name}
                inputRef={firstInputElement}
                value={activeUom.name}
                required
                onChange={handleTextInputChanged}
                dataQa="uom-name"
              />
            </Grid>
            <Grid xs={5}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Abbreviation"
                placeholder="Enter abbreviation"
                name="abbreviation"
                fullWidth
                permissions={editPermission}
                value={activeUom.abbreviation}
                error={!!errors.abbreviation}
                required
                onChange={handleTextInputChanged}
                dataQa="uom-abbreviation"
              />
            </Grid>
            <Grid xs={2} display="flex" alignItems="center">
              <Box ml={1}>
                <FormControlLabel
                  label="Default"
                  name="defaultFlag"
                  disabled={!canClick}
                  control={
                    <Checkbox
                      className="redesign"
                      color="primary"
                      checked={activeUom.defaultFlag}
                      inputProps={
                        {
                          'data-qa': 'uom-default',
                        } as any
                      }
                    />
                  }
                  onChange={handleCheckBoxChanged}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={1}>
            <Grid xs>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Description"
                placeholder="Enter description"
                name="description"
                autoComplete="off"
                fullWidth
                permissions={editPermission}
                value={activeUom.description}
                onChange={handleTextInputChanged}
                dataQa="uom-description"
              />
            </Grid>
          </Grid>

          {/* Conversions */}
          <Grid container xs={12} spacing={1}>
            <Grid xs={12}>
              <Box pt={3}>
                <Typography>
                  <b>Conversions</b>
                </Typography>

                <Grid container xs={12}>
                  <Grid xs={12}>
                    {renderConversions(activeUom.fromConversions, true)}
                    {renderConversions(activeUom.toConversions, false)}
                  </Grid>
                  {canClick && (
                    <Grid xs={12}>
                      <FBOButton
                        variant="tertiary"
                        color="neutral"
                        size="medium"
                        icon={IconNames.FBOAddCircle}
                        onClick={handleNewConversionClicked}
                        data-qa="uom-new-conversion"
                      >
                        Add New Conversion
                      </FBOButton>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Unit of Measure"
        body={`This will delete '${_.get(
          activeUom,
          'name',
          'UOM'
        )}' and all related conversions, are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={handleDeleteConfirm}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
      <ConfirmationModal
        open={Boolean(deleteConversionInfo)}
        title="Delete conversion"
        body={`This will delete conversion for ${
          deleteConversionInfo ? deleteConversionInfo.toName : ''
        }, are you sure?`}
        onCancelClicked={handleDeleteConversionModalHide}
        onConfirmClicked={handleDeleteConversionConfirm}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        confirmButtonRed
      />
    </DetailsCard>
  );
};

export default memo(FBOUomDetailsCard);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import {
  PermissionGroup,
  initialPermissionGroup,
  postPermissionGroup,
  fetchPermissionGroup,
  putPermissionGroup,
  deletePermissionGroup,
  PermissionType,
} from 'services/permissions';
import { Errors, validateYup } from 'services/forms/validation';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { TabPanel } from 'ui/components/TabPanel';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { useUrlQueryObject } from 'services/url';
import {
  PermissionsGroupTab,
  PermissionsTab,
  PermissionsItems,
} from './components';
import { PermissionDetailsCardProps } from './types';
import { permissionGroupYupSchema } from './validations';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const PermissionDetailsCard: React.FC<PermissionDetailsCardProps> = (props) => {
  const { activeId, onClose, fetchSearchResult } = props;
  const [, setQueryParams] = useUrlQueryObject();
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [permissionGroup, setPermissionGroup] = useState<PermissionGroup>(
    initialPermissionGroup
  );
  const [validation, setValidation] = useState<Errors>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const oldState = useRef<PermissionGroup | null>(initialPermissionGroup);

  const canEditPermissions =
    permissionGroup.id && permissionGroup.id > 0
      ? [PermissionType.PermissionEdit]
      : [PermissionType.PermissionCreate];

  useEffect(() => {
    if (activeId && activeId > 0) {
      (async () => {
        setIsLoading(true);
        try {
          const resPermissionGroup = await fetchPermissionGroup(activeId);
          oldState.current = resPermissionGroup;
          setPermissionGroup(resPermissionGroup);
        } catch {
          // nothing
        }
        setIsLoading(false);
      })();

      return;
    }

    oldState.current = initialPermissionGroup;
    setPermissionGroup(initialPermissionGroup);
    setActiveTab(0);
  }, [activeId]);

  const handleActiveTabChange = useCallback(
    async (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const handleSaveClicked = (close: boolean) => async () => {
    const isValid = validateYup(
      permissionGroup,
      permissionGroupYupSchema,
      setValidation
    );

    if (!isValid) {
      return false;
    }

    setIsLoading(true);

    // PUT
    if (permissionGroup.id && permissionGroup.id > 0) {
      try {
        const newPermissionGroup = await putPermissionGroup(permissionGroup);
        oldState.current = newPermissionGroup;
        setPermissionGroup(newPermissionGroup);
        await fetchSearchResult();
      } catch {
        setIsLoading(false);
        return false;
      }
    } else {
      // POST
      try {
        const newPermissionGroup = await postPermissionGroup(permissionGroup);
        oldState.current = newPermissionGroup;
        setPermissionGroup(newPermissionGroup);
        await fetchSearchResult();
        setQueryParams({ activeId: newPermissionGroup.id });
        if (close) {
          setQueryParams({ activeId: null });
          onClose();
          return true;
        }
      } catch {
        setIsLoading(false);
        return false;
      }
    }
    if (close) {
      onClose();
    }
    setIsLoading(false);
    return true;
  };

  const handleDeleteClicked = async () => {
    setIsDeleteLoading(true);

    try {
      await deletePermissionGroup(activeId!);
    } catch {
      // do nothing
    }

    onClose();
    fetchSearchResult();

    setIsDeleteLoading(false);
  };

  return (
    <>
      <DetailsCard
        onSubmit={handleSaveClicked(false)}
        isLoading={isLoading}
        state={permissionGroup}
        oldState={oldState}
      >
        <FBOTitleBar
          title={
            activeId && activeId > 0
              ? permissionGroup.name
              : 'New Permission Group'
          }
        >
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="permission-save"
            onClick={handleSaveClicked(false)}
            permissions={canEditPermissions}
          >
            Save
          </FBOButton>
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="permission-save-and-close"
            onClick={handleSaveClicked(true)}
            permissions={canEditPermissions}
          >
            Save And Close
          </FBOButton>
          {activeId && activeId > 0 && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="negative"
              size="medium"
              icon="TrashCan"
              data-qa="permission-delete"
              onClick={() => setDeleteModalVisible(true)}
              permissions={[PermissionType.PermissionDelete]}
            >
              Delete
            </FBOButton>
          )}
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="tertiary"
            color="neutral"
            size="medium"
            icon="FBOClose"
            data-qa="permission-close"
            onClick={onClose}
          />
        </FBOTitleBar>

        <Tabs
          value={activeTab}
          onChange={handleActiveTabChange}
          indicatorColor="primary"
          className={'redesign'}
        >
          <Tab label="Group" />
          <Tab label="Permissions" />
        </Tabs>
        <TabPanel value={activeTab} index={0} flexGrow noSpacing>
          <Box display="flex" flexDirection="column" width="100%" flexGrow={1}>
            <PermissionsGroupTab
              permissionGroup={permissionGroup}
              setPermissionGroup={setPermissionGroup}
              validation={validation}
            />
            <PermissionsItems
              permissionGroup={permissionGroup}
              setPermissionGroup={setPermissionGroup}
            />
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1} flexGrow noSpacing>
          <PermissionsTab
            permissionGroup={permissionGroup}
            setPermissionGroup={setPermissionGroup}
          />
        </TabPanel>
      </DetailsCard>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Permission Group "
        body={`This will delete group "${permissionGroup.name}"
       , are you sure?`}
        onCancelClicked={() => setDeleteModalVisible(false)}
        onConfirmClicked={handleDeleteClicked}
        confirmLabel={'Delete'}
        cancelLabel={'Cancel'}
        confirmButtonRed
        isLoading={isDeleteLoading}
      />
    </>
  );
};

export default PermissionDetailsCard;

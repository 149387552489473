import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import moment from 'moment';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  CustomField,
  CustomFieldOption,
  CustomFieldType,
} from 'services/customFields';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { MultiSelect } from 'ui/components/Autocomplete/MultiSelect';
import { activeUserHasPermission } from 'services/user/redux';

import { CustomFieldsProps } from './types';
import { mapErrorsByIds } from './validation';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const FBOCustomFields: React.FC<CustomFieldsProps> = (props) => {
  const {
    customFields,
    errors,
    permissions = [],
    onFieldChange,
    disabled,
    dataQa,
    showAll = false,
  } = props;

  const [otherFieldsVisible, setOtherFieldsVisible] = useState(showAll);

  const errorIds = mapErrorsByIds(errors, customFields);

  const canEditCustomFields = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEditCustomFields;

  const sortedCustomFields = useMemo(
    () => _.sortBy(customFields, 'lineNumber'),
    [customFields]
  );
  const visibleCustomFields = useMemo(() => {
    if (!otherFieldsVisible) {
      return sortedCustomFields.slice(0, 3);
    }

    return sortedCustomFields;
  }, [sortedCustomFields, otherFieldsVisible]);

  //find first input error to autoFocus to
  const firstErrorFieldId = useMemo(() => {
    let firstErrorId: number | null = null;
    if (errorIds.length > 0) {
      sortedCustomFields.forEach((cf) => {
        if (errorIds.includes(cf.id!) && !firstErrorId) {
          firstErrorId = cf.id;
        }
      });
    }
    return firstErrorId;
  }, [errorIds]);

  // show all custom fields if there are custom fields errors
  useEffect(() => {
    if (errorIds.length > 0 && showMoreButton) {
      setOtherFieldsVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorIds.length, errors]);

  const showMoreButton = !showAll && sortedCustomFields.length > 3;

  const handleTextChange = useCallback(
    (customField: CustomField) => (ev: React.ChangeEvent<any>) => {
      const value = ev.target.value ? ev.target.value : null;
      const newCustomField = {
        ...customField,
        customFieldValues: value,
      };
      onFieldChange(newCustomField);
    },
    [onFieldChange]
  );

  const handleNumberChange = useCallback(
    (customField: CustomField) => (ev: React.ChangeEvent<any>) => {
      const value = ev.target.value ? parseFloat(ev.target.value) : null;
      const newCustomField = {
        ...customField,
        customFieldValues: value,
      };
      onFieldChange(newCustomField);
    },
    [onFieldChange]
  );

  const handleDateChange = useCallback(
    (customField: CustomField) => (value: any) => {
      const date = moment(value);

      let newCustomField = customField;

      newCustomField = {
        ...customField,
        customFieldValues: value === null ? null : date.toDate(),
      };
      onFieldChange(newCustomField);
    },
    [onFieldChange]
  );

  const handleSingleChoiceChange = useCallback(
    (customField: CustomField) =>
      (ev: React.ChangeEvent<{}>, value: string | null) => {
        const newCustomField = {
          ...customField,
          customFieldValues: value,
        };
        onFieldChange(newCustomField);
      },
    [onFieldChange]
  );

  const handleMultipleChoiceChange = useCallback(
    (customField: CustomField) => (value: CustomFieldOption[] | null) => {
      const newCustomField = {
        ...customField,
        customFieldValues: value ? value.map((v) => v.option!) : null,
      };
      onFieldChange(newCustomField);
    },
    [onFieldChange]
  );

  const handleCheckBoxChange = useCallback(
    (customField: CustomField) =>
      (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean | null) => {
        const newCustomField = {
          ...customField,
          customFieldValues: !!checked,
        };

        onFieldChange(newCustomField);
      },
    [onFieldChange]
  );

  const handleCustomFieldsDropdown = () =>
    setOtherFieldsVisible((prevState) => !prevState);

  // prevent continually focusing on first error field after initial focus
  const [shouldFocusOnError, setShouldFocusOnError] = useState(true);

  useEffect(() => {
    if (!shouldFocusOnError) {
      setShouldFocusOnError(true);
    }
  }, [firstErrorFieldId]);

  const customFieldElementType = useCallback(
    (cf: CustomField) => {
      const hasError = errorIds.includes(cf.id!);
      const firstError = firstErrorFieldId === cf.id!;
      const inputRef = (ref: any) => {
        if (firstError && shouldFocusOnError) {
          setShouldFocusOnError(false);
          ref?.focus();
        }
      };
      switch (cf.customFieldType) {
        case CustomFieldType.Text:
          return (
            <TextField
              className="redesign"
              variant="standard"
              label={cf.name}
              value={cf.customFieldValues}
              required={cf.required}
              disabled={isDisabled}
              onChange={handleTextChange(cf)}
              error={hasError}
              inputRef={inputRef}
              helperText={hasError && 'Please enter text'}
              dataQa={`${dataQa}-text-custom-field`}
            />
          );
        case CustomFieldType.Number:
          return (
            <TextField
              className="redesign"
              variant="standard"
              type="number"
              label={cf.name}
              value={cf.customFieldValues}
              required={cf.required}
              disabled={isDisabled}
              onChange={handleNumberChange(cf)}
              error={hasError}
              inputRef={inputRef}
              helperText={hasError && 'Please enter valid number'}
              dataQa={`${dataQa}-number-custom-field`}
            />
          );
        case CustomFieldType.Boolean:
          return (
            <FormControlLabel
              className="redesign"
              control={
                <Checkbox
                  className="redesign"
                  checked={cf.customFieldValues as boolean}
                  onChange={handleCheckBoxChange(cf)}
                  color="primary"
                  data-qa="custom-field-checkbox"
                />
              }
              disabled={isDisabled}
              label={cf.name}
            />
          );
        case CustomFieldType.Date:
          return (
            <DatePickerWrapper
              label={cf.name!}
              required={cf.required}
              value={cf.customFieldValues as Date}
              onChange={handleDateChange(cf)}
              disabled={isDisabled}
              error={hasError}
              inputRef={inputRef}
              helperText={hasError && 'Please enter valid date'}
              fullWidth
              dataQa={dataQa}
            />
          );
        case CustomFieldType.SingleChoice:
          return (
            <Autocomplete
              label={cf.name!}
              value={cf.customFieldValues}
              options={_.sortBy(cf.customFieldOptions, 'option').map(
                (co) => co.option
              )}
              getOptionLabel={(value: string) => value || 'Unknown Option'}
              onChange={handleSingleChoiceChange(cf)}
              required={cf.required}
              disabled={isDisabled}
              error={hasError}
              helperText={hasError ? 'Please make a selection' : undefined}
              inputRef={inputRef}
              dataQa={`${dataQa}-single-choice-custom-field`}
              renderOption={(props, option: string) => (
                <li
                  {...props}
                  data-qa={`${dataQa}-single-choice-custom-field-option-${option}`}
                >
                  {option}
                </li>
              )}
            />
          );

        case CustomFieldType.MultipleChoice: {
          const customFieldValues = (cf.customFieldValues || []) as string[];
          const values: CustomFieldOption[] = [];
          cf.customFieldOptions.forEach((co: any) => {
            if (customFieldValues.includes(co.option || '')) {
              values.push(co);
            }
          });

          return (
            <MultiSelect
              label={cf.name!}
              values={values}
              disabled={isDisabled}
              options={_.sortBy(cf.customFieldOptions, 'option')}
              getOptionLabel={(o: CustomFieldOption) =>
                o.option || 'Unknown option'
              }
              onChange={handleMultipleChoiceChange(cf)}
              error={hasError}
              inputRef={inputRef}
              helperText={hasError && 'Please make a selection'}
              dataQa={`${dataQa}-multiple-choice-custom-field`}
            />
          );
        }
        case CustomFieldType.Url:
          return (
            <TextField
              className="redesign"
              variant="standard"
              label={cf.name}
              required={cf.required}
              disabled={isDisabled}
              value={cf.customFieldValues}
              onChange={handleTextChange(cf)}
              error={hasError}
              inputRef={inputRef}
              helperText={hasError && 'Please enter valid url'}
              dataQa={`${dataQa}-url-custom-field`}
            />
          );
        default:
          return null;
      }
    },
    [
      errorIds,
      isDisabled,
      handleCheckBoxChange,
      handleDateChange,
      handleMultipleChoiceChange,
      handleSingleChoiceChange,
      handleTextChange,
      handleNumberChange,
      dataQa,
      firstErrorFieldId,
    ]
  );

  return (
    <>
      {!_.isEmpty(customFields) && (
        <>
          {visibleCustomFields.map((cf) => (
            <Grid
              xs={4}
              key={cf.id!}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {customFieldElementType(cf)}
            </Grid>
          ))}
          {showMoreButton && (
            <Grid xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <FBOButton
                variant="secondary"
                color="positive"
                size="medium"
                data-qa="custom-fields-show-more-button"
                onClick={handleCustomFieldsDropdown}
              >
                {otherFieldsVisible ? 'Hide Fields' : 'Show More'}
              </FBOButton>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default FBOCustomFields;

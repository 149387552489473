import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

import { activeUserHasPermission, getActiveUser } from 'services/user/redux';
import { PermissionType } from 'services/permissions';

import { HomepageRouterCmp } from './types';
import { Routes } from '../../navigation';

const HomePage: HomepageRouterCmp = () => {
  const canAccessDashboard = useSelector(
    activeUserHasPermission([PermissionType.DashboardView])
  );

  const isLoading = useSelector(getActiveUser)._status.isLoading;

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress size={64} color="primary" />
      </Box>
    );
  }

  if (canAccessDashboard) {
    return <Redirect to={Routes.DashboardPage} />;
  } else {
    return <Redirect to={Routes.FlowPage} />;
  }
};

HomePage.route = Routes.HomePage;

export default HomePage;
